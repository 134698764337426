import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Grid, GridProps, Tooltip, TooltipProps, useTheme } from '@mui/material';
import { useCopyToClipboard } from 'hooks/ui';
import { FC, MouseEvent, ReactNode, useCallback, useState } from 'react';

export interface CopyableProps extends Omit<TooltipProps, 'title'> {
  clipboardValue: string;
  showTooltip?: boolean;
  anchorGridProps?: GridProps;
  title?: ReactNode;
}

const Copyable: FC<CopyableProps> = ({
  anchorGridProps,
  children,
  clipboardValue,
  showTooltip = true,
  ...props
}) => {
  const theme = useTheme();
  const [isCopyable, setIsCopyable] = useState(false);
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  const [, copy] = useCopyToClipboard();

  const showCopyIcon = () => {
    setIsCopyable(true);
  };

  const hideCopyIcon = () => {
    setIsCopyable(false);
  };

  const handleCopy = useCallback(
    (e: MouseEvent<SVGElement>) => {
      e?.stopPropagation();
      copy(clipboardValue);
      setRecentlyCopied(true);
      setTimeout(() => setRecentlyCopied(false), 1000);
    },
    [clipboardValue, copy],
  );

  return !clipboardValue ? (
    <>-</>
  ) : (
    <Tooltip
      PopperProps={{
        sx: {
          display: showTooltip || recentlyCopied ? 'block' : 'none',
          '.MuiTooltip-tooltip': {
            background: theme => theme.colors.gradients.sheet,
            boxShadow: '1px 1px 2px 1px rgba(32, 39, 35, 0.08)',
          },
          '.MuiTooltip-arrow::before': {
            background: '#fafafa',
            boxShadow: '1px 1px 2px 1px rgba(32, 39, 35, 0.08)',
          },
        },
      }}
      arrow
      {...props}
      title={(recentlyCopied ? 'Copied to Clipboard!' : clipboardValue) || props.title}
    >
      <Grid
        container
        flexWrap="nowrap"
        onMouseLeave={hideCopyIcon}
        onMouseOver={showCopyIcon}
        sx={{ position: 'relative' }}
        {...anchorGridProps}
      >
        {children}
        <Box sx={{ position: 'relative' }}>
          <ContentCopyIcon
            onClick={handleCopy}
            sx={{
              display: isCopyable && clipboardValue?.length > 0 ? 'block' : 'none',
              position: 'absolute',
              left: 0.3,
              pr: 0.3,
              cursor: 'pointer',
              '&:hover': {
                filter: 'brightness(1.05)',
              },
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      </Grid>
    </Tooltip>
  );
};

export default Copyable;
