import { Grid, Stack, Typography } from '@mui/material';
import CheckboxCard from 'components/Card/CheckboxCard';
import FormRadioButton from 'components/form-fields/FormRadioButton';
import { IOption, Option } from 'components/Option';
import { FormValues } from 'pages/NewDeployment';
import { useFormContext, useWatch } from 'react-hook-form';

// TODO: Revert commented out code when babylon integration is more certain
// const YAML_TEMPLATE = `finality_provider:
//   addr: ""
//   btc_pk: ""
//   commission: ""
//   consumer_id: ""
//   description:
//     details: ""
//     identity: ""
//     moniker: ""
//     security_contact: ""
//     website: ""
//  pop:
//     btc_sig: ""
//     btc_sig_type: ""
// `;

export interface IBabylonCheckbox extends Omit<IOption, 'optionTitle'> {}

const SelectIntegrations = ({ ...props }: IBabylonCheckbox) => {
  const form = useFormContext<FormValues>();
  const formValues = useWatch<FormValues>({ control: form.control });

  return (
    <Option optionTitle={''} {...props}>
      <Grid container>
        <Grid item xs={12}>
          <CheckboxCard
            content={
              <Typography px={1}>
                {'Would you like to integrate Babylon in your rollup?'}
              </Typography>
            }
            id="step_babylonEnabled"
            isSelected={typeof formValues.babylonEnabled === 'boolean'}
            rightButtonGroup={
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                mx="24px"
                spacing={2}
              >
                <FormRadioButton
                  assignedValue={true}
                  label="YES"
                  labelProps={{
                    sx: {
                      '& .MuiRadio-root': {
                        padding: 0,
                        width: 'unset',
                        height: 'unset',
                      },
                    },
                  }}
                  radioGroupConfig={{ label: 'YES', name: 'babylonEnabled' }}
                />
                <FormRadioButton
                  assignedValue={false}
                  label="NO"
                  labelProps={{
                    sx: {
                      '& .MuiRadio-root': {
                        padding: 0,
                        width: 'unset',
                        height: 'unset',
                      },
                    },
                  }}
                  radioGroupConfig={{ label: 'NO', name: 'babylonEnabled' }}
                />
              </Stack>
            }
            sx={{
              py: 1,
              svg: {},
              ...(form.formState.errors?.babylonEnabled && {
                border: theme => `1px solid ${theme.colors.schema.error}`,
              }),
            }}
          />
          <ErrorCaption
            error={!!form.formState.errors.babylonEnabled}
            message={form.formState.errors.babylonEnabled?.message}
          />
          {/* TODO: revert commented out code when babylon integration is more certain */}
          {/* <Collapse
            in={!form.formState.errors.babylonEnabled && Boolean(formValues.babylonEnabled)}
            mountOnEnter
          >
            <Box
              id="step_babylonFpDetails"
              sx={theme => {
                const defaultBorderColor = 'rgba(32, 39, 35, 0.08)';
                const errorBorderColor = theme.colors.schema.error;
                const activeBorderColor = `#5C8CE1`;

                return {
                  background: '#fff',
                  minHeight: '108px',
                  px: '24px',
                  py: '16px',
                  border: `1px solid ${
                    formValues.babylonFpDetails
                      ? activeBorderColor
                      : form.formState.errors?.babylonFpDetails
                      ? errorBorderColor
                      : defaultBorderColor
                  }`,
                  borderTop: 0,
                };
              }}
            >
              <HtmlTooltip
                placement="top"
                sx={{
                  minWidth: '550px',
                }}
                title={
                  <Typography>
                    <pre>{YAML_TEMPLATE}</pre>
                  </Typography>
                }
              >
                <div>
                  <Input
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          position: 'absolute',
                          top: '12px',
                          right: 0,
                        }}
                      >
                        <Tooltip title="Please refer to https://docs.babylonchain.io/docs/user-guides/btc-staking-testnet/finality-providers/overview to see how to generate the fp details and provide the following parameters to us:">
                          <div>
                            <IconInfo sx={{ height: '20px', width: '20px' }} />
                          </div>
                        </Tooltip>
                      </InputAdornment>
                    }
                    minRows={3}
                    multiline
                    onBlur={e =>
                      form.setValue('babylonFpDetails', e.target.value, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                      })
                    }
                    placeholder="Please provide the fp details..."
                    sx={{
                      width: '100%',
                      pr: '24px',
                    }}
                  />
                </div>
              </HtmlTooltip>
            </Box>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="start"
              spacing="8px"
              sx={{
                background: `linear-gradient(118.58deg, #FFF5D5 0%, #EFEADB 100%)`,
                px: '24px',
                py: '16px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'rgba(32, 39, 35, 0.08)',
              }}
            >
              <Typography variant="captionM">Please note: </Typography>
              <Typography variant="caption">
                Chains launched with Babylon integration during this hackathon will be terminated
                after the event concludes.
              </Typography>
            </Stack>
            <ErrorCaption
              error={!!form.formState.errors.babylonFpDetails}
              message={form.formState.errors.babylonFpDetails?.message}
            />
          </Collapse> */}
        </Grid>
      </Grid>
    </Option>
  );
};

export default SelectIntegrations;

const ErrorCaption = ({ error, message }: { error?: boolean; message?: string }) => {
  return (
    <>
      {error && (
        <Typography
          sx={{
            mx: '16px',
            color: theme => (error ? theme.colors.schema.error : undefined),
          }}
          variant="caption"
        >
          {message}
        </Typography>
      )}
    </>
  );
};
