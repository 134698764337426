import { Colors } from 'theme/types';

export const colors: Colors = {
  schema: {
    primary: '#6667AB',
    secondary: '#9092FE',
    success: '#39BA72',
    info: '#9092FE',
    error: '#FF6F7D',
    failure: '#F83B4C',
    warning: '#D4842E',
    pending: '#3E45C8',
    leafPrimary: '#66B489',
    waterPrimary: '#5C8CE1',
    creamPrimary: '#FFF5D5',
    metalPrimary: '#F6F6F6',
    metalSecondary: '#E7E7E7',
  },
  functional: {
    text: {
      primary: '#202723',
      second: '#AFB0CC',
      third: '#A4A4B2',
      link: '#5C8CE1',
      lint: 'rgba(32, 39, 35, 0.4)',
    },
    subject: {
      first: '#979797',
      third: '#646471',
      chosen: '#5C8CE1',
      border: 'rgba(32, 39, 35, 0.08)',
    },
    container: {
      primary: '#202723',
      disabled: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)',
      default: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
      dark: '#28282F',
    },
  },
  gradients: {
    pistachio: 'linear-gradient(127deg, #EAF4DC 0%, #D5E9BB 100%)',
    metal: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)',
    water: 'linear-gradient(74deg, #93B4F3 -3.42%, #5C8CE1 101.26%)',
    sheet: 'linear-gradient(155deg, #FFF 0%, #F7F7F7 100%)',
    leaf: 'linear-gradient(127deg, #74BA94 0%, #97C8AD 100%)',
    cream: 'linear-gradient(118.58deg, #FFF5D5 0%, #EFEADB 100%)',
  },
};
