// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.28.3
// source: aaas-bffavs.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";

export const protobufPackage = "avs.v1";

export enum AAAType {
  AAATYPE_UNSPECIFIED = 0,
  AAATYPE_BASE_AGENT = 1,
  AAATYPE_PERPLEXICA = 2,
  AAATYPE_AGENTKIT = 3,
  UNRECOGNIZED = -1,
}

export function aAATypeFromJSON(object: any): AAAType {
  switch (object) {
    case 0:
    case "AAATYPE_UNSPECIFIED":
      return AAAType.AAATYPE_UNSPECIFIED;
    case 1:
    case "AAATYPE_BASE_AGENT":
      return AAAType.AAATYPE_BASE_AGENT;
    case 2:
    case "AAATYPE_PERPLEXICA":
      return AAAType.AAATYPE_PERPLEXICA;
    case 3:
    case "AAATYPE_AGENTKIT":
      return AAAType.AAATYPE_AGENTKIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAAType.UNRECOGNIZED;
  }
}

export function aAATypeToJSON(object: AAAType): string {
  switch (object) {
    case AAAType.AAATYPE_UNSPECIFIED:
      return "AAATYPE_UNSPECIFIED";
    case AAAType.AAATYPE_BASE_AGENT:
      return "AAATYPE_BASE_AGENT";
    case AAAType.AAATYPE_PERPLEXICA:
      return "AAATYPE_PERPLEXICA";
    case AAAType.AAATYPE_AGENTKIT:
      return "AAATYPE_AGENTKIT";
    case AAAType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AAAHostType {
  AAAHOSTTYPE_UNSPECIFIED = 0,
  AAAHOSTTYPE_HOSTYPE = 1,
  AAAHOSTTYPE_CHATBOX = 2,
  UNRECOGNIZED = -1,
}

export function aAAHostTypeFromJSON(object: any): AAAHostType {
  switch (object) {
    case 0:
    case "AAAHOSTTYPE_UNSPECIFIED":
      return AAAHostType.AAAHOSTTYPE_UNSPECIFIED;
    case 1:
    case "AAAHOSTTYPE_HOSTYPE":
      return AAAHostType.AAAHOSTTYPE_HOSTYPE;
    case 2:
    case "AAAHOSTTYPE_CHATBOX":
      return AAAHostType.AAAHOSTTYPE_CHATBOX;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AAAHostType.UNRECOGNIZED;
  }
}

export function aAAHostTypeToJSON(object: AAAHostType): string {
  switch (object) {
    case AAAHostType.AAAHOSTTYPE_UNSPECIFIED:
      return "AAAHOSTTYPE_UNSPECIFIED";
    case AAAHostType.AAAHOSTTYPE_HOSTYPE:
      return "AAAHOSTTYPE_HOSTYPE";
    case AAAHostType.AAAHOSTTYPE_CHATBOX:
      return "AAAHOSTTYPE_CHATBOX";
    case AAAHostType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AAA {
  id: string;
  name: string;
  endpoints: { [key: string]: any } | undefined;
  app: string;
  hostType: string;
  createdAt: string;
}

export interface ListAAARequest {
}

export interface ListAAAReply {
  aaas: AAA[];
}

export interface TerminateAAARequest {
  id: string;
}

export interface TerminateAAAReply {
}

export interface CreateAAARequest {
  name: string;
  app: AAAType;
  promoCode: string;
}

export interface CreateAAAReply {
  aaa: AAA | undefined;
}

export interface APP {
  type: string;
  typeId: number;
  description: string;
  hostType: string;
}

export interface ListAAAAppTypeRequest {
}

export interface ListAAAAppTypeReply {
  templates: APP[];
}

function createBaseAAA(): AAA {
  return { id: "", name: "", endpoints: undefined, app: "", hostType: "", createdAt: "" };
}

export const AAA = {
  encode(message: AAA, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.endpoints !== undefined) {
      Struct.encode(Struct.wrap(message.endpoints), writer.uint32(26).fork()).ldelim();
    }
    if (message.app !== "") {
      writer.uint32(34).string(message.app);
    }
    if (message.hostType !== "") {
      writer.uint32(42).string(message.hostType);
    }
    if (message.createdAt !== "") {
      writer.uint32(50).string(message.createdAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AAA {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAAA();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endpoints = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.app = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.hostType = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AAA {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      endpoints: isObject(object.endpoints) ? object.endpoints : undefined,
      app: isSet(object.app) ? globalThis.String(object.app) : "",
      hostType: isSet(object.hostType) ? globalThis.String(object.hostType) : "",
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
    };
  },

  toJSON(message: AAA): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.endpoints !== undefined) {
      obj.endpoints = message.endpoints;
    }
    if (message.app !== "") {
      obj.app = message.app;
    }
    if (message.hostType !== "") {
      obj.hostType = message.hostType;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AAA>, I>>(base?: I): AAA {
    return AAA.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AAA>, I>>(object: I): AAA {
    const message = createBaseAAA();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.endpoints = object.endpoints ?? undefined;
    message.app = object.app ?? "";
    message.hostType = object.hostType ?? "";
    message.createdAt = object.createdAt ?? "";
    return message;
  },
};

function createBaseListAAARequest(): ListAAARequest {
  return {};
}

export const ListAAARequest = {
  encode(_: ListAAARequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAARequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAARequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAAARequest {
    return {};
  },

  toJSON(_: ListAAARequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAARequest>, I>>(base?: I): ListAAARequest {
    return ListAAARequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAARequest>, I>>(_: I): ListAAARequest {
    const message = createBaseListAAARequest();
    return message;
  },
};

function createBaseListAAAReply(): ListAAAReply {
  return { aaas: [] };
}

export const ListAAAReply = {
  encode(message: ListAAAReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aaas) {
      AAA.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aaas.push(AAA.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAReply {
    return { aaas: globalThis.Array.isArray(object?.aaas) ? object.aaas.map((e: any) => AAA.fromJSON(e)) : [] };
  },

  toJSON(message: ListAAAReply): unknown {
    const obj: any = {};
    if (message.aaas?.length) {
      obj.aaas = message.aaas.map((e) => AAA.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAReply>, I>>(base?: I): ListAAAReply {
    return ListAAAReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAReply>, I>>(object: I): ListAAAReply {
    const message = createBaseListAAAReply();
    message.aaas = object.aaas?.map((e) => AAA.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTerminateAAARequest(): TerminateAAARequest {
  return { id: "" };
}

export const TerminateAAARequest = {
  encode(message: TerminateAAARequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TerminateAAARequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerminateAAARequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TerminateAAARequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: TerminateAAARequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TerminateAAARequest>, I>>(base?: I): TerminateAAARequest {
    return TerminateAAARequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TerminateAAARequest>, I>>(object: I): TerminateAAARequest {
    const message = createBaseTerminateAAARequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseTerminateAAAReply(): TerminateAAAReply {
  return {};
}

export const TerminateAAAReply = {
  encode(_: TerminateAAAReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TerminateAAAReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerminateAAAReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TerminateAAAReply {
    return {};
  },

  toJSON(_: TerminateAAAReply): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TerminateAAAReply>, I>>(base?: I): TerminateAAAReply {
    return TerminateAAAReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TerminateAAAReply>, I>>(_: I): TerminateAAAReply {
    const message = createBaseTerminateAAAReply();
    return message;
  },
};

function createBaseCreateAAARequest(): CreateAAARequest {
  return { name: "", app: 0, promoCode: "" };
}

export const CreateAAARequest = {
  encode(message: CreateAAARequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.app !== 0) {
      writer.uint32(16).int32(message.app);
    }
    if (message.promoCode !== "") {
      writer.uint32(26).string(message.promoCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAARequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAARequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.app = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.promoCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAARequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      app: isSet(object.app) ? aAATypeFromJSON(object.app) : 0,
      promoCode: isSet(object.promoCode) ? globalThis.String(object.promoCode) : "",
    };
  },

  toJSON(message: CreateAAARequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.app !== 0) {
      obj.app = aAATypeToJSON(message.app);
    }
    if (message.promoCode !== "") {
      obj.promoCode = message.promoCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAARequest>, I>>(base?: I): CreateAAARequest {
    return CreateAAARequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAARequest>, I>>(object: I): CreateAAARequest {
    const message = createBaseCreateAAARequest();
    message.name = object.name ?? "";
    message.app = object.app ?? 0;
    message.promoCode = object.promoCode ?? "";
    return message;
  },
};

function createBaseCreateAAAReply(): CreateAAAReply {
  return { aaa: undefined };
}

export const CreateAAAReply = {
  encode(message: CreateAAAReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.aaa !== undefined) {
      AAA.encode(message.aaa, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAAAReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAAAReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aaa = AAA.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAAAReply {
    return { aaa: isSet(object.aaa) ? AAA.fromJSON(object.aaa) : undefined };
  },

  toJSON(message: CreateAAAReply): unknown {
    const obj: any = {};
    if (message.aaa !== undefined) {
      obj.aaa = AAA.toJSON(message.aaa);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAAAReply>, I>>(base?: I): CreateAAAReply {
    return CreateAAAReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAAAReply>, I>>(object: I): CreateAAAReply {
    const message = createBaseCreateAAAReply();
    message.aaa = (object.aaa !== undefined && object.aaa !== null) ? AAA.fromPartial(object.aaa) : undefined;
    return message;
  },
};

function createBaseAPP(): APP {
  return { type: "", typeId: 0, description: "", hostType: "" };
}

export const APP = {
  encode(message: APP, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.typeId !== 0) {
      writer.uint32(16).uint32(message.typeId);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.hostType !== "") {
      writer.uint32(34).string(message.hostType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): APP {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAPP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.typeId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.hostType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): APP {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      typeId: isSet(object.typeId) ? globalThis.Number(object.typeId) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      hostType: isSet(object.hostType) ? globalThis.String(object.hostType) : "",
    };
  },

  toJSON(message: APP): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.typeId !== 0) {
      obj.typeId = Math.round(message.typeId);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.hostType !== "") {
      obj.hostType = message.hostType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<APP>, I>>(base?: I): APP {
    return APP.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<APP>, I>>(object: I): APP {
    const message = createBaseAPP();
    message.type = object.type ?? "";
    message.typeId = object.typeId ?? 0;
    message.description = object.description ?? "";
    message.hostType = object.hostType ?? "";
    return message;
  },
};

function createBaseListAAAAppTypeRequest(): ListAAAAppTypeRequest {
  return {};
}

export const ListAAAAppTypeRequest = {
  encode(_: ListAAAAppTypeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAAppTypeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAAppTypeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListAAAAppTypeRequest {
    return {};
  },

  toJSON(_: ListAAAAppTypeRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAAppTypeRequest>, I>>(base?: I): ListAAAAppTypeRequest {
    return ListAAAAppTypeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAAppTypeRequest>, I>>(_: I): ListAAAAppTypeRequest {
    const message = createBaseListAAAAppTypeRequest();
    return message;
  },
};

function createBaseListAAAAppTypeReply(): ListAAAAppTypeReply {
  return { templates: [] };
}

export const ListAAAAppTypeReply = {
  encode(message: ListAAAAppTypeReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      APP.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAAAAppTypeReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAAAAppTypeReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(APP.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAAAAppTypeReply {
    return {
      templates: globalThis.Array.isArray(object?.templates) ? object.templates.map((e: any) => APP.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListAAAAppTypeReply): unknown {
    const obj: any = {};
    if (message.templates?.length) {
      obj.templates = message.templates.map((e) => APP.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAAAAppTypeReply>, I>>(base?: I): ListAAAAppTypeReply {
    return ListAAAAppTypeReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAAAAppTypeReply>, I>>(object: I): ListAAAAppTypeReply {
    const message = createBaseListAAAAppTypeReply();
    message.templates = object.templates?.map((e) => APP.fromPartial(e)) || [];
    return message;
  },
};

export type AAAServiceDefinition = typeof AAAServiceDefinition;
export const AAAServiceDefinition = {
  name: "AAAService",
  fullName: "avs.v1.AAAService",
  methods: {
    listAAA: {
      name: "ListAAA",
      requestType: ListAAARequest,
      requestStream: false,
      responseType: ListAAAReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([14, 18, 12, 47, 118, 49, 47, 98, 102, 102, 47, 97, 97, 97, 115])],
        },
      },
    },
    createAAA: {
      name: "CreateAAA",
      requestType: CreateAAARequest,
      requestStream: false,
      responseType: CreateAAAReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([17, 34, 12, 47, 118, 49, 47, 98, 102, 102, 47, 97, 97, 97, 115, 58, 1, 42])],
        },
      },
    },
    terminateAAA: {
      name: "TerminateAAA",
      requestType: TerminateAAARequest,
      requestStream: false,
      responseType: TerminateAAAReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              27,
              34,
              22,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              97,
              97,
              97,
              115,
              47,
              116,
              101,
              114,
              109,
              105,
              110,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    listAAAAppType: {
      name: "ListAAAAppType",
      requestType: ListAAAAppTypeRequest,
      requestStream: false,
      responseType: ListAAAAppTypeReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [new Uint8Array([14, 18, 12, 47, 118, 49, 47, 98, 102, 102, 47, 97, 112, 112, 115])],
        },
      },
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
