import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export interface CustomizationFormValues {
  rpcEndpoint: string;
}

export const getValidationSchema = (): yup.ObjectSchema<CustomizationFormValues> =>
  yup
    .object<CustomizationFormValues>()
    .shape({
      rpcEndpoint: yup
        .string()
        .url('RPC endpoint must be a valid URL.')
        .required('RPC endpoint is required.'),
    })
    .required();

export const resolver = yupResolver(getValidationSchema());
