import { LineChart } from 'components/charts';
import { MetricContainer } from 'pages/DeploymentDetails/MetricContainer';

enum DataKey {
  INITIATED_TXNS = 'initiated txns',
  FINALIZED = 'finalized',
}

export const WithdrawalTxnsGrowthMetric = () => {
  const dataKeys = [
    {
      name: DataKey.INITIATED_TXNS,
      color: '#5C8CE1',
    },
    {
      name: DataKey.FINALIZED,
      color: '#66B489',
    },
  ];
  const data = [
    {
      name: '0:00',
      [DataKey.INITIATED_TXNS]: 15,
      [DataKey.FINALIZED]: 30,
    },
    {
      name: '01:00',
      [DataKey.INITIATED_TXNS]: 20,
      [DataKey.FINALIZED]: 35,
    },
    {
      name: '02:00',
      [DataKey.INITIATED_TXNS]: 25,
      [DataKey.FINALIZED]: 50,
    },
    {
      name: '03:00',
      [DataKey.INITIATED_TXNS]: 20,
      [DataKey.FINALIZED]: 45,
    },
    {
      name: '04:00',
      [DataKey.INITIATED_TXNS]: 30,
      [DataKey.FINALIZED]: 55,
    },
    {
      name: '05:00',
      [DataKey.INITIATED_TXNS]: 32,
      [DataKey.FINALIZED]: 58,
    },
    {
      name: '06:00',
      [DataKey.INITIATED_TXNS]: 35,
      [DataKey.FINALIZED]: 70,
    },
    {
      name: '07:00',
      [DataKey.INITIATED_TXNS]: 35,
      [DataKey.FINALIZED]: 80,
    },
  ];

  return (
    <MetricContainer
      responsiveContainerSx={{ minHeight: '275px' }}
      title={'Withdrawals txns growth'}
    >
      <LineChart data={data} dataKeys={dataKeys} />
    </MetricContainer>
  );
};
