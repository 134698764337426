import { GetComponentStyleFunc } from './types';

export const muiTableContainer: GetComponentStyleFunc<'MuiTableContainer'> = theme => {
  return {
    styleOverrides: {
      root: {
        color: theme.colors.functional.text.primary,
        '& .MuiTableCell-root': {
          paddingLeft: '40px',
        },
        '& .MuiTableHead-root > .MuiTableRow-root': {
          background: 'rgba(0, 0, 0, 0.16)',
          borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
        },
        '& .MuiTableHead-root > .MuiTableCell-root': {
          paddingTop: '8px',
          paddingBottom: '8px',
        },
        '& .MuiTableBody-root > .MuiTableCell-root': {
          paddingTop: '16px',
          paddingBottom: '16px',
        },
        '& .MuiTableBody-root > .MuiTableRow-root': {
          background: '#fff',
          borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
          '&:hover': {
            background: '#fafafa',
          },
        },
        '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root > .MuiSkeleton-root': {
          fontSize: '15px',
          lineHeight: '20px',
          width: '100%',
        },
      },
    },
  };
};
