export const linearGradientIds = ['water', 'cream', 'pistachio', 'leaf', 'metal'];

export const strokeColors = [
  '#5C8CE1',
  '#66B489',
  '#F1605F',
  '#A6C3FA',
  '#F1905F',
  '#4AD5D0',
  '#F8D97D',
  '#A281FF',
  '#D5E9BB',
  '#BAC6AB',
  '#FD81FF',
  '#B78F49',
];
