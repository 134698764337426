import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Section } from 'pages/DeploymentDetails/Section';
import { truncateEthAddress } from 'utils/address';
import { zeroHash } from 'viem';

export const WithdrawHistorySection = () => {
  return (
    <Section
      renderContent={
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['Fee Vault', 'Tx Hash', 'Amount (ETH)', 'Type', 'Time'].map(header => (
                  <TableCell key={header}>
                    <Typography variant="captionC">{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: 4 }).map((_, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <Typography variant="bodySmall">L1FeeVault</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{ color: '#5C8CE1', textDecoration: 'underline' }}
                      variant="bodySmall"
                    >
                      {truncateEthAddress(zeroHash)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="bodySmall">{15}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="bodySmall">Auto</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="bodySmall">2024/08/01 12:00:00</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      title="Withdraw History"
    />
  );
};
