import { Stack, Typography } from '@mui/material';
import { Banner } from 'components/Banner';
import { IPageBanner, PageBanner, PageBannerVariant } from 'components/Layout/PageBanner';
import { useDisclosure } from 'hooks/ui';
import { PropsWithChildren, useEffect } from 'react';
import { BannerVariant, useBannerStore } from 'stores/useBannerStore';

export interface IAaasLayout extends PropsWithChildren {
  bannerDefaultOpen?: boolean;
  pageBannerProps?: IPageBanner;
}

export const AaasLayout: React.FC<IAaasLayout> = props => {
  const { banners, setBanner } = useBannerStore();
  const { onClose: onCloseBanner, open: isOpenBanner } = useDisclosure({
    defaultOpen: props.bannerDefaultOpen ?? banners[BannerVariant.AAA_SIGN_UP_EARLY_ACCESS],
  });

  useEffect(() => {
    setBanner(BannerVariant.AAA_SIGN_UP_EARLY_ACCESS, isOpenBanner);
  }, [isOpenBanner, setBanner]);

  return (
    <>
      <PageBanner variant={PageBannerVariant.AAA} {...props?.pageBannerProps} />
      <Banner in={isOpenBanner} onClose={onCloseBanner}>
        <Stack direction="row" spacing="8px">
          <Typography>Sign up for the waitlist</Typography>
          <Typography
            component="a"
            href="https://www.autono.meme"
            rel="noreferrer noopener"
            target="_blank"
          >
            https://www.autono.meme
          </Typography>
        </Stack>
      </Banner>

      {props.children}
    </>
  );
};
