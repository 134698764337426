import { useRaasMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { useParams } from 'react-router-dom';
import { Panel } from 'types/protoc-gen/raas-metrics';
import { formatAbbreviatedNumber } from 'utils/strings';

export const BatcherPendingBlockCountMetric = () => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.BATCHER_BALANCE_BALANCE_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    rollupId,
    panel: Panel.PANEL_OPSTACK_BATCHER_PENDING_BLOCK,
    start,
    end,
    step,
  });

  return (
    <Metric
      curveType="step"
      data={result}
      id={'batcher-pending-block-count'}
      loading={isPending}
      title={'Batcher pending block count'}
      yAxisProps={{
        tickFormatter: value => formatAbbreviatedNumber(value),
      }}
    />
  );
};
