export const ORBIT_MD = `
## Brief Introduction
---

1.  **What Orbit modes do you support?**

    | | L3 Orbit | L2 Orbit* |
    | - | - | - |
    | Testnet parent chains | Arbitrum Sepolia | Sepolia |
    | Mainnet parent chains | Arbitrum One | Ethereum |
    | Data availability | AnyTrust | AnyTrust |

    We will also be rolling out other integration options such as Celestia, EigenDA, and Avail (for data availability) and Espresso (for transaction sequencing) soon.

    _Do note that L2 Orbit chain deployment requires a 10% sequencer fee sharing agreement with Arbitrum Foundation as stated in the [Arbitrum Expansion Program](https://forum.arbitrum.foundation/t/the-arbitrum-expansion-program-and-developer-guild/20722)._

2.  **Do you support migrations to alternative DAs or addition of restaked rollup modules after the Orbit L2/L3 chain is live?**

    We are currently in the midst of adding this capability.

3.  **Where can I get more information about Arbitrum Orbit?**

    You can refer to <https://docs.arbitrum.io/launch-orbit-chain/orbit-gentle-introduction>

## Parameter Settings
---

1.  **What parameters can we configure for our Orbit chain?**

    You can provide us with your preferred:

    -   Chain ID

    -   Network name

    -   Custom gas token address

    -   Fee receiver address

    For mainnets, you also need to provide us with:

    -   Chain operator address (for sending chain configuration transactions)

    -   Challenge period (for withdrawals)

    If you are planning to use a custom gas token address, please also send some tokens to us for the chain setup.

## Launch Process
---

1.  **What is included in the standard setup?**

    | Label | Description |
    | - | - |
    | Orbit Chain | 1 sequencer / batch poster / staker / validator node |
    | RPC / WS data | 1 or more load-balanced full nodes, 1 feed relay node to allow you to host your own full nodes |
    | Block explorer | Standard Blockscout v2 application |
    | AnyTrust DA | 1 DA server to store transaction data, 1 or more load-balanced mirror nodes to serve data read requests |

2.  **What is the Orbit setup process like?**

    You can refer to the Arbitrum quickstart guide for details: <https://docs.arbitrum.io/launch-orbit-chain/orbit-quickstart>

    In brief, the steps are:

    1.  Rollup registration on Arbitrum's rollup factory contract

    2.  Deployment of the nodes for your chain

    3.  Token bridge creation on Arbitrum's token bridge factory contract

    4.  Some final configuration actions on the Orbit chain

3.  **Can I quickly try out an Orbit testnet somewhere?**

    We provide a public testnet that you can use to test out Orbit: <https://rollup-info.altlayer.io/public/orbit_demo_testnet>

4.  **What can we expect after the chain setup is completed?**

    You will receive a rollup info page containing all the L2 details and links, similar to [this](https://rollup-info.altlayer.io/public/orbit_demo_testnet). We can also help with transferring the ownership to a multi-sig (gnosis-safe fork hosted by us), if you choose to do so.

5.  **Can we host our own full nodes?**

    Yes, and in fact hosting your own full nodes is recommended if you have query-intensive applications for your chain, such as an indexer. Upon request, we will provide you with the scripts for launching your own full nodes.

1.  **Can we use our own domain for the chain URLs?**
    
    Yes, you can CNAME the RPC, websocket (if any) and explorer URLs to your own domain.

    For these customizations, you will need to create CNAME records on your DNS provider. We will provide you the target for these records.

## Product Instruction
---

### Explorer

1.  **Can we customize our block explorer?**

    Yes, we can customize settings such as the logos, icons, color scheme, and text content. Please request our Blockscout order form to see all the customizations available.

2.  **Can I verify contracts on the block explorer?**

    Yes. Please follow this [sample](https://github.com/alt-research/contract-verify-sample) for verifying a deployed contract using hardhat.

### Bridge

1.  **What are our bridging options?**

    You can use the native bridge for Orbit chains ([here](https://bridge.arbitrum.io/)). Or, you can choose to partner with third-party bridges in our [partner list](https://altlayer.io/#ecosystem).

    If you intend to use the native bridge, there's a guide in the rollup info page which we provide after the chain deployment.

2.  **If I want to deploy my ERC20 token, what do I need to prepare?**

    You have to make sure the ERC20 token has already been deployed on L1.

### Faucet

1.  **What are our faucet options?**

    You can choose to use [our partners' faucet solutions](https://atanetwork.notion.site/Automata-Testnet-Faucet-Service-850d288f6556425d8bc8ed0366acb470), with three options available.

## Performance Issue
---

### General

1.  **Can I measure the max TPS?**

    Max TPS is a difficult one to measure as it relies on network activity and the type of transactions being submitted. We can, however, calculate the max throughput using default orbit chain parameters. Assuming a block time of 250ms and a 32m L2 gas limit per block this arrives at 128M L2 gas per second. A theoretical limit on max TPS under these parameters would be as many transactions you can fit within the 128M gas per second. 128M / 21K = # of TPS.

### Gas fee

1.  **Introduction of the orbit gas mechanism.**

    Here's the [official guide](https://docs.arbitrum.io/launch-orbit-chain/how-tos/manage-fee-collectors#what-fees-are-collected-on-an-orbit-chain) about gas fee.

    orbit chain user need to pay two kinds of fee: infra fee and network fee.

    **a. Infra fee**

      Infra fee is for reimbursing batch poster sending calldata to l1.

      The formula for calculating infra fee is:

      \`\`\`
      txn size compressed using Brotli(L1S) * l1 gas price per byte under orbit chain's view (L1P)
      \`\`\`

      Take a deep look at L1P:

      -   Use \`getL1PricingSurplus\` API check if the L1P is reasonable, if it is not, we can consider fix it using \`SetL1PricePerUnit\`

      -   There is an auto-adjustment algo for adjusting L1P, however the algo doesn't work because the orbit chain itself and the batch poster don't work regularly.

    **b. Network fee**

      Network fee is for reimbursing any orbit chain resource used: computation, storage etc.

2.  **Can I manually adjust L1 base fee or also called L1 price per unit?**

    a.  In most cases, you should not manually adjust the l1 price estimate and let it auto-adjust.

    b.  Manual adjustment should only be done when the price has drifted too far from the actual value and thus inhibiting activity.

    c.  L1 price drifting too far can be due to - lack of activity on the chain - the batch poster is not posting enough batches regularly (to deliver l1 price update to the orbit chain).

    d.  The batch poster eventually needs to pay for the actual L1 posting cost, so a cheaper Orbit txn only means the batch poster needs to pay more, so it is net 0.

4.  **How can I estimate the gas?**

    Here's the [official gas estimation tutorial](https://github.com/OffchainLabs/arbitrum-tutorials/tree/master/packages/gas-estimation).

## Technical Details
---

1.  **Is it possible to let us (the clients) provide the ERC20 contracts on L2, for example, the contract of USDT?**

    No. According to the [official contract code](https://github.com/OffchainLabs/token-bridge-contracts/blob/main/contracts/tokenbridge/libraries/L2GatewayToken.sol#L66), only the gateway can mint, and the prerequisite for mint is that the token is locked to ensure the balance of supply and demand. It is impossible for contracts and components that are not internal to the arbitrum system to invoke the mint function.
`;

