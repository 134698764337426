import { Stack, StackProps, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

export const SubHeader = ({
  children,
  sx,
  ...props
}: PropsWithChildren & Omit<StackProps, 'children'>) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        background: theme.colors.gradients.metal,
        borderBottom: '1px solid rgba(32, 39, 35, 0.08)',
        height: '68px',
        width: '100%',
        px: '40px',
        py: '24px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};
