import { IconButton, Stack, Typography } from '@mui/material';
import { IconClose } from 'components/icons/IconClose';

export const DialogHeader: React.FC<{ title: string; onClose?: () => void }> = ({
  onClose,
  title,
}) => {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography variant="h5">{title}</Typography>

      <IconButton onClick={() => onClose?.()} size="small">
        <IconClose />
      </IconButton>
    </Stack>
  );
};
