import { darken } from '@mui/material';
import { colors } from 'theme/colors';

import { GetComponentStyleFunc } from './types';

export const muiButton: GetComponentStyleFunc<'MuiButton'> = theme => {
  return {
    defaultProps: {
      size: 'large',
      variant: 'contained',
    },
    variants: [
      {
        props: { variant: 'cream', size: 'large' },
        style: {
          minHeight: '68px',
          '&, &:hover': {
            background: colors.gradients.cream,
            color: colors.functional.text.primary,
          },
        },
      },
      {
        props: { variant: 'sheet' },
        style: {
          background: colors.gradients.sheet,
          color: colors.functional.text.primary,
          '&:hover': {
            background: colors.gradients.sheet,
            color: colors.functional.text.primary,
          },
        },
      },
      {
        props: { variant: 'metal', size: 'large' },
        style: {
          minHeight: '68px',
          '&, &:hover': {
            background: colors.gradients.metal,
            color: colors.functional.text.primary,
          },
        },
      },
      {
        props: { variant: 'raas' },
        style: {
          background: colors.gradients.leaf,
          color: colors.functional.text.primary,
          '&:hover': {
            background: colors.gradients.leaf,
            color: colors.functional.text.primary,
          },
        },
      },
      {
        props: { variant: 'contained' },
        style: {
          color: 'white',
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '26px',
          background: theme.colors.functional.container.primary,
          '&:hover': {
            background: darken(theme.colors.functional.container.primary, 0.7),
          },
          '&.MuiLoadingButton-root.MuiLoadingButton-loading > .MuiLoadingButton-loadingIndicator': {
            color: 'white',
          },
          '&.MuiLoadingButton-root.MuiLoadingButton-loading': {
            opacity: 1,
            background: darken(theme.colors.functional.container.primary, 0.3),
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '26px',
          color: theme.colors.functional.text.primary,
          '&.MuiLoadingButton-root.MuiLoadingButton-loading > .MuiLoadingButton-loadingIndicator': {
            color: theme.colors.functional.text.primary,
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '26px',
          background: theme.colors.gradients.metal,
          color: theme.colors.functional.text.primary,
          '&:hover': {
            filter: 'invert(0.02)',
          },
        },
      },
      {
        props: { size: 'large' },
        style: {
          minWidth: '10rem',
        },
      },
    ],
    styleOverrides: {
      root: {
        borderRadius: '0px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '14px',
      },
      containedSizeLarge: {
        height: '56px',
      },
      outlinedSizeLarge: {
        height: '56px',
      },
      textSizeLarge: {
        height: '56px',
      },
    },
  };
};

export const muiButtonBase: GetComponentStyleFunc<'MuiButtonBase'> = () => {
  return {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },
    },
  };
};
