import { DepositsSection } from './DepositsSection';
import { GeneralSection } from './GeneralSection';
import { WithdrawalsSection } from './WithdrawalsSection';

export const BridgeMetricsView: React.FC = () => {
  return (
    <>
      <GeneralSection />
      <DepositsSection />
      <WithdrawalsSection />
    </>
  );
};
