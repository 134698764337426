import {
  Button,
  Dialog,
  DialogProps,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { IconClose } from 'components/icons/IconClose';
import { truncateEthAddress } from 'utils/address';
import { zeroAddress } from 'viem';

import { useBalanceAlertStore } from './store';

export interface IEditDialog extends Omit<DialogProps, 'onClose'> {
  onClose?: () => void;
}

export const EditDialog = ({ onClose, open, sx, ...props }: IEditDialog) => {
  const {
    dialogs: {
      edit: { notifications, threshold },
    },
  } = useBalanceAlertStore();

  return (
    <Dialog onClose={onClose} open={open} {...props} sx={{ p: 0, ...sx }}>
      <Stack spacing="40px" sx={{ p: '40px', minWidth: '504px', minHeight: '500px' }}>
        <Stack
          alignItems="center"
          direction="row"
          id="edit-dialog-header"
          justifyContent="space-between"
          spacing="40px"
        >
          <Typography variant="h5">Edit</Typography>
          <IconButton onClick={onClose}>
            <IconClose />
          </IconButton>
        </Stack>

        <Stack id="edit-dialog-content" spacing="24px">
          <Stack
            alignItems="center"
            direction="row"
            id="edit-dialog-details"
            justifyContent="space-between"
          >
            <Stack alignItems="start" justifyContent="center" spacing="8px">
              <Typography variant="caption">Name</Typography>
              <Typography variant="bodySmallM">Batcher</Typography>
            </Stack>

            <Stack alignItems="center" justifyContent="center" spacing="8px">
              <Typography variant="caption">Address</Typography>
              <Typography
                component="a"
                href="#"
                rel="noopener noreferrer"
                sx={theme => ({
                  textDecoration: 'underline',
                  color: theme.colors.functional.text.link,
                  cursor: 'pointer',
                })}
                target="_blank"
                variant="bodySmallM"
              >
                {truncateEthAddress(zeroAddress)}
              </Typography>
            </Stack>

            <Stack alignItems="end" justifyContent="center" spacing="8px">
              <Typography variant="caption">Balance</Typography>
              <Typography variant="bodySmallM">0.4990118 ETH</Typography>
            </Stack>
          </Stack>

          <Stack id="edit-dialog-fields" spacing="24px">
            <Stack spacing="8px">
              <Typography variant="captionC">Threshold</Typography>
              <TextField
                defaultValue={threshold}
                id="threshold"
                inputProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                  },
                }}
                variant="outlined"
              />
            </Stack>

            <Stack spacing="8px">
              <Typography variant="captionC">Notifications</Typography>
              <TextField defaultValue={notifications} id="notifications" variant="outlined" />
            </Stack>
          </Stack>
        </Stack>

        <Button
          disableElevation
          onClick={onClose}
          sx={theme => ({
            background: theme.colors.gradients.leaf,
            color: theme.colors.functional.text.primary,
            minHeight: '68px',
          })}
        >
          <Typography variant="bodySmall">Submit</Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};
