import { SvgIcon, SvgIconProps } from '@mui/material';

const IconEth = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 20 20" {...props}>
    <path d="M3.92773 10.1854L10.0619 0V7.39941L3.92773 10.1854Z" fill="black" fillOpacity="0.4" />
    <path
      d="M10.0619 13.8148L3.92773 10.1854L10.0619 7.39941V13.8148Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path d="M16.2031 10.1854L10.0625 7.39941V0L16.2031 10.1854Z" fill="black" fillOpacity="0.7" />
    <path d="M10.0625 13.8148V7.39941L16.2031 10.1854L10.0625 13.8148Z" fill="black" />
    <path
      d="M10.0619 19.9999L3.92773 11.3545L10.0619 14.9743V19.9999Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M10.0625 19.9999V14.9743L16.2031 11.3545L10.0625 19.9999Z"
      fill="black"
      fillOpacity="0.7"
    />
  </SvgIcon>
);

export default IconEth;
