import { useMemo, useState } from 'react';

export interface IUseTablePagination<T> {
  itemsPerPage?: number;
  data: T[];
}

export const useTablePagination = <T>({ data, itemsPerPage = 10 }: IUseTablePagination<T>) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const _data = useMemo<T[]>(() => data, [data]);
  const filteredData = useMemo(
    () => _data?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [currentPage, _data, itemsPerPage],
  );
  const paginationCount = useMemo(
    () => Math.ceil(data.length / itemsPerPage),
    [data.length, itemsPerPage],
  );

  return {
    data,
    setCurrentPage,
    filteredData,
    itemsPerPage,
    paginationCount,
  };
};
