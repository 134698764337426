import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Modal,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { AxiosError, AxiosResponse } from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useCallback, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { axiosAuth } from '../../api';
import { PATHS } from '../../App';
import { useAuthContext, User } from '../../contexts/AuthContext';
import { authUtil } from '../../utils/auth';

export function Login() {
  const { loginSuccessRedirectPath, setIsLoggedIn, setUser } = useAuthContext();
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const theme = useTheme();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleInitializeMava = useCallback(async (user: User) => {
    if (!window.Mava) return console.debug('Mava not initialized');

    await window.Mava.initialize();
    await window.Mava.identify({
      emailAddress: user.email,
      customAttributes: [
        {
          label: 'User ID',
          value: String(user.user_id),
        },
        {
          label: 'Name',
          value: user.name,
        },
        {
          label: 'Organization ID',
          value: String(user.org_id),
        },
        {
          label: 'Organization Name',
          value: user.org_name,
        },
      ],
    });

    console.debug('Mava initialized and identified');
  }, []);

  const handleGoogleLoginSuccess = useCallback(
    async (credentialResponse: CredentialResponse) => {
      const idToken = credentialResponse?.credential;

      console.debug('id token: ', idToken);

      let res: AxiosResponse<{ accessToken: string; user: User }>;

      try {
        setIsLoggingIn(true);
        res = await axiosAuth.get<{ accessToken: string; user: User }>('/auth/login', {
          headers: { Authorization: `Bearer ${idToken}` },
        });

        await handleInitializeMava(res.data.user);
      } catch (_err) {
        const err = _err as AxiosError<{ message: string }>;

        addAlert({
          title: `Login failed (${err?.response?.statusText})`,
          severity: ALERT_SEVERITY.ERROR,
          desc: `${err?.response?.data?.message}`,
        });

        return;
      } finally {
        setIsLoggingIn(false);
      }

      const { accessToken, user } = res?.data || {};

      authUtil.setAccessToken(accessToken);
      setUser(user);
      setIsLoggedIn(true);

      if (!user?.org_id) {
        navigate(PATHS.CREATE_ORG);
      } else {
        navigate(loginSuccessRedirectPath || PATHS.DEFAULT);
      }
    },
    [addAlert, handleInitializeMava, loginSuccessRedirectPath, navigate, setIsLoggedIn, setUser],
  );

  return (
    <>
      <Modal open={isLoggingIn}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            flex: 1,
            position: 'relative',
            width: '100vw',
            height: '100vh',
          }}
        >
          <CircularProgress size={50} sx={{ color: '#EAEAEAAA' }} />
        </Stack>
      </Modal>
      <Grid container>
        <Grid
          container
          item
          md={6}
          sx={{
            display: { xs: 'none', md: 'flex' },
            flex: 1,
            background: theme.colors.gradients.water,
            height: '85vh',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden',
            pr: 20,
          }}
        >
          <Box
            component="img"
            src="imgs/altLayerLg.svg"
            sx={{
              position: 'absolute',
              transform: 'translate(-50%,-50%)',
              left: '50%',
              top: '45%',
            }}
          />
          <Box
            sx={{
              background: `url(${process.env.PUBLIC_URL}/imgs/loginBgLeft.png)`,
              minWidth: '756px',
              flex: 1,
              backgroundSize: 'contain',
              backgroundRepeat: { xs: 'repeat', md: 'no-repeat' },
              backgroundPositionX: 'right',
              mt: 3,
              mb: 1.5,
            }}
          />
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            flex: 1,
            background: theme.colors.gradients.water,
            height: '85vh',
            overflow: 'hidden',
            pl: 7,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              background: {
                xs: `url(${process.env.PUBLIC_URL}/imgs/loginBgRight.png)`,
                sm: `url(${process.env.PUBLIC_URL}/imgs/loginBgRightSm.png)`,
                xl: `url(${process.env.PUBLIC_URL}/imgs/loginBgRight.png)`,
              },
              minWidth: '1000px',
              height: { xs: 'calc(100% - 80px)', xl: '100%' },
              backgroundSize: { xs: 'contain', sm: 'contain', xl: 'contain' },
              backgroundRepeat: { xs: 'repeat', xl: 'no-repeat' },
            }}
          >
            <Stack flex={1} justifyContent="center" sx={{ pt: '25vh' }}>
              <Typography variant="h2">
                Welcome to
                <br />
                Wizard
              </Typography>
              <Box sx={{ maxWidth: '20rem', width: 'max-content', mt: 3 }}>
                <GoogleLogin
                  // click_listener={() => setIsLoggingIn(true)}
                  onError={() => {
                    console.error('Login Failed');
                  }}
                  onSuccess={handleGoogleLoginSuccess}
                />
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '40px',
              right: '40px',
              textAlign: 'right',
              background: 'transparent',
            }}
          >
            <Link component={RouterLink} to="/disclaimer">
              TERMS OF SERVICE
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
