import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconEdit = ({ ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M12.5 1.00977L12.995 1.50474L16.495 5.00474L17.0028 5.51257L16.4821 6.00721L6.48212 15.5072L6.32016 15.6611L6.099 15.6927L2.59899 16.1927L1.8 16.3068V15.4997V11.9997V11.7098L2.00503 11.5047L12.005 1.50474L12.5 1.00977ZM3.2 12.2897V14.6926L5.67984 14.3383L11.9203 8.40994L9.5 5.98967L3.2 12.2897ZM10.49 4.99972L12.9356 7.44537L14.9972 5.48686L12.5 2.98966L10.49 4.99972ZM19 18.6997H1V17.2997H19V18.6997Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
