import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconMagnifyingGlass = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M7.75 13.1C10.7047 13.1 13.1 10.7047 13.1 7.75C13.1 4.79528 10.7047 2.4 7.75 2.4C4.79528 2.4 2.4 4.79528 2.4 7.75C2.4 10.7047 4.79528 13.1 7.75 13.1ZM12.9924 12.0024C13.9351 10.8417 14.5 9.36183 14.5 7.75C14.5 4.02208 11.4779 1 7.75 1C4.02208 1 1 4.02208 1 7.75C1 11.4779 4.02208 14.5 7.75 14.5C9.36183 14.5 10.8417 13.9351 12.0024 12.9924L17.505 18.495L18.495 17.505L12.9924 12.0024ZM7.05 8.45V11H8.45V8.45H11V7.05H8.45V4.5H7.05V7.05H4.5V8.45H7.05Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
