import { useBlockscoutMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { gweiFormatter } from 'utils/recharts';

export const GasUsedGrowthMetric = () => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.FEE_TRACKER_GAS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'gasUsedGrowth',
    l2: true,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Cumulative gas used for the period'}
      id={'gas-used-growth'}
      loading={isPending}
      title={'Gas used growth'}
      tooltipValueFormatter={gweiFormatter}
      yAxisProps={{
        padding: { bottom: 40 },
        tickFormatter: gweiFormatter,
        tickMargin: -40,
      }}
    />
  );
};
