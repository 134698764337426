import {
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StatusLabel } from 'components/StatusLabel';
import dayjs from 'dayjs';
import { hideFeature } from 'helpers/visibility';
import { useRaasDeployment, useRaasMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { MetricGridItem } from 'pages/DeploymentDetails/MetricGridItem';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionButtonTimeFilter } from 'pages/DeploymentDetails/SectionButtonTimeFilter';
import { SubHeader } from 'pages/DeploymentDetails/SubHeader';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { WidgetMetric } from 'pages/DeploymentDetails/WidgetMetric';
import { useParams } from 'react-router-dom';
import { Panel } from 'types/protoc-gen/raas-metrics';

export const RpcUsageMonitoringSection = () => {
  const { rollupId } = useParams();
  const { isPending } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.RPC_USAGE_MONITORING_SECTION] || 'month',
  );

  const { data: rpmResult, isPending: getRpmResultPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_RPM,
    rollupId,
    step,
    start,
    end,
  });

  const { data: rpmByMethodsResult, isPending: getRpmByMethodsPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_RPM_BY_METHOD,
    rollupId,
    step,
    start,
    end,
  });

  return (
    <Section
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter
          loading={isPending}
          variant={TimeFilterVariant.RPC_USAGE_MONITORING_SECTION}
        />
      }
      title="RPC Usage Monitoring"
    >
      <Stack spacing="24px" sx={{ width: '100%' }}>
        <Grid container>
          {
            // TODO: remove hideFeature when backend is ready for integration
            !hideFeature && (
              <Grid xs={6}>
                <Stack sx={{ background: '#fafafa' }}>
                  <WidgetMetric
                    caption={'QPS'}
                    label={'Throughput Limit'}
                    loading={getRpmResultPending}
                    value={2_000}
                    xs={12}
                  />

                  <Stack
                    alignItems="start"
                    justifyContent="start"
                    spacing="16px"
                    sx={{
                      borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
                      borderRight: theme => `1px solid ${theme.colors.functional.subject.border}`,
                      px: '40px',
                      py: '24px',
                    }}
                  >
                    <StatusLabel
                      loading={getRpmResultPending}
                      sx={{ background: theme => theme.colors.gradients.leaf }}
                    >
                      Healthy
                    </StatusLabel>

                    {getRpmResultPending ? (
                      <Stack spacing="8px" width="100%">
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="75%" />
                      </Stack>
                    ) : (
                      <Typography variant="caption">
                        Your rollup has stayed under the monthly limit and throughput limit in the
                        past 24 hours.{' '}
                        <a href="#" style={{ textDecoration: 'underline' }} target="_blank">
                          Contact Support
                        </a>{' '}
                        to upgrade.
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            )
          }

          <MetricGridItem item xs={6}>
            <Metric
              chartType="area"
              data={rpmResult}
              id="real-time-qps"
              loading={getRpmResultPending}
              responsiveContainerSx={{ minHeight: '250px' }}
              title="Real-time QPS"
            />
          </MetricGridItem>

          <MetricGridItem item xs={6}>
            <Metric
              chartType="line"
              data={rpmByMethodsResult}
              hideLegend
              id="requests-by-methods"
              loading={getRpmByMethodsPending}
              responsiveContainerSx={{ minHeight: '250px' }}
              title="Requests by methods"
            />
          </MetricGridItem>

          {
            // TODO: remove hideFeature when backend is ready for integration
            !hideFeature && (
              <Grid item xs={12}>
                <SubHeader>Requests by method</SubHeader>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {['Method Name', 'Requests/Min'].map(header => (
                          <TableCell key={header}>
                            <Typography variant="captionC">{header}</Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        rpmByMethodsResult?.data.filter(
                          v => dayjs(v.date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY'),
                        )?.[0] ?? {},
                      ).map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell>{key}</TableCell>
                          <TableCell>{value.toLocaleString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )
          }
        </Grid>
      </Stack>
    </Section>
  );
};
