import { RequireLoginGuard } from 'guards';
import { AaasDeployments } from 'pages/AaasDeployments';
import { AaasDetails } from 'pages/AaasDetails';
import { routes as aaasDetailsRoutes } from 'pages/AaasDetails/routes';
import { AVSDeployments } from 'pages/AvsDeployments';
import { AVSDetails } from 'pages/AvsDetails';
import { routes as deploymentDetailsRoutes } from 'pages/DeploymentDetails/routes';
import { RaasAdmin } from 'pages/RaasAdmin';
import { routes as adminRaasRoutes } from 'pages/RaasAdmin/routes';
import { RaasDeployments } from 'pages/RaasDeployments';
import { SelectLayerType } from 'pages/SelectLayerType';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { AcceptInvite } from './pages/AcceptInvite';
import { CreateOrganization } from './pages/CreateOrganization';
import { DeploymentDetails } from './pages/DeploymentDetails';
import Disclaimer from './pages/Disclaimer';
import { Login } from './pages/Login';
import { NewAVS } from './pages/NewAVS';
import { NewDeployment } from './pages/NewDeployment';
import Privacy from './pages/Privacy';

export const PATHS = {
  DEFAULT: '/select',
  AAAS_DEPLOYMENTS: '/aaas',
  AAAS_DETAILS: '/aaas/:appId/details',
  AAAS_DETAILS_CHAT: '/aaas/:appId/chat',
  DEPLOYMENTS: '/rollups',
  NEW_DEPLOYMENT: '/rollups/new',
  DEPLOYMENT_DETAILS: '/rollups/:deploymentId',
  AVS_DEPLOYMENTS: '/avs',
  NEW_AVS: '/avs/new',
  AVS_DETAILS: '/avs/:deploymentId',
  SETTINGS: '/settings',
  LOGIN: '/login',
  CREATE_ORG: '/create-org',
  NEW_DEPLOYMENT_DEPR: '/new-deployment',
  ACCEPT_INVITE: '/invite/:id',
  ADMIN_RAAS: '/admin/raas',
  SELECT_LAYER_TYPE: '/select',
};

const DefaultRoute: React.FC = () => {
  return <Navigate to={PATHS.DEFAULT} />;
};

export const routes: RouteObject[] = [
  {
    element: <Login />,
    path: PATHS.LOGIN,
  },
  {
    element: <AcceptInvite />,
    path: PATHS.ACCEPT_INVITE,
  },
  {
    element: <CreateOrganization />,
    path: PATHS.CREATE_ORG,
  },
  {
    element: (
      <RequireLoginGuard>
        <SelectLayerType />
      </RequireLoginGuard>
    ),
    path: PATHS.SELECT_LAYER_TYPE,
  },
  {
    path: PATHS.AAAS_DEPLOYMENTS,
    element: (
      <RequireLoginGuard>
        <Outlet />
      </RequireLoginGuard>
    ),
    children: [
      {
        path: '',
        element: <AaasDeployments />,
      },
      {
        path: ':appId',
        element: <AaasDetails />,
        children: aaasDetailsRoutes,
      },
    ],
  },
  {
    path: PATHS.DEPLOYMENTS,
    element: (
      <RequireLoginGuard>
        <Outlet />
      </RequireLoginGuard>
    ),
    children: [
      {
        path: '',
        element: <RaasDeployments />,
      },
      {
        path: ':rollupId',
        element: <DeploymentDetails />,
        children: deploymentDetailsRoutes,
      },
      {
        path: 'new',
        element: <NewDeployment />,
      },
    ],
  },
  {
    path: PATHS.AVS_DEPLOYMENTS,
    element: (
      <RequireLoginGuard>
        <Outlet />
      </RequireLoginGuard>
    ),
    children: [
      { path: '', element: <AVSDeployments /> },
      { path: ':deploymentId', element: <AVSDetails /> },
      {
        path: 'new',
        element: <NewAVS />,
      },
    ],
  },
  {
    element: <Privacy />,
    path: '/privacy',
  },
  {
    element: <Disclaimer />,
    path: '/disclaimer',
  },
  {
    path: PATHS.ADMIN_RAAS,
    element: <RaasAdmin />,
    children: [...adminRaasRoutes],
  },
  {
    element: <DefaultRoute />,
    path: '*',
  },
];
