import SendIcon from '@mui/icons-material/Send';
import { alpha, CircularProgress, IconButton, TextField, TextFieldProps } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useScrollToElement } from './useScrollToElement';

export interface SubmitOpts {
  question: string | undefined;
  setQuestion: Dispatch<SetStateAction<string | undefined>>;
}

export const InputSection: React.FC<
  Omit<TextFieldProps, 'onSubmit'> & {
    onPrompt: (args: SubmitOpts) => void;
    loading: boolean;
  }
> = ({ loading, onPrompt, ...props }) => {
  const { scrollChatHistory, scrollToChatInput } = useScrollToElement();

  const [question, setQuestion] = useState<string | undefined>([''].join('\n'));
  const isDisabled = !question || loading;

  const handleSubmitPrompt = useCallback(() => {
    onPrompt({ question, setQuestion });
    setQuestion('');
    scrollChatHistory();
    scrollToChatInput();
  }, [onPrompt, question, scrollChatHistory, scrollToChatInput]);

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <IconButton
            disabled={props?.disabled || isDisabled}
            onClick={handleSubmitPrompt}
            sx={{
              position: 'absolute',
              bottom: '8px',
              right: '8px',
            }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <SendIcon
                sx={theme => ({
                  fill: isDisabled ? alpha(theme.palette.primary.light, 0.3) : 'primary',
                  color: theme.colors.functional.text.primary,
                })}
              />
            )}
          </IconButton>
        ),
      }}
      autoFocus
      id="chat-input"
      multiline
      onChange={e => setQuestion(e.target.value)}
      onKeyDown={e => {
        if (loading) return e.preventDefault();

        // shift + enter
        if (e.key === 'Enter' && e.shiftKey) {
          return; // Allow newline
        }

        if (e.key === 'Enter') {
          e.preventDefault();
          handleSubmitPrompt();
        }
      }}
      rows={4}
      sx={theme => ({
        color: theme.colors.functional.text.primary,
        borderColor: theme.colors.functional.subject.border,
        outlineColor: theme.colors.functional.subject.chosen,
      })}
      value={question}
      {...props}
    />
  );
};
