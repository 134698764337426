import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps,
  SxProps,
  Theme,
} from '@mui/material';
import { ReactNode } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

interface FormRadioButtonProps extends RadioProps {
  radioGroupConfig: { name: string; label?: ReactNode };
  assignedValue: string | number | boolean;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  controllerProps?: Partial<ControllerProps>;
  label?: string;
  labelProps?: Partial<FormControlLabelProps>;
  labelSx?: SxProps<Theme>;
  onChange?: (e: any) => void;
}

const FormRadioButton = ({
  assignedValue,
  controllerProps = {},
  label,
  labelPlacement = 'end',
  labelProps,
  labelSx = {},
  radioGroupConfig,
  ...radioProps
}: FormRadioButtonProps) => {
  const { control, setValue } = useFormContext();

  const handleChange = () => {
    setValue(radioGroupConfig.name, assignedValue, { shouldTouch: true, shouldDirty: true });
  };

  return (
    <Controller
      control={control}
      name={radioGroupConfig.name}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Radio
              {...field}
              checked={field.value === assignedValue}
              inputRef={field.ref}
              name="radio-button"
              onBlur={field.onBlur}
              onChange={handleChange}
              ref={undefined}
              value={assignedValue}
              {...radioProps}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
          sx={labelSx}
          {...labelProps}
        />
      )}
      {...controllerProps}
    />
  );
};

export default FormRadioButton;
