import { SvgIconProps } from '@mui/material';
import { IconBinance } from 'components/icons/IconBinance';
import IconEth from 'components/icons/IconEth';

export interface ITokenSymbolIcon extends SvgIconProps {
  symbol: string;
}

export const TokenSymbolIcon = ({ sx, symbol, ...props }: ITokenSymbolIcon) => {
  const styleOverrides = { height: '20px', width: '20px', ...sx };

  return (
    {
      BNB: <IconBinance {...props} sx={styleOverrides} />,
      ETH: <IconEth {...props} sx={styleOverrides} />,
    }?.[symbol] ?? <IconEth {...props} sx={styleOverrides} />
  );
};
