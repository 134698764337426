import { Stack, StackProps } from '@mui/material';

export const DialogContainer: React.FC<StackProps> = ({ children, ...props }) => (
  <Stack
    spacing="40px"
    {...props}
    sx={{
      minWidth: '504px',
      p: '40px',
      ...props.sx,
    }}
  >
    {children}
  </Stack>
);
