import dayjs from 'dayjs';
import { useHealthz } from 'hooks/aaas/useHealthz';
import { useChatStore } from 'pages/AaasDetails/useChatStore';
import { useEffect } from 'react';
import { AAA } from 'types/protoc-gen/aaas-bffavs';

export const useStatus = (app: AAA | undefined) => {
  const { deployed, deploying, reset, setDeployed, setDeploying } = useChatStore();
  const { error, isError, isPending, isSuccess } = useHealthz({ appId: app?.id });

  useEffect(() => {
    reset(app?.id);
  }, [app, reset]);

  useEffect(() => {
    setDeployed(app?.id);
  }, [app, isSuccess, setDeployed]);

  useEffect(() => {
    setDeploying(app?.id);
  }, [app, isError, setDeploying, error]);

  useEffect(() => {
    console.debug('minute:', dayjs().diff(dayjs(app?.createdAt), 'minute'));

    if (isError || dayjs().diff(dayjs(app?.createdAt), 'minute') < 8) {
      setDeploying(app?.id);
    } else {
      setDeployed(app?.id);
    }
  }, [app, isError, isPending, setDeployed, setDeploying]);

  return {
    isPending,
    deployed: !isError && app && typeof deployed === 'object' ? deployed[app?.id] : false,
    deploying: isError && app && typeof deploying === 'object' ? deploying[app?.id] : false,
  };
};
