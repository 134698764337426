import { MetricGridItem } from 'pages/DeploymentDetails/MetricGridItem';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionButtonTimeFilter } from 'pages/DeploymentDetails/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/DeploymentDetails/useTimeFilterStore';

import { WithdrawalStatusMetric } from './WithdrawalStatusMetric';
import { WithdrawalTxnsGrowthMetric } from './WithdrawalTxnsGrowthMetric';
import { WithdrawalVolumeEthGrowthMetric } from './WithdrawalVolumeEthGrowthMetric';

export const WithdrawalsSection = () => {
  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <WithdrawalTxnsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <WithdrawalVolumeEthGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item xs={12}>
            <WithdrawalStatusMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter variant={TimeFilterVariant.BRIDE_METRICS_WITHDRAWALS_SECTION} />
      }
      sectionContentSx={{ p: '0px' }}
      title="Withdrawals"
    />
  );
};
