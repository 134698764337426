import { Grid } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const DeploymentGridContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid
      container
      sx={{
        background: '#fafafa',
        '& > *': {
          minHeight: '12rem',
          borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
          borderRight: theme => `1px solid ${theme.colors.functional.subject.border}`,
        },
      }}
    >
      {children}
    </Grid>
  );
};
