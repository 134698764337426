import { Stack, Typography } from '@mui/material';
import { Section } from 'pages/DeploymentDetails/Section';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from 'routes';

import { EditableInput } from './EditableInput';
import { StackItem } from './StackItem';

export const CustomizationView: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const handleClickContactUs = useCallback(
    () => navigate(`${PATHS.DEPLOYMENTS}/${params.deploymentId}/troubleshooting/contact-us`),
    [navigate, params.deploymentId],
  );

  return (
    <>
      <Section
        renderContent={
          <Stack spacing="8px">
            <StackItem>
              <EditableInput
                disabledRefreshButton
                label="Domain for Explorer"
                showValidation={false}
              />
            </StackItem>

            <StackItem>
              <EditableInput
                externalLink="https://wizard.altlayer.io"
                label="Xterio RPC Endpoint"
                labelChipValue="RPC Endpoint"
                showValidation
                validConfiguration={false}
              />
            </StackItem>

            <StackItem>
              <Stack spacing="16px">
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <Typography variant="bodySmallM">Domain for Bridge</Typography>
                  <Typography variant="bodySmall">
                    Please open a ticket on <a onClick={handleClickContactUs}>Contact Us</a> to
                    apply for the bridge custom domain.
                  </Typography>
                </Stack>
              </Stack>
            </StackItem>
          </Stack>
        }
        title="Custom Domains"
      />
    </>
  );
};
