import { Button, ButtonProps, Stack, StackProps, Typography } from '@mui/material';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';

export interface ISectionButtonTimeFilter extends Omit<StackProps, 'children'> {
  variant?: TimeFilterVariant;
  loading?: boolean;
}

export const SectionButtonTimeFilter = ({
  loading,
  variant,
  ...props
}: ISectionButtonTimeFilter) => {
  const { selectedTimeFilter, setSelectedTimeFilter } = useTimeFilterStore();

  return (
    <Stack direction="row" spacing="16px" {...props}>
      <_Button
        active={variant && selectedTimeFilter[variant] === 'month'}
        disabled={loading}
        onClick={() => variant && setSelectedTimeFilter(variant, 'month')}
      >
        Month
      </_Button>
      <_Button
        active={variant && selectedTimeFilter[variant] === 'week'}
        disabled={loading}
        onClick={() => variant && setSelectedTimeFilter(variant, 'week')}
      >
        Week
      </_Button>
    </Stack>
  );
};

const _Button = ({ active, children, sx, ...props }: ButtonProps & { active?: boolean }) => (
  <Button
    disableElevation
    size="small"
    {...props}
    sx={{
      color: !active ? theme => theme.colors.functional.text.primary : undefined,
      background: !active ? 'transparent' : undefined,
      height: '20px',
      minWidth: '43px',
      py: '1px',
      px: '8px',
      m: 0,
      '&:hover': {
        color: !active ? theme => theme.colors.functional.text.primary : undefined,
        background: !active ? 'transparent' : undefined,
      },
      ...sx,
    }}
  >
    <Typography variant="captionC">{children}</Typography>
  </Button>
);
