import { BlocksSection } from './BlocksSection';
import { ContractsSection } from './ContractsSection';
import { EoaAccountsSection } from './EoaAccountsSection';
import { OverviewSection } from './OverviewSection';
import { TokensSection } from './TokensSection';
import { TransactionsSection } from './TransactionsSection';

export const RollupStatisticsView: React.FC = () => {
  return (
    <>
      <OverviewSection />
      <EoaAccountsSection />
      <TransactionsSection />
      <BlocksSection />
      <TokensSection />
      <ContractsSection />
    </>
  );
};
