import { Grid, GridProps, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { IconCopy } from 'components/icons/IconCopy';
import { ConditionalTooltip } from 'components/Tooltip/ConditionalTooltip';
import { useCopyToClipboard } from 'hooks/ui';
import { ReactNode, useCallback } from 'react';
import { truncate as truncateString } from 'utils/strings';

export interface ISectionContentKeyValue extends Omit<GridProps, 'children'> {
  label: string;
  value: string | undefined;
  truncate?: boolean;
  gridItem?: boolean;
  renderIcon?: ReactNode;
  clipboard?: boolean | undefined;
  hyperlink?: boolean;
  truncateMaxLength?: number;
  loading?: boolean;
  endAdornment?: ReactNode;
  renderValue?: ReactNode;
}

export const SectionContentKeyValue = ({
  clipboard,
  endAdornment,
  gridItem,
  hyperlink,
  label,
  loading,
  renderIcon,
  renderValue,
  sx,
  truncate,
  truncateMaxLength,
  value,
  ...props
}: ISectionContentKeyValue) => {
  const [, copy] = useCopyToClipboard();

  const handleCopyToClipboard = useCallback(() => copy(value ?? ''), [copy, value]);

  const valueTypography: ReactNode = (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="start"
      spacing="8px"
      sx={{ cursor: clipboard ? 'pointer' : 'text' }}
    >
      {renderIcon}
      <Typography
        {...(hyperlink ? { component: 'a', href: value, target: '_blank' } : undefined)}
        onClick={clipboard && !hyperlink ? handleCopyToClipboard : undefined}
        sx={theme => ({
          cursor: clipboard ? 'pointer' : 'text',
          color: theme.colors.functional.text.primary,
          textDecoration: hyperlink ? 'underline' : 'none',
        })}
        variant="bodySmallM"
      >
        {truncate ? truncateString(value ?? '', '...', truncateMaxLength ?? 26, 0) : value}
      </Typography>
      {clipboard && (
        <IconButton onClick={handleCopyToClipboard} size="small">
          <IconCopy sx={{ cursor: 'pointer', height: '20px', width: '20px' }} />
        </IconButton>
      )}
    </Stack>
  );

  return (
    <Grid
      container
      flexDirection="column"
      item={gridItem}
      mb="24px"
      sx={{ ...sx }}
      xs={3}
      {...props}
    >
      <Stack spacing="8px">
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="caption">{label}</Typography>

          {endAdornment}
        </Stack>

        {loading ? (
          <Skeleton variant="text" width="65%" />
        ) : (
          renderValue ?? (
            <>
              {truncate ? (
                <ConditionalTooltip placement="bottom" title={value}>
                  {valueTypography}
                </ConditionalTooltip>
              ) : (
                valueTypography
              )}
            </>
          )
        )}
      </Stack>
    </Grid>
  );
};
