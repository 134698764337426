import { useBlockscoutMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';

export const NewContractsMetric = () => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.ROLLUP_STATS_CONTRACTS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'newContracts',
    l2: true,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Number of new contracts in the network'}
      id={'new-contracts'}
      loading={isPending}
      title={'New contracts'}
      yAxisProps={{
        tickMargin: -5,
      }}
    />
  );
};
