import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconOverview = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={{ height: '20px', width: '20px', ...sx }} {...props} viewBox="0 0 20 20">
    <path
      clipRule="evenodd"
      d="M2.4 2.4H7.3V6.3H2.4V2.4ZM8.7 6.3V2.4H17.6V6.3H8.7ZM2.4 7.7V17.6H17.6V7.7H2.4ZM1 1H2.4H17.6H19V2.4V17.6V19H17.6H2.4H1V17.6V2.4V1ZM13.7 16V9.5H12.3V16L13.7 16ZM16.2 12.5V16H14.8V12.5H16.2ZM7.09961 11.0999C8.09372 11.0999 8.89961 11.9058 8.89961 12.8999C8.89961 13.894 8.09372 14.6999 7.09961 14.6999C6.1055 14.6999 5.29961 13.894 5.29961 12.8999H3.89961C3.89961 14.6672 5.3323 16.0999 7.09961 16.0999C8.86692 16.0999 10.2996 14.6672 10.2996 12.8999C10.2996 11.1326 8.86692 9.6999 7.09961 9.6999V11.0999Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
