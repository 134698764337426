import { hideFeature } from 'helpers/visibility';
import { RollupType } from 'labels/raas';

export const getSideNavigationItems = (
  path: string | undefined,
  rollupType: RollupType,
): {
  id: string;
  label: string;
  items: {
    label: string;
    href?: string;
    to: string;
  }[];
}[] =>
  [
    {
      id: 'rollup-management',
      label: 'Rollup Management',
      items: [
        {
          label: 'Rollup Info',
          to: `${path}/info`,
        },
        ...(hideFeature
          ? []
          : [
              {
                label: 'Start a Fullnode',
                to: `${path}/start-fullnode`,
              },
              {
                label: 'Customization',
                to: `${path}/customization`,
              },
            ]),
      ],
    },
    {
      id: 'operational-metrics',
      label: 'Operational Metrics',
      items: [
        {
          label: 'Rollup Statistics',
          to: `${path}/stats`,
        },
        ...(hideFeature
          ? []
          : [
              {
                label: 'Bridge Metrics',
                to: `${path}/bridge`,
              },
            ]),
        {
          label: 'Fee Tracker',
          to: `${path}/fee-tracker`,
        },
      ],
    },
    {
      id: 'technical-metrics',
      label: 'Technical Metrics',
      items: [
        {
          label: 'Blockchain Statistics',
          to: `${path}/blockchain-stats`,
        },
        {
          label: 'Rollup Statistics',
          to: `${path}/rollup-statistics`,
        },
        {
          label: 'Proposer',
          to: `${path}/proposer`,
        },
        {
          label: 'Batcher',
          to: `${path}/batcher`,
        },
      ],
    },
    ...(hideFeature
      ? [
          {
            id: 'plugin-service',
            label: 'Plugin Service',
            items: [
              {
                label: 'RPC Usage Monitoring',
                to: `${path}/rpc`,
              },
            ],
          },
        ]
      : [
          {
            id: 'plugin-service',
            label: 'Plugin Service',
            items: [
              {
                label: 'Auto Withdrawal Fee',
                to: `${path}/withdrawal-fee`,
              },
              {
                label: 'Balance Alert',
                to: `${path}/balance`,
              },
            ],
          },
        ]),
    {
      id: 'troubleshooting',
      label: 'Troubleshooting',
      items: [
        {
          label: 'FAQ',
          to: `${path}/faq`,
        },
        {
          label: 'Contact us',
          to: `${path}/contact-us`,
        },
      ],
    },
  ].filter(({ id }) => {
    // Show everything for OP Stack
    if (rollupType === RollupType.OPSTACK) return true;

    // Only show basic panels for other rollup types
    return ['rollup-management', 'troubleshooting'].includes(id);
  });
