import { MetricGridItem } from 'pages/DeploymentDetails/MetricGridItem';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionButtonTimeFilter } from 'pages/DeploymentDetails/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/DeploymentDetails/useTimeFilterStore';

import { DepositTxnsGrowthMetric } from './DepositTxnsGrowthMetric';
import { DepositVolumeEthGrowthMetric } from './DepositVolumeEthGrowthMetric';
import { NewDepositedAccountsMetric } from './NewDepositedAccountsMetric';

export const DepositsSection = () => {
  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <DepositTxnsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <DepositVolumeEthGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <NewDepositedAccountsMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter variant={TimeFilterVariant.BRIDE_METRICS_DEPOSITS_SECTION} />
      }
      sectionContentSx={{ p: '0px' }}
      title="Deposits"
    />
  );
};
