import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconDelete: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M7.5 2.75H12.5V1.75H7.5V2.75ZM3 4.75H4.5V17.25V17.75H5H15H15.5V17.25V4.75H17V3.75H15H5H3V4.75ZM5.5 16.75V4.75H14.5V16.75H5.5ZM7.75 7.25V14.25H8.75V7.25H7.75ZM11.25 7.25V14.25H12.25V7.25H11.25Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
