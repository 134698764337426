import { useMemo } from 'react';

export const useLinearGradients = (prefix?: string) =>
  useMemo<
    {
      id: string;
      stops: React.SVGAttributes<SVGStopElement>[];
    }[]
  >(
    () => [
      {
        id: [prefix, 'metal'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#F6F6F6', stopOpacity: 0.05 },
          { offset: '95%', stopColor: '#E7E7E7', stopOpacity: 0.65 },
        ],
      },
      {
        id: [prefix, 'water'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#93B4F3', stopOpacity: 0.05 },
          { offset: '95%', stopColor: '#5C8CE1', stopOpacity: 0.65 },
        ],
      },
      {
        id: [prefix, 'cream'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#FFF5D5', stopOpacity: 0.05 },
          { offset: '95%', stopColor: '#EFEADB', stopOpacity: 0.65 },
        ],
      },
      {
        id: [prefix, 'pistachio'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#EAF4DC', stopOpacity: 0.05 },
          { offset: '95%', stopColor: '#D5E9BB', stopOpacity: 0.65 },
        ],
      },
      {
        id: [prefix, 'leaf'].join('-'),
        stops: [
          { offset: '5%', stopColor: '#74BA94', stopOpacity: 0.05 },
          { offset: '95%', stopColor: '#97C8AD', stopOpacity: 0.65 },
        ],
      },
    ],
    [prefix],
  );
