import { Collapse, CollapseProps, Stack, StackProps, Typography } from '@mui/material';

export interface IBanner extends CollapseProps {
  text?: string;
  closeText?: string;
  onClose: () => void;
  wrapperProps?: StackProps;
}

export const Banner: React.FC<IBanner> = ({
  children,
  closeText = 'Dismiss',
  onClose,
  text,
  wrapperProps,
  ...props
}) => (
  <Collapse
    sx={{
      position: 'sticky',
      top: 0,
      zIndex: 100,
    }}
    {...props}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      {...wrapperProps}
      sx={{
        bgcolor: '#000',
        px: '40px',
        py: '24px',
        color: 'white',
        ...wrapperProps?.sx,
      }}
    >
      {children || <Typography textAlign="start">{text}</Typography>}
      <Typography
        component="span"
        onClick={onClose}
        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
      >
        {closeText}
      </Typography>
    </Stack>
  </Collapse>
);
