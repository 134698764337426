import { Stack, StackProps, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';

export const ConditionalTooltip: FC<
  TooltipProps & { enabled?: boolean; wrapped?: boolean; wrapperProps?: StackProps }
> = ({ children, enabled, wrapped, wrapperProps, ...props }) =>
  enabled ? (
    <Tooltip {...props}>{wrapped ? <Stack {...wrapperProps}>{children}</Stack> : children}</Tooltip>
  ) : (
    children
  );
