import dayjs from 'dayjs';
import { useMemo } from 'react';

export const useTimeFilterOptions = (
  timeFilter?: 'month' | 'week',
): {
  start: string;
  end: string;
  step: '1h' | '10m' | '1d' | '24h' | '48h';
} =>
  useMemo(() => {
    return {
      start:
        timeFilter === 'month'
          ? dayjs().startOf('month').startOf('day').format('YYYY-MM-DD')
          : dayjs().startOf('week').startOf('day').format('YYYY-MM-DD'),
      end:
        timeFilter === 'month'
          ? dayjs().endOf('month').endOf('day').format('YYYY-MM-DD')
          : dayjs().endOf('week').endOf('day').format('YYYY-MM-DD'),
      step: '24h',
    };
  }, [timeFilter]);
