import { useBlockscoutMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';

export interface IAverageGasPriceMetric {
  timeFilterVariant?: TimeFilterVariant;
}

export const AverageGasPriceMetric = ({ timeFilterVariant }: IAverageGasPriceMetric) => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[timeFilterVariant ?? TimeFilterVariant.FEE_TRACKER_GAS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'averageGasPrice',
    l2: true,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Average gas price for the period (Gwei)'}
      id={'average-gas-price'}
      loading={isPending}
      title={'Average gas price'}
      tooltipValueFormatter={value => `${Number(value).toFixed(5)} Gwei`}
      yAxisProps={{
        padding: { bottom: 40 },
        tickFormatter: value => `${value} Gwei`,
        tickMargin: -40,
      }}
    />
  );
};
