import { darken } from '@mui/material';
import { connectorsForWallets, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { RainbowKitChain } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext';
import {
  coinbaseWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  tokenPocketWallet,
  trustWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError, HttpStatusCode } from 'axios';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Chain, Transport } from 'viem';
import { arbitrumSepolia, holesky, sepolia } from 'viem/chains';
import { Config, createConfig, fallback, http, WagmiProvider as _WagmiProvider } from 'wagmi';

import { ALERT_SEVERITY, useAlerts } from './AlertsContext';

type Chains = readonly [Chain, ...Chain[]];
type Transports = Record<Chains[number]['id'], Transport>;
interface Context {
  config: Config;
  chains: readonly [RainbowKitChain, ...RainbowKitChain[]];
  transports: Transports;
  setTransports: Dispatch<SetStateAction<Transports>>;
  setChains: Dispatch<SetStateAction<readonly [RainbowKitChain, ...RainbowKitChain[]]>>;
  handleSetTransports: (newTransports: Transports) => void;
  handleSetChains: (newChain: Chain) => void;
}

export const WagmiContext = createContext<Context>({} as Context);

export const useWagmi = () => {
  return useContext(WagmiContext);
};

const theme = lightTheme();

// These 2 colors should be the same for UI to look cohesive
theme.colors.accentColor = darken('#000', 0.5); // Affects connect modal font colors and 'Get a Wallet' button background
theme.colors.modalTextSecondary = darken('#000', 0.5); // Affects other connect modal font colors

theme.colors.profileForeground = '#EAF4DC';
theme.colors.modalBackground = '#EAF4DC';
theme.colors.connectButtonBackground = 'transparent';
theme.colors.connectButtonInnerBackground = 'transparent';
theme.colors.generalBorder = 'transparent';
theme.colors.accentColorForeground = '#f7f7f7';
theme.colors.actionButtonSecondaryBackground = darken('#202723', 0.02);
theme.colors.profileAction = '#D5E9BB';

theme.radii.modal = '0px';
theme.radii.connectButton = '0px';
theme.radii.actionButton = '0px';
theme.radii.menuButton = '0px';
theme.radii.modalMobile = '0px';

theme.shadows.connectButton = 'none';

theme.fonts.body = 'ABCDiatypeSemiMono'; // Default value: SFRounded, ui-rounded, "SF Pro Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

export const WagmiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [chains, setChains] = useState<readonly [RainbowKitChain, ...RainbowKitChain[]]>([
    holesky,
    sepolia,
    arbitrumSepolia,
  ]);
  const [transports, setTransports] = useState<Transports>({});
  const { addAlert } = useAlerts();

  const handleSetTransports = useCallback(
    (newTransports: Transports) => setTransports({ ...transports, ...newTransports }),
    [transports],
  );
  const handleSetChains = useCallback(
    (newChain: Chain) => setChains([...chains, newChain]),
    [chains],
  );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            /**
             * @param refetchOnWindowFocus
             * @description
             * This is disabled on dev server to avoid confusion during development.
             * e.g. when an additional query is noticed on the network tab, a dev might think it is due to an unnecessary re-render.
             *
             * It is enabled on prod builds to give a small UX boost.
             */
            refetchOnWindowFocus: false, // TODO: This refetches unnecessarily when set to true, causing some generated keys to change. Disable temporarily on prod until demo over / funding rollup is moved into modal step
            staleTime: 60000,
            retry: process.env.NODE_ENV === 'production', // retry on prod only. Do not retry on dev for debugging purposes.
          },
          mutations: {
            onError: _error => {
              const error = _error as AxiosError<{ message: string }>;
              const { status: httpStatus, statusText: httpStatusText } = error?.response || {};

              if (
                httpStatus === HttpStatusCode.TooManyRequests &&
                !error?.response?.data?.message
              ) {
                addAlert({
                  severity: ALERT_SEVERITY.WARNING,
                  title: 'Request failed due to high network traffic.',
                  desc: 'Some features may not work as expected. Please try again in awhile.',
                });

                return;
              }

              addAlert({
                severity: ALERT_SEVERITY.ERROR,
                title: `Request failed${httpStatusText ? `: ${httpStatusText}` : '.'}`,
                desc: error?.response?.data?.message || 'An unexpected error occured',
              });
            },
          },
        },
      }),
    [addAlert],
  );

  const connectors = useMemo(
    () =>
      connectorsForWallets(
        [
          {
            groupName: 'Recommended',
            wallets: [
              metaMaskWallet,
              rabbyWallet,
              walletConnectWallet,
              coinbaseWallet,
              okxWallet,
              tokenPocketWallet,
              trustWallet,
            ],
          },
        ],
        {
          appName: 'AltLayer Launchpad',
          projectId: '3d27bfc93787d97f1103ec8fa5367aad',
        },
      ),
    [],
  );

  const config = useMemo(
    () =>
      createConfig({
        connectors,
        chains: [holesky, ...chains],
        transports: {
          [holesky?.id]: fallback(
            [
              http('https://holesky.drpc.org'),
              http('https://rpc.holesky.ethpandaops.io'),
              http('https://ethereum-holesky-rpc.publicnode.com'),
              http('https://ethereum-holesky.blockpi.network/v1/rpc/public'),
              // http('https://1rpc.io/holesky'), // Exclude due to large payload revert
              // http('https://rpc-holesky.rockx.com'), // Exclude due to obscure error messages
            ],
            {
              rank: {
                interval: 30_000,
              },
            },
          ),
          [sepolia?.id]: fallback(
            [
              http('https://ethereum-sepolia-rpc.publicnode.com'),
              http('https://sepolia.drpc.org'),
              http('https://eth-sepolia.public.blastapi.io'),
              http('https://rpc.sepolia.org'),
              http('https://rpc2.sepolia.org'),
              // http('https://1rpc.io/sepolia'), // Exclude due to large payload revert
            ],
            {
              rank: {
                interval: 30_000,
              },
            },
          ),
          [arbitrumSepolia?.id]: fallback(
            [
              http('https://arbitrum-sepolia.blockpi.network/v1/rpc/public'),
              http('https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'),
              http('https://arbitrum-sepolia.gateway.tenderly.co'),
              http('https://sepolia-rollup.arbitrum.io/rpc'),
            ],
            {
              rank: {
                interval: 30_000,
              },
            },
          ),
          ...transports,
        },
      }),
    [chains, connectors, transports],
  );

  return (
    <WagmiContext.Provider
      value={{
        chains,
        config,
        setChains,
        transports,
        setTransports,
        handleSetChains,
        handleSetTransports,
      }}
    >
      <_WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
          <RainbowKitProvider showRecentTransactions theme={theme}>
            {children}
          </RainbowKitProvider>
        </QueryClientProvider>
      </_WagmiProvider>
    </WagmiContext.Provider>
  );
};
