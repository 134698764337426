import { useRaasMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { useParams } from 'react-router-dom';
import { Panel } from 'types/protoc-gen/raas-metrics';

export const MemoryMetric = () => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.BLOCKCHAIN_STATS_GENERAL_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    rollupId,
    panel: Panel.PANEL_OPSTACK_MEMORY,
    start,
    end,
    step,
  });

  return (
    <Metric
      chartType="line"
      data={result}
      id={'memory'}
      legendDirection={'column'}
      legendSpacing={'4px'}
      loading={isPending}
      title={'Memory'}
      yAxisProps={{
        padding: { bottom: 35 },
        tickFormatter: value => [new Intl.NumberFormat().format(value), 'GB'].join(' '),
        tickMargin: -5,
      }}
    />
  );
};
