import { Menu, MenuItem, MenuProps } from '@mui/material';

export interface IMetricContainerMoreMenu extends MenuProps {
  onClickExpand?: React.MouseEventHandler<HTMLLIElement>;
}

export const MetricContainerMoreMenu = ({
  anchorEl,
  onClickExpand,
  onClose,
  open,
  ...props
}: IMetricContainerMoreMenu) => {
  return (
    <Menu anchorEl={anchorEl} onClose={onClose} open={open} {...props}>
      <MenuItem onClick={onClickExpand}>Expand</MenuItem>
    </Menu>
  );
};
