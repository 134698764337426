import { Divider, Stack, Typography } from '@mui/material';
import { DeploymentCard } from 'components/Deployment';
import {
  renderDaTypeIcon,
  renderRollupSettlementLayerIcon,
  renderRollupTypeIcon,
} from 'labels/raas';
import { RollupStatusChip } from 'pages/DeploymentDetails/RollupStatusChip';
import { useNavigate } from 'react-router-dom';
import { Rollup } from 'types/protoc-gen/raas-rollup';

export const RaasDeploymentCard = ({ rollup }: { rollup: Rollup }) => {
  const navigate = useNavigate();

  return (
    <DeploymentCard
      onClickHeader={() => navigate(`/rollups/${rollup.id}`)}
      title={rollup?.name}
      titleStartAdornment={<RollupStatusChip rollup={rollup} />}
    >
      <Stack direction="row" py={3}>
        <Stack flex={1} spacing="8px">
          <Typography variant="caption">Rollup Stack</Typography>
          <Stack alignItems="center" direction="row" justifyContent="start" spacing="8px">
            {renderRollupTypeIcon(rollup.type)}
            <Typography variant="bodySmallM">{rollup?.type}</Typography>
          </Stack>
        </Stack>
        <Stack flex={1} spacing="8px">
          <Typography variant="caption">Settlement Layer</Typography>
          <Stack direction="row" spacing="8px">
            {renderRollupSettlementLayerIcon(rollup?.settlementLayer)}
            <Typography variant="bodySmallM">{rollup?.settlementLayer}</Typography>
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <Stack py={3} spacing="8px">
        <Typography variant="caption">Data Availability</Typography>
        <Stack direction="row" spacing="8px">
          {renderDaTypeIcon(rollup.dataAvailabilityLayer)}
          <Typography variant="bodySmallM">{rollup.dataAvailabilityLayer}</Typography>
        </Stack>
      </Stack>
    </DeploymentCard>
  );
};
