import { SvgIcon, SvgIconProps } from '@mui/material';

const IconAnyTrustDa = ({ ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M1.80859 6.07981V13.9198C1.80859 14.4238 2.07259 14.8798 2.51259 15.1358L9.30459 19.0558C9.73659 19.3038 10.2726 19.3038 10.7046 19.0558L17.4966 15.1358C17.9286 14.8878 18.2006 14.4238 18.2006 13.9198V6.07981C18.2006 5.57581 17.9366 5.11981 17.4966 4.86381L10.7046 0.943812C10.2726 0.695813 9.73659 0.695813 9.30459 0.943812L2.51259 4.86381C2.08059 5.11181 1.81659 5.57581 1.81659 6.07981H1.80859Z"
      fill="#213147"
    />
    <path
      d="M11.4801 11.5202L10.5121 14.1762C10.4881 14.2482 10.4881 14.3282 10.5121 14.4082L12.1761 18.9762L14.1041 17.8642L11.7921 11.5202C11.7361 11.3762 11.5361 11.3762 11.4801 11.5202Z"
      fill="#12AAFF"
    />
    <path
      d="M13.4246 7.056C13.3686 6.912 13.1686 6.912 13.1126 7.056L12.1446 9.712C12.1206 9.784 12.1206 9.864 12.1446 9.944L14.8726 17.424L16.8006 16.312L13.4246 7.064V7.056Z"
      fill="#12AAFF"
    />
    <path
      d="M10.0007 1.24C10.0487 1.24 10.0967 1.256 10.1367 1.28L17.4807 5.52C17.5687 5.568 17.6167 5.664 17.6167 5.76V14.24C17.6167 14.336 17.5607 14.432 17.4807 14.48L10.1367 18.72C10.0967 18.744 10.0487 18.76 10.0007 18.76C9.95272 18.76 9.90472 18.744 9.86472 18.72L2.52072 14.48C2.43272 14.432 2.38472 14.336 2.38472 14.24V5.752C2.38472 5.656 2.44072 5.56 2.52072 5.512L9.86472 1.272C9.90472 1.248 9.95272 1.232 10.0007 1.232V1.24ZM10.0007 0C9.73672 0 9.48072 0.064 9.24072 0.2L1.89672 4.44C1.42472 4.712 1.13672 5.208 1.13672 5.752V14.232C1.13672 14.776 1.42472 15.272 1.89672 15.544L9.24072 19.784C9.47272 19.92 9.73672 19.984 10.0007 19.984C10.2647 19.984 10.5207 19.92 10.7607 19.784L18.1047 15.544C18.5767 15.272 18.8647 14.776 18.8647 14.232V5.752C18.8647 5.208 18.5767 4.712 18.1047 4.44L10.7527 0.2C10.5207 0.064 10.2567 0 9.99272 0H10.0007Z"
      fill="#9DCCED"
    />
    <path
      d="M5.13672 17.4322L5.81672 15.5762L7.17672 16.7042L5.90472 17.8722L5.13672 17.4322Z"
      fill="#213147"
    />
    <path
      d="M9.37698 5.15204H7.51298C7.37698 5.15204 7.24898 5.24004 7.20098 5.36804L3.20898 16.312L5.13698 17.424L9.53698 5.36804C9.57698 5.25604 9.49698 5.14404 9.38498 5.14404L9.37698 5.15204Z"
      fill="white"
    />
    <path
      d="M12.6411 5.15204H10.7771C10.6411 5.15204 10.5131 5.24004 10.4651 5.36804L5.90513 17.864L7.83313 18.976L12.7931 5.36804C12.8331 5.25604 12.7531 5.14404 12.6411 5.14404V5.15204Z"
      fill="white"
    />
  </SvgIcon>
);

export default IconAnyTrustDa;
