import { hideFeature } from 'helpers/visibility';
import { useRaasDeployment } from 'hooks';
import { RollupType } from 'labels/raas';
import { MetricGridItem } from 'pages/DeploymentDetails/MetricGridItem';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionButtonTimeFilter } from 'pages/DeploymentDetails/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/DeploymentDetails/useTimeFilterStore';
import { AverageGasPriceMetric } from 'pages/DeploymentDetails/views/FeeTrackerView/AverageGasPriceMetric';

import { BridgeTVLMetric } from './BridgeTVLMetric';
import { TxPoolMetric } from './TxPoolMetric';

export const MetricsSection = () => {
  const { data: rollup, isPending } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          {!hideFeature && (
            <MetricGridItem item>
              <BridgeTVLMetric />
            </MetricGridItem>
          )}
          {rollup?.type === RollupType.OPSTACK && (
            <MetricGridItem item>
              <TxPoolMetric />
            </MetricGridItem>
          )}
          <MetricGridItem item>
            <AverageGasPriceMetric timeFilterVariant={TimeFilterVariant.OVERVIEW_METRICS_SECTION} />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        !isPending && (
          <SectionButtonTimeFilter variant={TimeFilterVariant.OVERVIEW_METRICS_SECTION} />
        )
      }
      sectionContentSx={{ p: '0px' }}
      title="Metrics"
    />
  );
};
