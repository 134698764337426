import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconAaa: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 80 80" {...props}>
    <path
      clipRule="evenodd"
      d="M65.6489 3.73739L65.0185 2.17993L64.3566 3.72428L55.3566 24.7243L56.6434 25.2758L60.3187 16.7H69.3853L72.8512 25.2627L74.1489 24.7374L65.6489 3.73739ZM68.8186 15.3L64.9816 5.82011L60.9187 15.3H68.8186ZM5 8.29999H5.7H34.9148V9.69999H6.4V72.3H18V54V53.3H18.7H60.7H61.4V54V72.3H73V45.5037H74.4V73V73.7H73.7H5.7H5V73V8.99999V8.29999ZM60 54.7V72.3H19.4V54.7H60ZM26.2 26.8H25.5V27.5V35.3H22.7V36.7H25.5V47V47.7H26.2H52.7H53.4V47V36.7H56.7V35.3H53.4V27.5V26.8H52.7H46.4V19H45V26.8H34.4V19H33L33 26.8H26.2ZM26.9 46.3V28.2H52V46.3H26.9ZM39.7 9.69999H45.7V8.29999L39.7 8.29999V9.69999ZM50.7 9.69999H48.7V8.29999H50.7V9.69999ZM73 40V35H74.4V40H73ZM73 28V30H74.4V28H73ZM33 36V34H34.4V36L33 36ZM45 34V36L46.4 36V34H45Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
