import { Skeleton, StackOwnProps, SxProps } from '@mui/material';
import { AreaChart, Formatter, LineChart } from 'components/charts';
import { RaasMetricsData, RaasMetricsResult } from 'hooks';
import { useDisclosure } from 'hooks/ui';
import { useMenuDisclosure } from 'hooks/ui/useMenuDisclosure';
import { ExpandMetricDrawer } from 'pages/DeploymentDetails/ExpandMetricDrawer';
import { MetricContainer } from 'pages/DeploymentDetails/MetricContainer';
import { MetricContainerMoreMenu } from 'pages/DeploymentDetails/MetricContainerMoreMenu';
import { YAxisProps } from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';

export interface IMetric {
  id?: string;
  title: string;
  description?: string;
  data?: RaasMetricsResult<RaasMetricsData>;
  loading?: boolean;
  tooltipKeyFormatter?: Formatter;
  tooltipValueFormatter?: Formatter;
  yAxisProps?: YAxisProps;
  chartType?: 'area' | 'line';
  curveType?: CurveType;
  legendDirection?: StackOwnProps['direction'];
  legendSpacing?: StackOwnProps['spacing'];
  hideTooltipKeys?: boolean;
  hideTooltipKeysSvg?: boolean;
  hideLegend?: boolean;
  responsiveContainerSx?: SxProps;
}

export const Metric = ({
  chartType,
  curveType,
  data: result,
  description,
  hideLegend,
  hideTooltipKeys,
  hideTooltipKeysSvg,
  id,
  legendDirection,
  legendSpacing,
  loading: isPending,
  responsiveContainerSx,
  title,
  tooltipKeyFormatter,
  tooltipValueFormatter,
  yAxisProps,
}: IMetric) => {
  const {
    onClose: onCloseExpandedDialog,
    onOpen: onOpenExpandedDialog,
    open: openedExpandedDialog,
  } = useDisclosure();
  const {
    anchorEl: anchorElMoreMenu,
    onClose: onCloseMoreMenu,
    onOpen: onOpenMoreMenu,
    open: openedMoreMenu,
  } = useMenuDisclosure();

  return (
    <>
      <MetricContainer
        description={description}
        onClickMoreButton={onOpenMoreMenu}
        renderMenu={
          <MetricContainerMoreMenu
            anchorEl={anchorElMoreMenu}
            onClickExpand={() => {
              onOpenExpandedDialog();
              onCloseMoreMenu();
            }}
            onClose={onCloseMoreMenu}
            open={openedMoreMenu}
          />
        }
        responsiveContainerSx={{ px: isPending ? '40px' : 0, ...responsiveContainerSx }}
        title={title}
      >
        {isPending ? (
          <Skeleton height="100%" sx={{ width: '50%' }} variant="rectangular" />
        ) : chartType === 'line' ? (
          <LineChart
            curveType={curveType}
            data={result?.data}
            dataKeys={result?.dataKeys}
            hideLegend={hideLegend ?? result?.dataKeys.length === 1}
            hideTooltipKeys={hideTooltipKeys ?? result?.dataKeys.length === 1}
            hideTooltipKeysSvg={hideTooltipKeysSvg ?? result?.dataKeys.length === 1}
            id={id}
            legendDirection={legendDirection}
            legendSpacing={legendSpacing}
            tooltipValueFormatter={tooltipValueFormatter}
            yAxisProps={yAxisProps}
          />
        ) : (
          <AreaChart
            curveType={curveType}
            data={result?.data}
            dataKeys={result?.dataKeys}
            hideLegend={hideLegend ?? result?.dataKeys.length === 1}
            hideTooltipKeys={hideTooltipKeys ?? result?.dataKeys.length === 1}
            id={id}
            tooltipKeyFormatter={tooltipKeyFormatter}
            tooltipValueFormatter={tooltipValueFormatter}
            yAxisProps={yAxisProps}
          />
        )}
      </MetricContainer>

      <ExpandMetricDrawer
        chartId={`${id}-expanded`}
        chartType={chartType}
        curveType={curveType}
        data={result}
        description={description}
        hideLegend={hideLegend}
        hideTooltipKeys={hideTooltipKeys}
        hideTooltipKeysSvg={hideTooltipKeysSvg}
        legendDirection={legendDirection}
        legendSpacing={legendSpacing}
        loading={isPending}
        onClose={onCloseExpandedDialog}
        open={openedExpandedDialog}
        title={title}
        tooltipValueFormatter={tooltipValueFormatter}
        yAxisProps={yAxisProps}
      />
    </>
  );
};
