import { IconButton, Stack, TooltipProps, Typography, TypographyProps } from '@mui/material';
import { IconCopy } from 'components/icons/IconCopy';
import { ConditionalTooltip } from 'components/Tooltip/ConditionalTooltip';
import { useCopyToClipboard } from 'hooks/ui';
import { FC } from 'react';
import { truncate as textTruncate } from 'utils/strings';

export interface IStackRowFieldValue {
  field: string;
  value: string;
  copyable?: boolean;
  truncate?: boolean;
  showTooltip?: boolean;
  href?: string;
  textTruncateFrontChars?: number;
  textTruncateBackChars?: number;
  valueTypographyProps?: TypographyProps;
  tooltipProps?: TooltipProps;
}

export const StackRowFieldValue = ({
  copyable,
  field,
  href,
  showTooltip,
  textTruncateBackChars,
  textTruncateFrontChars,
  tooltipProps,
  truncate,
  value,
  valueTypographyProps,
}: IStackRowFieldValue) => {
  const [, copy] = useCopyToClipboard();

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography variant="bodySmall" width="100%">
        {field}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="start"
        spacing="8px"
        sx={{
          width: '100%',
        }}
        textAlign="start"
      >
        <ConditionalTooltip enabled={showTooltip} title={value} {...tooltipProps}>
          <TypographyValue
            href={href}
            textTruncateBackChars={textTruncateBackChars}
            textTruncateFrontChars={textTruncateFrontChars}
            truncate={truncate}
            value={value}
            {...valueTypographyProps}
          />
        </ConditionalTooltip>

        {copyable && (
          <IconButton onClick={() => copy(value)} size="small">
            <IconCopy
              sx={theme => ({
                height: '20px',
                width: '20px',
                color: theme.colors.functional.container.primary,
              })}
            />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

const TypographyValue: FC<
  Pick<
    IStackRowFieldValue,
    'href' | 'truncate' | 'textTruncateBackChars' | 'textTruncateFrontChars' | 'value'
  > &
    TypographyProps
> = ({ href, textTruncateBackChars, textTruncateFrontChars, truncate, value, ...props }) => (
  <Typography
    {...(href && {
      component: 'a',
      target: '_blank',
      rel: 'noreferrer',
      href,
      sx: {
        textDecoration: 'underline',
        color: theme => theme.colors.functional.text.link,
      },
    })}
    {...props}
    variant="bodySmall"
  >
    {truncate ? textTruncate(value, '....', textTruncateFrontChars, textTruncateBackChars) : value}
  </Typography>
);
