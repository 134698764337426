import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { IconClose } from 'components/icons/IconClose';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useListApps, useTerminateApp } from 'hooks/aaas';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { AAA } from 'types/protoc-gen/aaas-bffavs';

export interface IDeleteAppDialog extends DialogProps {
  app: AAA | undefined;
}

export const DeleteAppDialog: React.FC<IDeleteAppDialog> = ({ app, onClose, ...props }) => {
  const navigate = useNavigate();
  const { addAlert } = useAlerts();
  const { refetch: refetchListApps } = useListApps();
  const { isPending: terminateAppPending, mutateAsync: terminateApp } = useTerminateApp({
    onSuccess: () => {
      refetchListApps();
      addAlert({ severity: ALERT_SEVERITY.SUCCESS, title: 'Server deleted' });
      onClose?.({}, 'backdropClick');
      navigate(PATHS.AAAS_DEPLOYMENTS, { replace: true });
    },
  });

  const handleClose = () => onClose?.({}, 'backdropClick');

  return (
    <Dialog onClose={onClose} {...props}>
      <Stack spacing="40px" sx={{ p: '40px', maxWidth: '504px' }}>
        <Stack alignItems="start" direction="row" justifyContent="space-between" spacing="40px">
          <Typography variant="h5">
            Are you sure you want to permanently delete this app?
          </Typography>

          <IconButton onClick={handleClose} size="small">
            <IconClose />
          </IconButton>
        </Stack>

        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing="24px"
          width="100%"
        >
          <Button disableElevation fullWidth onClick={handleClose} variant="contained">
            <Typography variant="bodySmall">Cancel</Typography>
          </Button>
          <LoadingButton
            disableElevation
            fullWidth
            loading={terminateAppPending}
            onClick={() => app?.id && terminateApp({ id: app.id })}
            variant="text"
          >
            <Typography variant="bodySmall">Yes</Typography>
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
