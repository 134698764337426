import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconDocument = ({ fill, sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M15.1 17.2702H4.9V2.07017H10.8V6.17017V6.87017H11.5H15.1V17.2702ZM14.4365 5.47017L12.2 2.98517V5.47017H14.4365ZM4.9 0.670166H12L16.5 5.67017V17.2702V18.6702H15.1H4.9H3.5V17.2702V2.07017V0.670166H4.9ZM13.5 10.6702H6.5V9.27017H13.5V10.6702ZM6.5 14.6702H13.5V13.2702H6.5V14.6702Z"
      fill={fill ?? '#202723'}
      fillRule="evenodd"
    />
  </SvgIcon>
);
