import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconCircleHealthStatus = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" {...props} sx={{ height: '12px', width: '12px', ...sx }}>
    <path
      clipRule="evenodd"
      d="M10.56 6C10.56 8.51842 8.51842 10.56 6 10.56C3.48158 10.56 1.44 8.51842 1.44 6C1.44 3.48158 3.48158 1.44 6 1.44C8.51842 1.44 10.56 3.48158 10.56 6ZM6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM8.5 6C8.5 7.38071 7.38071 8.5 6 8.5C4.61929 8.5 3.5 7.38071 3.5 6C3.5 4.61929 4.61929 3.5 6 3.5C7.38071 3.5 8.5 4.61929 8.5 6Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
