import { Skeleton, TableCell, TableRow, TableRowProps } from '@mui/material';

export interface ITableRowSkeleton extends Omit<TableRowProps, 'children'> {
  cellCount?: number;
}

export const TableRowSkeleton = ({ cellCount = 5, ...props }: ITableRowSkeleton) => (
  <TableRow {...props}>
    {Array.from({ length: cellCount }).map((_, index) => (
      <TableCell key={index.toString()}>
        <Skeleton />
      </TableCell>
    ))}
  </TableRow>
);
