import { alpha } from '@mui/material';

import { GetComponentStyleFunc } from './types';

export const muiOutlinedInput: GetComponentStyleFunc<'MuiOutlinedInput'> = theme => {
  return {
    styleOverrides: {
      root: {
        input: {
          color: theme.colors.functional.text.primary,
          fontSize: '15px',
          fontWeight: 400,
          padding: '24px',
        },
        '& > .MuiOutlinedInput-notchedOutline': {
          borderRadius: 0,
          border: '1px solid rgba(32, 39, 35, 0.08)',
        },
        '&.Mui-disabled': {
          background: theme.colors.functional.container.disabled,
        },
        '&.Mui-disabled > .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: '1px solid rgba(32, 39, 35, 0.3)',
          },
        '&:hover > .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: '1px solid rgba(32, 39, 35, 0.3)',
          },
        '&.MuiInput-underline::before': {
          border: 'none',
        },
        '&.MuiInput-underline:hover::before': {
          border: 'none',
        },
      },
    },
  };
};

export const muiInput: GetComponentStyleFunc<'MuiInputBase'> = theme => {
  return {
    styleOverrides: {
      root: {
        input: {
          color: alpha(theme.colors.functional.text.primary, 0.7),
          padding: '24px',
        },
        '&.MuiInput-underline::before': {
          border: 'none',
        },
        '&.MuiInput-underline:hover': {
          border: 'none',
        },
        '&&.Mui-focused::after': {
          display: 'none',
          border: 'none',
        },
        '&.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
          display: 'none',
          border: 'none',
        },
      },
    },
  };
};
