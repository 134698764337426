// [Days, hours, minutes, seconds] format
export const msToDaysHrsMinsSeconds = (ms: number) => {
  const diffInSeconds = Math.floor(ms / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  const seconds = diffInSeconds % 60;
  const minutes = diffInMinutes % 60;
  const hours = diffInHours % 24;

  if (diffInDays < 0 || hours < 0 || minutes < 0 || seconds < 0) {
    return [0, 0, 0, 0];
  }

  return [diffInDays, hours, minutes, seconds];
};

export const monthDateFormat = (value: string | number) => {
  const date = new Date(value);

  return `${date.getMonth() + 1}/${date.getDate()}`;
};
