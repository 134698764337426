import { BoxProps, IconButton, Stack, Typography, TypographyProps } from '@mui/material';
import { IconMore } from 'components/icons/IconMore';
import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { ResponsiveContainer } from 'recharts';

export interface IMetricContainer {
  title: string;
  children: ReactElement;
  description?: string;
  responsiveContainerSx?: BoxProps['sx'];
  descriptionSx?: TypographyProps['sx'];
  /**
   * @deprecated Define `onClickMoreButton` to render the more icon button.
   */
  renderMoreButtonHeaderEnd?: boolean;
  onClickMoreButton?: MouseEventHandler<HTMLButtonElement> | undefined;
  renderMenu?: ReactNode;
}

export const MetricContainer = ({
  children,
  description,
  descriptionSx,
  onClickMoreButton,
  renderMenu,
  responsiveContainerSx,
  title,
}: IMetricContainer) => {
  return (
    <Stack sx={{ height: '100%', width: '100%', py: '24px' }}>
      <Stack
        alignItems="start"
        direction="row"
        justifyContent="space-between"
        sx={{
          px: '40px',
          width: '100%',
        }}
      >
        <Typography variant="bodySmallM">{title}</Typography>

        {onClickMoreButton && (
          <IconButton onClick={onClickMoreButton} size="small">
            <IconMore sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        )}
      </Stack>
      {renderMenu}

      <Typography
        sx={{
          px: '40px',
          mb: '18px',
          width: '100%',
          color: 'rgba(32, 39, 35, 0.5)',
          ...descriptionSx,
        }}
        variant="caption"
      >
        {description}
      </Typography>

      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '275px',
          px: '12px',
          ...responsiveContainerSx,
        }}
      >
        <ResponsiveContainer>{children}</ResponsiveContainer>
      </Stack>
    </Stack>
  );
};
