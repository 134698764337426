import {
  BoxProps,
  Grid,
  GridProps,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

import { SubHeader } from './SubHeader';

export interface ISection extends Omit<GridProps, 'children'> {
  title?: string;
  children?: ReactNode;
  renderSubHeaderStartContent?: ReactNode;
  renderSubHeaderEndContent?: ReactNode;
  renderGridContent?: ReactNode;
  renderContent?: ReactNode;
  renderTitleSuffix?: ReactNode;
  sectionContentSx?: GridProps['sx'];
  subHeaderSx?: BoxProps['sx'];
  titleTypographySx?: TypographyProps['sx'];
  titleTypographyVariant?: TypographyProps['variant'];
}

export const Section = ({
  children,
  renderContent,
  renderGridContent,
  renderSubHeaderEndContent,
  renderSubHeaderStartContent,
  renderTitleSuffix,
  sectionContentSx,
  subHeaderSx,
  sx,
  title,
  titleTypographySx,
  titleTypographyVariant,
  ...props
}: ISection) => {
  const theme = useTheme();

  return (
    <Grid item sx={{ background: '#fff', width: '100%', ...sx }} {...props}>
      <SubHeader color={theme.colors.gradients.metal} sx={{ width: '100%', ...subHeaderSx }}>
        <Stack alignItems="center" direction="row" display="flex" justifyContent="space-between">
          {renderSubHeaderStartContent || (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="start"
              sx={{ width: '100%' }}
            >
              <Typography sx={titleTypographySx} variant={titleTypographyVariant ?? 'bodySmallC'}>
                {title}
              </Typography>
              {renderTitleSuffix}
            </Stack>
          )}
          {renderSubHeaderEndContent}
        </Stack>
      </SubHeader>
      {children || renderContent || (
        <Grid
          container
          sx={{
            px: '40px',
            pt: '24px',
            background: '#fff',
            ...sectionContentSx,
          }}
        >
          {renderGridContent}
        </Grid>
      )}
    </Grid>
  );
};
