import { Grid, Radio, Stack, Typography, useTheme } from '@mui/material';
import { aaaType } from 'labels/aaas';
import { aAATypeFromJSON, APP } from 'types/protoc-gen/aaas-bffavs';

export interface ITemplateCard {
  template: APP;
  selected?: boolean;
  onClick: () => void;
}

export const TemplateCard: React.FC<ITemplateCard> = ({ onClick, selected, template }) => {
  const theme = useTheme();

  return (
    <Grid
      height="180px"
      onClick={onClick}
      sx={{
        background: theme.colors.gradients.sheet,
        cursor: 'pointer',
        border: `1px solid ${selected ? '#5C8CE1' : 'rgba(32, 39, 35, 0.08)'}`,
        p: '24px',
      }}
      width="100%"
    >
      <Grid alignItems="start" display="flex" justifyContent="space-between" mb="24px">
        <Stack alignItems="center" direction="row" spacing="16px">
          <Typography>{aaaType[aAATypeFromJSON(template.type)]}</Typography>
        </Stack>
        <Radio
          checked={selected}
          sx={{
            color: theme.palette.text.primary,
            '&.Mui-checked': {
              color: theme.colors.functional.text.link,
            },
          }}
        />
      </Grid>
      <Grid>
        <Typography
          sx={{
            mr: '24px',
            textAlign: 'justify',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'text',
          }}
          variant="caption"
        >
          {template.description}
        </Typography>
      </Grid>
    </Grid>
  );
};
