import { AreaChart } from 'components/charts';
import { MetricContainer } from 'pages/DeploymentDetails/MetricContainer';

export const DepositVolumeEthGrowthMetric = () => {
  return (
    <MetricContainer title={'Deposit volume (ETH) growth'}>
      <AreaChart />
    </MetricContainer>
  );
};
