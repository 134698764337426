import { Box, BoxProps, Collapse, Grid, Stack, Typography } from '@mui/material';
import { useDisclosure } from 'hooks/ui';
import { DADetailsTable } from 'pages/NewDeployment/form-steps/DADetailsTable';
import { ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import SelectableCard from '../../../components/Card/SelectableCard';
import IconExpanded from '../../../components/icons/IconExpanded';
import { Option } from '../../../components/Option';
import { WithInfo } from '../../../components/WithInfo';
import { DAType } from '../../../types/protoc-gen/rollup';
import { FormValues } from '..';

interface DaTypeOption {
  icon: ReactNode;
  title: ReactNode;
  content?: ReactNode;
  value: DAType;
  disabled?: boolean;
}

const daTypeOptions: DaTypeOption[] = [
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/arbitrum.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">AnyTrust</Typography>,
    value: DAType.TYPE_ANYTRUST,
  },
  {
    icon: (
      <Box component="img" src={`/imgs/eigen.svg`} sx={{ height: '24px', width: '24px', mr: 1 }} />
    ),
    title: <Typography variant="bodySmall">EigenDA</Typography>,
    value: DAType.TYPE_EIGENDA,
    disabled: true,
  },
  {
    icon: (
      <Box
        component="img"
        src={`/imgs/celestia.svg`}
        sx={{ height: '24px', width: '24px', mr: 1 }}
      />
    ),
    title: <Typography variant="bodySmall">Celestia</Typography>,
    value: DAType.TYPE_CELESTIA,
    disabled: true,
  },
  {
    icon: (
      <Box component="img" src={`/imgs/avail.svg`} sx={{ height: '24px', width: '24px', mr: 1 }} />
    ),
    title: <Typography variant="bodySmall">Avail</Typography>,
    value: DAType.TYPE_AVAIL,
    disabled: true,
  },
];

export default function SelectDA(props: BoxProps) {
  const form = useFormContext<FormValues>();
  const daType = useWatch<FormValues, 'daType'>({ control: form.control, name: 'daType' });
  const { onToggle: onToggleDetailsAccordion, open: openedDetailsAccordion } = useDisclosure();

  return (
    <Option
      endAdornment={
        <Typography
          component={Stack}
          flexDirection="row"
          onClick={onToggleDetailsAccordion}
          sx={{ cursor: 'pointer', userSelect: 'none' }}
        >
          Details
          <IconExpanded
            sx={{ ml: 1, transform: openedDetailsAccordion ? 'unset' : 'scaleY(-1)' }}
          />
        </Typography>
      }
      optionTitle={
        <WithInfo
          info="Data availability refers to guarantees that the data needed to verify a block in the blockchain is actually available to all network participants"
          text={<Typography variant="bodySmallC">CHOOSE A DATA AVAILABILITY SOLUTION</Typography>}
        />
      }
      {...props}
    >
      <Stack spacing="16px" width="100%">
        <Grid container {...form.register('daType')}>
          {daTypeOptions?.map(da => (
            <Grid item key={da.value} xs={12 / daTypeOptions?.length}>
              <SelectableCard
                disabled={da.disabled}
                icon={da.icon}
                isSelected={da.value === daType}
                onClick={() => {
                  form.setValue('daType', da.value, { shouldTouch: true, shouldDirty: true });
                }}
                sx={{
                  height: '100%',
                }}
                title={da.title}
              />
            </Grid>
          ))}
        </Grid>
        <Collapse in={openedDetailsAccordion} mountOnEnter>
          <DADetailsTable />
        </Collapse>
      </Stack>
    </Option>
  );
}
