import { ActivateAVSResponse } from 'types/protoc-gen/avs';
import { Hash } from 'viem';
import { create } from 'zustand';

interface State {
  activeStep: number;
  initTxHash: Hash | undefined;
  finalizeTxHash: Hash | undefined;
  createECDSATxHash: Hash | undefined;
  activateAvsRes: ActivateAVSResponse | undefined;
}
interface Actions {
  setActiveStep: (step: number) => void;
  setInitTxHash: (txHash: Hash) => void;
  setFinalizeTxHash: (txHash: Hash) => void;
  setCreateECDSATxHash: (txHash: Hash) => void;
  setActivateAvsRes: (res: ActivateAVSResponse) => void;
  reset: () => void;
}

const initState: State = {
  activeStep: 0,
  initTxHash: undefined,
  finalizeTxHash: undefined,
  createECDSATxHash: undefined,
  activateAvsRes: undefined,
};

export const useNewAvsStore = create<State & Actions>(set => ({
  ...initState,
  setActiveStep: step => set(() => ({ activeStep: step })),
  setInitTxHash: txHash => set(() => ({ initTxHash: txHash })),
  setFinalizeTxHash: txHash => set(() => ({ finalizeTxHash: txHash })),
  setCreateECDSATxHash: txHash => set(() => ({ createECDSATxHash: txHash })),
  setActivateAvsRes: res => set(() => ({ activateAvsRes: res })),
  reset: () => set(initState),
}));
