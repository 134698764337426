import { LoadingButton } from '@mui/lab';
import { Dialog, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { IconClose } from 'components/icons/IconClose';
import { PasswordTextField } from 'components/TextField/PasswordTextField';
import { useVerifyPromoCode } from 'pages/AaasDeployments/useVerifyPromoCode';
import { useState } from 'react';

export interface IVerifyPromoCodeDialog extends DialogProps {
  onPromoCodeVerified?: (promoCode: string) => void;
}

export const VerifyPromoCodeDialog: React.FC<IVerifyPromoCodeDialog> = ({
  onClose,
  onPromoCodeVerified,
  ...props
}) => {
  const [promoCode, setPromoCode] = useState<string>();
  const { isPending: verifyPromoCodePending, mutate: verifyPromoCode } = useVerifyPromoCode({
    onSuccess: valid => {
      if (valid && promoCode) onPromoCodeVerified?.(promoCode);

      setPromoCode(undefined);
      onClose?.({}, 'backdropClick');
    },
  });

  return (
    <Dialog {...props}>
      <Stack
        spacing="40px"
        sx={{
          width: '504px',
          p: '40px',
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h5">Verify promo code</Typography>

          <IconButton onClick={() => onClose?.({}, 'backdropClick')} size="small">
            <IconClose />
          </IconButton>
        </Stack>

        <PasswordTextField onChange={e => setPromoCode(e.target.value)} placeholder="PROMO123" />

        <LoadingButton
          disableElevation
          disabled={!promoCode}
          loading={verifyPromoCodePending}
          onClick={() => verifyPromoCode(promoCode)}
          variant="contained"
        >
          <Typography variant="body1">Verify Promo Code</Typography>
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};
