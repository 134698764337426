import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconRefresh = ({ ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M16.601 10H18.001C18.001 5.58172 14.4193 2 10.001 2C7.44245 2 5.16441 3.20111 3.7 5.07023V2H2.3V7V7.7H3H7.5V6.3H4.53489C5.72181 4.54994 7.72713 3.4 10.001 3.4C13.6461 3.4 16.601 6.35492 16.601 10ZM3.4 10H2C2 14.4183 5.58172 18 10 18C12.558 18 14.8355 16.7995 16.3 14.9311V18H17.7V13V12.3H17H12.5V13.7H15.4661C14.2792 15.4501 12.2739 16.6 10 16.6C6.35492 16.6 3.4 13.6451 3.4 10Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
