import axios from 'axios';
import { useRaasDeployment } from 'hooks/raas/useRaasDeployment';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export interface IUseBlockscoutAxios {
  v2?: boolean;
}

export const useBlockscoutAxios = (options?: IUseBlockscoutAxios) => {
  const { v2 = false } = options ?? {};
  const { rollupId } = useParams();
  const { data: rollup } = useRaasDeployment({ rollupId });

  return useMemo(
    () => ({
      rollup,
      l1: axios.create({ baseURL: `${rollup?.l1Blockscout}/api/${v2 ? 'v2' : 'v1'}` }),
      l2: axios.create({ baseURL: `${rollup?.l2Blockscout}/api/${v2 ? 'v2' : 'v1'}` }),
    }),
    [v2, rollup],
  );
};
