import { SvgIcon, SvgIconProps } from '@mui/material';

const IconEigenDa = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 24 24" {...props}>
    <rect fill="#1A0C6D" height="24" width="24" />
    <path
      clipRule="evenodd"
      d="M5.22363 3.52942H8.61187V10.3059H5.22363V3.52942ZM17.0825 10.3059V13.6941H18.7766V17.0824H15.3883V20.4706H13.6942V17.0824H8.61187V10.3059H17.0825ZM12.0001 6.91765H13.6942V10.3059H12.0001V6.91765ZM10.3738 3.52942H12.0679V5.22354H10.3738V3.52942Z"
      fill="#8FFF00"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default IconEigenDa;
