import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconAvs = ({ ...props }: SvgIconProps) => (
  <SvgIcon height="80" viewBox="0 0 80 80" width="80" {...props}>
    <path
      clipRule="evenodd"
      d="M66.9357 10L67.4306 10.495L79.5054 22.5698L80.0004 23.0648L79.5054 23.5597L65.1385 37.9254L76.7196 49.5052L77.2147 50.0002L77.2114 50.0034L79.5084 52.3004L80.0034 52.7954L79.5084 53.2903L67.4336 65.3652L66.9386 65.8601L66.4436 65.3652L52.0712 50.9927L40.4952 62.57L40.0003 63.065L39.5053 62.57L27.9241 50.9901L13.5598 65.3558L13.0648 65.8508L12.5698 65.3558L0.494975 53.281L0 52.786L0.494975 52.291L14.86 37.926L3.28074 26.3455L2.78582 25.8505L2.78601 25.8503L0.504724 23.569L0.00974911 23.0741L0.504724 22.5791L12.5795 10.5043L13.0745 10.0093L13.5695 10.5043L27.9256 24.8604L39.5052 13.2807L40.0002 12.7857L40.4952 13.2808L52.0738 24.8606L66.4407 10.4949L66.9357 10ZM14.8608 13.7755L13.0745 11.9892L1.98965 23.0741L3.77596 24.8604L14.8608 13.7755ZM4.76591 25.8503L15.8505 14.7657L27.4297 26.3462L27.9247 26.8412L27.9256 26.8403L29.7276 28.6423L29.7242 28.6457L30.2192 29.1407L48.3056 47.2271L37.2242 58.3086L4.76591 25.8503ZM15.8508 38.9151L1.9799 52.786L13.0648 63.8708L26.935 49.9993L15.8508 38.9151ZM38.2138 59.2989L40.0002 61.0851L51.0813 50.0028L49.2956 48.2171L38.2138 59.2989ZM50.2855 47.2271L52.0711 49.0128L52.0744 49.0096L52.5694 49.5046L64.1499 61.0851L75.2315 50.0034L42.789 17.5608L31.7075 28.6423L49.794 46.7287L50.2889 47.2237L50.2855 47.2271ZM30.7176 27.6523L41.8021 16.5678L40.0002 14.7657L28.9156 25.8503L30.7176 27.6523ZM78.0205 23.0647L64.146 36.938L53.0611 25.8531L66.9356 11.9799L78.0205 23.0647ZM76.2215 50.9933L65.1366 62.0782L66.9386 63.8802L78.0235 52.7954L76.2215 50.9933Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
