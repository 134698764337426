import { CardContent, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import Card, { CardProps } from '.';

interface SelectableCardProps {
  icon?: ReactNode;
  title?: ReactNode;
  rightButtonGroup?: ReactNode;
  children?: ReactNode;
  subtitle?: ReactNode;
  isSelected?: boolean;
  disabled?: boolean;
  disabledText?: string;
}

const SelectableCard: FC<SelectableCardProps & Omit<CardProps, 'title'>> = ({
  children,
  disabled,
  disabledText,
  icon,
  isSelected,
  rightButtonGroup,
  subtitle,
  sx,
  title,
  ...rest
}) => {
  return (
    <Card
      sx={{
        borderRadius: 0,
        border: '1px solid rgba(32, 39, 35, 0.08)',
        boxShadow: 'none',
        ...(isSelected && { backgroundColor: '#fff', border: '1px solid #5C8CE1' }),
        ...(disabled
          ? {
              cursor: 'not-allowed',
              opacity: 0.7,
              pointerEvents: 'none',
              '&, &:hover': {
                filter: 'brightness(0.98)',
              },
            }
          : {
              cursor: 'pointer',
              '&:hover': {
                filter: 'brightness(0.98)',
              },
            }),
        ...sx,
      }}
      {...rest}
    >
      <Stack className="card-header" p={2}>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={4}>
          <Stack alignItems="center" direction="row">
            {icon}
            <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
              <Typography component="span" variant="bodySmallM">
                {title}
              </Typography>
              {disabled && (
                <Typography component="span" variant="captionC">
                  {disabledText ?? 'Coming Soon'}
                </Typography>
              )}
            </Stack>
          </Stack>
          {rightButtonGroup}
        </Stack>
        <Typography>{subtitle}</Typography>
      </Stack>
      {children && <CardContent sx={{ pt: 0, mt: 2 }}>{children}</CardContent>}
    </Card>
  );
};

export default SelectableCard;
