import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { Metadata } from 'types/avs';
import {
  ActivateAVSRequest,
  ActivateAVSResponse,
  ActivateOperatorRequest,
  ActivateOperatorResponse,
  GetAggregatorResponse,
  GetAggregatorStatusResponse,
  LaunchOperatorRequest,
  ListAllAVSDeploymentAdminResponse,
  ListAllAVSDeploymentResponse,
  QueryOperatorResponse,
  TerminateOperatorRequest,
  TerminateOperatorResponse,
} from 'types/protoc-gen/avs';

import { axiosRaas } from '.';

export async function fetchAllAvs() {
  const res = await axiosRaas.get<ListAllAVSDeploymentResponse>('/avs');

  return res?.data?.avsDeployment;
}

export async function fetchAllAggregatorsStatus() {
  const res = await axiosRaas.get<GetAggregatorStatusResponse>('/aggregators');

  return res?.data?.getAVSStatusByNameResponse;
}

export async function activateAvs(reqBody: ActivateAVSRequest) {
  const res = await axiosRaas.post<ActivateAVSResponse>(`/avs`, reqBody);

  return res?.data;
}

export async function activateOperator(reqBody: ActivateOperatorRequest) {
  const res = await axiosRaas.post<ActivateOperatorResponse>(`/operators`, reqBody);

  return res?.data;
}

export async function launchOperator(reqBody: LaunchOperatorRequest) {
  const res = await axiosRaas.post<ActivateOperatorResponse>(`/operator/nodes`, reqBody);

  return res?.data;
}

export async function terminateOperator(reqBody: TerminateOperatorRequest) {
  const res = await axiosRaas.delete<TerminateOperatorResponse>(
    `/operators/${reqBody?.deploymentId}`,
  );

  return res?.data;
}

export async function fetchOperatorStatus(context: QueryFunctionContext) {
  const deploymentId = context?.queryKey?.[1];
  const res = await axiosRaas.get<QueryOperatorResponse>(`/operators/${deploymentId}`);

  return res?.data;
}

export async function fetchAvsMetadata(context: QueryFunctionContext) {
  const svcManagerAddr = context?.queryKey?.[1];
  const res = await axios.get<Metadata>(
    `https://api-holesky.eigenexplorer.com/avs/${svcManagerAddr}`,
  );

  return res?.data;
}

export async function fetchGlobalPublicAvs(): Promise<ListAllAVSDeploymentAdminResponse> {
  const res = await axiosRaas.get<ListAllAVSDeploymentAdminResponse>('/admin/avs');

  return res?.data;
}

export async function fetchAggregator() {
  const res = await axiosRaas.get<GetAggregatorResponse>('/info');

  return res?.data;
}
