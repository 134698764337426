import { GridProps, Stack } from '@mui/material';

export interface IDeploymentDetailsLayout extends GridProps {}

export const DeploymentDetailsLayout = ({ children, sx, ...props }: IDeploymentDetailsLayout) => {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        overflowY: 'auto',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};
