import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconCheckedCircle({
  fill,
  sx,
  ...props
}: SvgIconProps & { fill?: string }) {
  return (
    <SvgIcon sx={{ width: '18px', height: '18px', ...sx }} viewBox="0 0 18 18" {...props}>
      <circle cx="9" cy="8.5" fill={fill ?? '#202723'} r="8" stroke={fill ?? '#202723'} />
      <path d="M4.59961 8.79999L7.65335 11.8537L13.7232 5.78384" stroke="white" />
    </SvgIcon>
  );
}
