import './font.css';

import { createTheme, responsiveFontSizes } from '@mui/material';
import { FONT_FAMILY } from 'constants/font';

import { colors } from './colors';
import { muiBreadcrumbs } from './MuiBreadcrumbs';
import { muiButton, muiButtonBase } from './MuiButton';
import {
  muiDialog,
  muiDialogActions,
  muiDialogContent,
  muiDialogContentText,
  muiDialogTitle,
} from './MuiDialog';
import { muiInput, muiOutlinedInput } from './MuiInput';
import { muiLink } from './MuiLink';
import { muiPopover } from './MuiPopover';
import { muiRadio } from './MuiRadio';
import { muiSlider } from './MuiSlider';
import { muiSvgIcon } from './MuiSvgIcon';
import { muiTab, muiTabs } from './MuiTab';
import { muiTableContainer } from './MuiTableContainer';
import { muiTextField } from './MuiTextField';
import { muiTooltip } from './MuiTooltip';
import { muiTypography } from './MuiTypography';

const baseTheme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
  },
  colors,
  palette: {
    text: { primary: colors.functional.text.primary, secondary: colors.functional.text.lint },
    primary: { main: colors.schema.primary },
    secondary: { main: colors.schema.secondary },
    error: { main: colors.schema.error },
    warning: { main: colors.schema.error },
    info: { main: colors.schema.info },
    success: { main: colors.schema.success },
    action: {
      disabledBackground: colors.functional.subject.first,
      disabled: colors.functional.text.primary,
    },
  },
});

export const theme = responsiveFontSizes({
  ...baseTheme,
  components: {
    MuiTooltip: muiTooltip(baseTheme),
    MuiPopover: muiPopover(baseTheme),
    MuiBreadcrumbs: muiBreadcrumbs(baseTheme),
    MuiRadio: muiRadio(baseTheme),
    MuiButtonBase: muiButtonBase(baseTheme),
    MuiButton: muiButton(baseTheme),
    MuiTextField: muiTextField(baseTheme),
    MuiOutlinedInput: muiOutlinedInput(baseTheme),
    MuiInput: muiInput(baseTheme),
    MuiLink: muiLink(baseTheme),
    MuiTypography: muiTypography(baseTheme),
    MuiSlider: muiSlider(baseTheme),
    MuiSvgIcon: muiSvgIcon(baseTheme),
    MuiDialogTitle: muiDialogTitle(baseTheme),
    MuiDialogActions: muiDialogActions(baseTheme),
    MuiDialogContent: muiDialogContent(baseTheme),
    MuiDialogContentText: muiDialogContentText(baseTheme),
    MuiDialog: muiDialog(baseTheme),
    MuiTab: muiTab(baseTheme),
    MuiTabs: muiTabs(baseTheme),
    MuiTableContainer: muiTableContainer(baseTheme),
    MuiCssBaseline: {
      styleOverrides: `
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
        
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Works for Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        body {
          min-height: 100vh;
        }
        #root > div[data-rk] {
          position: relative;
        }
      `,
    },
  },
});
