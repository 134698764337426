import { BASE_FEE_ADDR } from 'constants/raas';
import { useBlockscoutBalanceMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { addressFormatter, ethFormatter } from 'utils/recharts';

export const BaseFeeBalanceMetric = () => {
  const { data: result, isPending } = useBlockscoutBalanceMetrics({
    account: BASE_FEE_ADDR,
  });

  return (
    <Metric
      data={result}
      description={'Base fee balance per period'}
      id={'base-fee-balance'}
      loading={isPending}
      title={'Base fee balance'}
      tooltipKeyFormatter={addressFormatter}
      tooltipValueFormatter={ethFormatter}
      yAxisProps={{
        padding: { bottom: 40 },
        tickFormatter: ethFormatter,
        tickMargin: -20,
      }}
    />
  );
};
