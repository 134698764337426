export const SCROLL_MD = `
## Brief Introduction
---

1.  **What Scroll versions do you support?**

    We recommend using the latest release at <https://github.com/scroll-tech/go-ethereum/releases>. The required version for Scroll Mainnet is scroll-v5.5.0 or higher, and for Scroll Sepolia it is scroll-v5.4.2 or higher.

    If you'd like to keep up with new node releases, go to <https://github.com/scroll-tech/go-ethereum>, click on Watch, Custom, and make sure that Releases is selected.

2.  **What data availability options do you support?**

    We support Ethereum blobs.


## Parameter Settings
---

1.  **What parameters can we configure for our Scroll chain?**

    You can provide us with your preferred:

    -   Chain ID (if no preference, we'll generate a random ID)

    -   Network name

### ChainId

ChainId is the most important value for an Ethereum-like chain. So you need to check an unique chain id before we launch the rollup.

Please check you expected chain id in this repo, and create pr to this repo for your chainid：

<https://github.com/ethereum-lists/chains>

<https://github.com/DefiLlama/chainlist>

<https://github.com/wevm/viem/tree/main/src/chains/definitions>

_Risk alert: this repo review pr in a very low speed, please must ensure your chain id is unique with other Ethereum-like chain._

While for the endpoint and explorer url, you can set the one under your domain, in future after we launch your rollup, we can set our service under this domain, or you can replace later in this repo.

### Gas fees

1.  **How are gas fees calculated on Scroll zk-Rollup?**

    On Scroll zk-Rollup, gas fees are calculated based on the gas used to execute transactions on L2 and the L1 data costs. Gas fees are influenced by both L2 execution costs and the cost of posting zk-proofs and associated data to Ethereum L1.

    Scroll gas fees are composed of two main components:

    -   L2 Execution Gas: The cost of executing transactions on Scroll's Layer 2 network, measured in L2 gas units.

    -   L1 Data Fees: The cost of posting compressed transaction data and zk-proofs to Ethereum Layer 1.

    For more information about the gas calculation, please refer to the [official doc](https://docs.scroll.io/en/developers/transaction-fees-on-scroll/).

2.  **How to adjust the gas scalar in Scroll?**

    Here's the [official doc](https://scrollzkp.notion.site/How-to-calculate-commitScalar-and-blobScalar-10a7792d22af80a88dece36290907988) to introduce the gas scalar calculation.

    Basically,
    \`\`\`js
    commitScalar = (1382.58 / tx_per_block + 71621.32 / tx_per_batch)  * fluctuation_multiplier * 1e9

    blobScalar = fluctuation_multiplier / compression_ratio / blob_util_ratio * 1e9 (compression_ratio = 2.887，blob_util_ratio : 87.1%)
    \`\`\`

3.  **What's the addresses of L2GasOracle and L1GasOracle and what do they used for?**

    [L1GasOracle](https://scrollscan.com/address/0x5300000000000000000000000000000000000002)

    _Role:_
    - Fetching L1 Gas Information: The L1GasOracle retrieves the latest gas price from Ethereum’s Layer 1 network. Specifically, it captures the base fee and other gas parameters necessary for submitting zk-Rollup data and proofs to L1.
    - Calculating L1 Data Costs: Scroll's zk-Rollup relies on posting transaction data and zk-proof validity information to L1. L1GasOracle helps calculate the cost of posting this data, which is essential for securing the transactions on Ethereum’s L1.
    
    _Use in Gas Fee Calculation:_
    - L1 Data Fee Calculation: The gas price provided by L1GasOracle (L1GasPrice) is used to calculate the gas fees for posting zk-Rollup transaction data to L1. This is a crucial component of the total transaction fee, as every batch of transactions on L2 eventually needs to be recorded on L1 for security.
    - Optimizing L1 Posting Costs: L1GasOracle dynamically retrieves the L1 gas price, allowing Scroll to post data when the gas prices on Ethereum are lower, thereby optimizing the costs associated with submitting data to L1.

    [L2GasOrable](https://etherscan.io/address/0x0d7E906BD9cAFa154b048cFa766Cc1E54E39AF9B)

    _Role:_
    - Determining L2 Gas Prices: The L2GasOracle is responsible for determining the gas prices for executing transactions on the Scroll Layer 2 network. It provides a dynamic gas price (L2GasPrice) that reflects the cost of computation and transaction execution on L2.
    - Adjusting Based on L1 Prices: L2GasOracle also takes into account the gas prices from L1 (via L1GasOracle) to ensure that the gas price on L2 is in sync with L1, adjusting to changes in the base fee of Ethereum’s L1 network.
    - Reflecting Network Demand: The gas price on L2 is dynamically adjusted based on the demand and congestion on the Scroll network. Higher congestion leads to higher L2 gas prices to ensure proper network functionality.
    
    _Use in Gas Fee Calculation:_
    - L2 Execution Cost: The gas price provided by L2GasOracle is used to calculate the cost of executing operations or smart contract calls on L2. When users submit transactions, they pay gas fees based on the L2GasPrice determined by L2GasOracle.
    - Maintaining Stability: By adjusting gas fees dynamically, L2GasOracle ensures that gas prices remain stable and reasonable, even during periods of high transaction demand.

### Gas token

1.  **Is custom gas token supported?**
    
    Yes. Scroll supports Custom gas tokens through a customised gas gateway. By deploying a mechanism similar to the WETH Gateway, users are allowed to use custom tokens to pay for gas fees. 

2.  **How is the WETH Gateway handled on L1 and L2 in Scroll when using custom gas fees?**

    In Scroll, when custom gas fees are in place, the traditional deployment of the WETH Gateway and ETH Gateway is modified. Here's how it's handled:

    -   ETH Gateway and WETH Gateway are not deployed: Under the custom gas fee structure, Scroll does not deploy the traditional ETH Gateway and WETH Gateway since they do not support custom token payments for gas fees.

    -   L2 WETH Gateway is replaced by GasToken Gateway: On Scroll’s L2, the original WETH Gateway is replaced with a GasToken Gateway (contract address: 0x53...04). This GasToken Gateway contract allows for the use of WETH or other custom gas tokens for paying gas fees. The contract automatically converts these tokens into ETH to cover both L1 and L2 fees.

    -   ETH/WETH transfer handling between L1 and L2: For moving ETH between L1 and L2, the L1 WrapperToken Gateway contract is used. This means that on L1, ETH is wrapped into WETH and transferred through the Gateway contract to L2.

## Launch Process
---

1.  **What is included in the standard setup?**

    | Label | Description |
    | - | - |
    | OP chain | 1 sequencer node (geth / node / batcher / proposer)
    | RPC / WS data | 1 or more load-balanced full nodes, meanwhile as bootnode |
    | Block explorer | Standard Blockscout v2 application |
    | Bridge | Scroll Bridge UI |

2. **Can we host our own full nodes?**

    Yes, here's the [guide](https://docs.scroll.io/en/developers/guides/running-a-scroll-node/) to run a Scroll L2geth node.

3. **Can we use our own domain for the chain URLs?**

    Yes, you can CNAME the RPC, websocket (if any) and explorer URLs to your own domain.

    _For these customizations, you will need to create CNAME records on your DNS provider. We will provide you the target for these records._

4. **What can we expect after the chain setup is completed?**

    You will receive a rollup info page containing all the L2 details and links, similar to [this](https://rollup-info.altlayer.io/public/op_testnet).

## Product Instruction
--- 

### Bridge 

1.  **What are our bridging options?**

    Scroll will provide the bridge interface like the sample(TODO).

2. **What are L1 standard gateway and L1 custom gateway and what are their usages in bridging?**

    In Scroll's bridging system, the L1 Standard Gateway and L1 Custom Gateway are key components used to facilitate the transfer of assets between L1 and L2. They serve different purposes depending on the type of assets being bridged and the specific requirements of the tokens. Here's an explanation of their roles:

    L1 Standard Gateway: 

    -   ETH and Standard ERC-20 Tokens: If you are working with commonly used ERC-20 tokens (like USDT, DAI, etc.) or ETH, the Standard Gateway is the go-to bridging method. It provides seamless transfer between L1 and L2, and the process is straightforward.

    L1 Custom Gateway: 

    -   Custom ERC-20 Tokens or Specialized Assets: If you're bridging a custom ERC-20 token or an asset with non-standard features (such as project-specific tokens or tokens with unique contract logic), you'll need to use the L1 Custom Gateway.
    
    -   New Tokens: Newly created ERC-20 tokens often require integration with the Custom Gateway before they can be used for bridging on the Scroll network.

3. **If I want to deploy my ERC20 token, what do I need to prepare?**

    Here's the [official guide](https://docs.scroll.io/en/developers/guides/bridge-erc20-through-the-custom-gateway/) to step by step bridging ERC20 through the custom gateway.

4. **What's the bridged standard for USDC?**

    For now, in the early stages of Scroll's mainnet, there was no official USDC contract from Circle. To bridge USDC, Scroll deployed the L1USDCGateway and L2USDCGateway using the L1 Custom Gateway. This allowed USDC assets to be transferred between L1and L2 on Scroll.

    When Circle officially deployed USDC on Scroll, the ownership of the L2 USDC contract was transferred to Circle via the L2USDCGateway contract.

5. **How to track a transaction from L1 to L2 on Scroll?**

    The Scroll contracts emit a SentMessage event that helps track the transaction across layers. 

    Also, you can use the function provided in the L1MessageQueue.sol contract to determine the corresponding L2 transaction hash after initiating an L1 transaction. Specifically, the L2 transaction hash can be determined by referencing the [following function](https://github.com/scroll-tech/scroll-contracts/blob/main/src/L1/rollup/L1MessageQueue.sol#L166C14-L166C36). This function will provide you with the necessary details to locate and verify the execution of the transaction on L2 after it has been initiated from L1.

### Block Explorer

1.  **Can we customise our block explorer UI?**

    Yes, we can customise settings such as the logos, icons, colour scheme, and text content. Please request our [Blockscout order form](https://docs.google.com/forms/d/e/1FAIpQLSe66onMMxoN5EYfVyo0Kcq3GFK7YWMEFbQ1uPfEgZQTP5PNHQ/viewform) to see all the customizations available.
    
    Pay attention to the image size we require in the form, e.g., rectangle logo size is 120*24.

2.  **Can I verify contracts on the block explorer?**

    Yes. Please follow this [sample](https://github.com/alt-research/contract-verify-sample) for verifying a deployed contract using hardhat or foundry.

3.  **Why in the welcome page shows: "98% Blocks Indexed - We're indexing this chain right now. Some of the counts may be inaccurate"**

    This info normally shows when this rollup just launched recently. It means the blockscout need to take some time to scan the block status then save to the blockscout background database.

    Usually after some time, it will disappear after the blockscout sync all data.

### Faucet

1.  **What are our faucet options?**

    You can choose to use our partners' faucet solutions, with three options available.

## Tech Details
---

1.  **What's the contract upgrade solution in Scroll?**

    _TBD._

## Custom Domain
---

1.  **If I want to use a custom domain but my domain is an APEX domain, what should I do?**

    Since we cannot use CNAME to point to the target, we can try Alias or DDNS.

    **a. Alia**
    
    Alias is a special type of A-type DNS record, not all DNS providers support it.

    -   In **Cloudflare**, it's called [CNAME Flattening](https://developers.cloudflare.com/dns/cname-flattening/)
    -   In **Namecheap**, it's also called [ANAME](https://www.namecheap.com/support/knowledgebase/article.aspx/10128/2237/how-to-create-an-alias-record/)
    -   In **Route53**, you can set a Route53 Alias A record on the APEX domain like the pic below. You can ask us for the correct load balancer url.

    ![](https://lh7-rt.googleusercontent.com/docsz/AD_4nXeTWf1LXi-BGi3HX4r139sTaVpMiflFXsNXMfgg1X6XWHTtZGNDd9tXrJug6R8S-IhKIcUEPRRjDNXeLQO-95or1oKC1TAAHDlwMIzZrdK_wJ3XUqR1D3Ra-ShcyDdYEyaiZfFwiH1GTfdjBbkitx2nXmtf?key=c49ioRFsTs1HNKlokS54Rw)

    For other DNS providers, we need to check if they support Alias.

    b. DDNS (Dynamic DNS) Service

    DDNS is not a record type, it is a service that can update the DNS record dynamically.

    We will provide a script to you, you need to save this as \`update-route53-record.sh\`, and add it as a cron job to update the Route53 record.

    \`\`\`
    # Add the cron job to update the Route53 record every 5 minutes */5 * * * * /path/to/update-route53-record.sh <hosted_zone_id> <route53_record_name> <domain>
    \`\`\`
`;
