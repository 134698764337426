import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconAltLayer = ({ ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M1.75467 13.2749C1.54953 13.4416 1.55072 13.7551 1.75711 13.9202L8.36743 19.2084C8.6388 19.4255 9.0408 19.2323 9.0408 18.8848V16.4315V13.1158L9.0408 8.22567C9.0408 7.87668 8.63584 7.68393 8.36499 7.904L1.75467 13.2749Z"
      fill="url(#paint0_linear_1605_4122)"
      fillRule="evenodd"
    />
    <path
      d="M2.48767 11.1893L1.4993 10.3986C1.29291 10.2335 1.29172 9.92001 1.49685 9.75334L8.36401 4.17378C8.63487 3.95371 9.03982 4.14645 9.03982 4.49544V6.08919C9.03982 6.214 8.98358 6.33216 8.88672 6.41086L3.00794 11.1874C2.85658 11.3104 2.63996 11.3112 2.48767 11.1893Z"
      fill="url(#paint1_linear_1605_4122)"
    />
    <path
      d="M1.15555 6.80794L2.2312 7.66846C2.38348 7.79029 2.6001 7.78947 2.75146 7.66649L8.88708 2.68129C8.98395 2.60259 9.04019 2.48443 9.04019 2.35963V0.715286C9.04019 0.367759 8.63819 0.17455 8.36682 0.391648L1.15555 6.16066C0.94815 6.32658 0.948151 6.64202 1.15555 6.80794Z"
      fill="url(#paint2_linear_1605_4122)"
    />
    <path
      d="M10.5767 19.0397L19.5231 10.0932C19.685 9.93131 19.685 9.66889 19.5231 9.50704L10.5767 0.560698C10.3156 0.299605 9.86914 0.484524 9.86914 0.853767V18.7466C9.86914 19.1159 10.3156 19.3008 10.5767 19.0397Z"
      fill="url(#paint3_linear_1605_4122)"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1605_4122"
        x1="1.60156"
        x2="12.2039"
        y1="7.81042"
        y2="7.22085"
      >
        <stop stopColor="#8F97EF" />
        <stop offset="1" stopColor="#6166DA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_1605_4122"
        x1="1.34375"
        x2="13.4518"
        y1="4.0802"
        y2="6.3628"
      >
        <stop stopColor="#8F97EF" />
        <stop offset="1" stopColor="#6166DA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_1605_4122"
        x1="1"
        x2="13.0668"
        y1="0.300049"
        y2="2.20779"
      >
        <stop stopColor="#8F97EF" />
        <stop offset="1" stopColor="#6166DA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_1605_4122"
        x1="8.6"
        x2="19.8085"
        y1="6.95006"
        y2="3.19828"
      >
        <stop stopColor="#8F97EF" />
        <stop offset="1" stopColor="#6166DA" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
