import { useBlockscoutMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';

export const ContractsGrowthMetric = () => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.ROLLUP_STATS_CONTRACTS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'contractsGrowth',
    l2: true,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Cumulative contract growth over time'}
      id={'contracts-growth'}
      loading={isPending}
      title={'Contracts growth'}
    />
  );
};
