import { Stack, Typography } from '@mui/material';
import { Section } from 'pages/DeploymentDetails/Section';
import { useEffect } from 'react';

export const ContactUsView = () => {
  useEffect(() => {
    const element = document.getElementById('contact-us-section');

    if (element) {
      const yOffset = -250; // Adjust this value as needed
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }

    const style = document.createElement('style');

    style.innerHTML = `
      #mava-webchat-launcher {
        animation: pulse 2s infinite, moveUpDown 1s infinite;
        position: relative;
      }

      @keyframes pulse {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(92, 140, 225, 0.8);
        }
        
        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }
        
        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
      }

      @keyframes moveUpDown {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <Section
        id="contact-us-section"
        renderContent={
          <Stack
            sx={theme => ({
              background: '#fff',
              px: '40px',
              py: '24px',
              borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
              minHeight: '304px',
            })}
          >
            <Typography variant="bodySmall">
              You can create a support ticket via the chatbot on the bottom right of the page.
              Alternatively, you can reach out to us at{' '}
              <a href="mailto:support@altresear.ch">support@altresear.ch</a>
            </Typography>
          </Stack>
        }
        title="Contact Us"
      />
    </>
  );
};
