import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconExternalLink = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 20 20" {...props}>
    <path
      d="M5.59961 14.3999L14.5996 5.3999M14.5996 5.3999H5.59961M14.5996 5.3999V14.3999"
      stroke="#202723"
      strokeWidth="1.4"
    />
  </SvgIcon>
);
