import { Box, BoxProps } from '@mui/material';

export const SubHeaderChip = ({ children, sx, ...props }: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        ml: '16px',
        background: '#fff',
        px: '6px',
        py: '1px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
