import { Button, Stack, Typography } from '@mui/material';
import { InlineFormTextField } from 'components/form-fields/InlineFormTextField';
import { IconEdit } from 'components/icons/IconEdit';
import { IconRefresh } from 'components/icons/IconRefresh';
import { useCallback, useState } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { CompactButton } from './CompactButton';
import { LabelChip } from './LabelChip';
import { CustomizationFormValues, resolver } from './validation';
import { ValidationChip } from './ValidationChip';

export interface IEditableInput {
  label: string;
  labelChipValue?: string;
  validConfiguration?: boolean;
  onClickEdit?: () => void;
  onClickRefresh?: () => void;
  disabledRefreshButton?: boolean;
  disabledEditButton?: boolean;
  externalLink?: string;
  showValidation?: boolean;
}

export const EditableInput = ({
  disabledEditButton,
  disabledRefreshButton,
  externalLink,
  label,
  labelChipValue,
  onClickEdit,
  onClickRefresh,
  showValidation,
  validConfiguration,
}: IEditableInput) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleClickEditButton = useCallback(() => {
    setEditMode(true);
    onClickEdit?.();
  }, [onClickEdit]);

  const form = useForm<CustomizationFormValues>({
    mode: 'all',
    resolver,
  });

  const onSubmit: SubmitHandler<CustomizationFormValues> = useCallback(data => {
    console.log(data);
    setEditMode(false);
  }, []);
  const onError: SubmitErrorHandler<CustomizationFormValues> = useCallback(formErrors => {
    console.error(formErrors);
  }, []);

  return !editMode ? (
    <Stack spacing="16px">
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack direction="row" spacing="16px">
          <Typography>{label}</Typography>
          {labelChipValue && <LabelChip>{labelChipValue}</LabelChip>}
        </Stack>

        <Stack direction="row" spacing="24px">
          <CompactButton
            disabled={disabledRefreshButton}
            onClick={onClickRefresh}
            renderIcon={
              <IconRefresh
                sx={{
                  height: '20px',
                  width: '20px',
                }}
              />
            }
          >
            Refresh
          </CompactButton>
          <CompactButton
            disabled={disabledEditButton}
            onClick={handleClickEditButton}
            renderIcon={
              <IconEdit
                sx={{
                  height: '20px',
                  width: '20px',
                }}
              />
            }
          >
            Edit
          </CompactButton>
        </Stack>
      </Stack>

      {showValidation && (
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack direction="row" spacing="40px">
            {validConfiguration ? (
              <>
                <ValidationChip valid>Valid Configuration</ValidationChip>
                <ValidationChip valid>Assigned</ValidationChip>
              </>
            ) : (
              <>
                <ValidationChip valid={false}>Invalid Configuration</ValidationChip>
              </>
            )}
          </Stack>

          {externalLink && (
            <Typography variant="bodySmall">
              Please refer to{' '}
              <a
                href={externalLink}
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
                target="_blank"
              >
                this document
              </a>{' '}
              for configuration.
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  ) : (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onError)}>
        <Stack alignItems="start" direction="row" justifyContent="space-between">
          <InlineFormTextField name="rpcEndpoint" />
          <Button
            size="small"
            sx={theme => ({
              color: theme.colors.functional.text.link,
              background: 'transparent',
            })}
            type="submit"
            variant="text"
          >
            <Typography variant="bodySmallC">Confirm</Typography>
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
