import { TokenSymbolIcon } from 'components/icons/TokenSymbolIcon';
import {
  DataAvailabilityLayer,
  renderDaTypeIcon,
  renderRollupSettlementLayerIcon,
  renderRollupTypeIcon,
  RollupType,
  SettlementLayer,
} from 'labels/raas';
import { RollupStatusChip } from 'pages/DeploymentDetails/RollupStatusChip';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionContentKeyValue } from 'pages/DeploymentDetails/SectionContentKeyValue';

import { IRollupInfoSection } from './types';

export const GeneralSection = ({ loading, rollup }: IRollupInfoSection) => {
  return (
    <Section
      renderGridContent={
        <>
          <SectionContentKeyValue label="Chain ID" loading={loading} value="139182" xs={4} />
          <SectionContentKeyValue
            label="Rollup Stack"
            loading={loading}
            renderIcon={renderRollupTypeIcon(rollup?.type ?? RollupType.UNRECOGNIZED)}
            value={rollup?.type}
            xs={4}
          />
          <SectionContentKeyValue
            label="Settlement Layer"
            loading={loading}
            renderIcon={renderRollupSettlementLayerIcon(
              rollup?.settlementLayer ?? SettlementLayer.UNRECOGNIZED,
            )}
            value={rollup?.settlementLayer}
            xs={4}
          />
          <SectionContentKeyValue
            label="Symbol"
            loading={loading}
            renderIcon={<TokenSymbolIcon symbol={rollup?.tokenMetadata?.symbol} />}
            value={rollup?.tokenMetadata?.symbol}
            xs={4}
          />
          <SectionContentKeyValue
            label="Data Availability"
            loading={loading}
            renderIcon={renderDaTypeIcon(
              rollup?.dataAvailabilityLayer ?? DataAvailabilityLayer.UNRECOGNIZED,
            )}
            value={rollup?.dataAvailabilityLayer}
            xs={4}
          />
        </>
      }
      renderSubHeaderEndContent={<RollupStatusChip loading={loading} rollup={rollup} />}
      title="General"
    />
  );
};
