import { Box, BoxProps } from '@mui/material';

export const StackItem = ({ children, sx, ...props }: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        background: '#fff',
        px: '40px',
        py: '24px',
        borderBottom: '1px solid rgba(32, 39, 35, 0.08)',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
