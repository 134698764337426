import { useRaasDeployment } from 'hooks';
import { MetricGridItem } from 'pages/DeploymentDetails/MetricGridItem';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionButtonTimeFilter } from 'pages/DeploymentDetails/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/DeploymentDetails/useTimeFilterStore';

import { AccountsGrowthMetric } from './AccountsGrowthMetric';
import { ActiveAccountsMetric } from './ActiveAccountsMetric';
import { NewAccountMetric } from './NewAccountMetric';

export const EoaAccountsSection = () => {
  const { isPending } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <AccountsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <ActiveAccountsMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <NewAccountMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter
          loading={isPending}
          variant={TimeFilterVariant.ROLLUP_STATS_EOA_ACCOUNTS_SECTION}
        />
      }
      sectionContentSx={{ p: '0px' }}
      title="EOA Accounts"
    />
  );
};
