import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosAuth } from 'api';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useCallback, useMemo, useState } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import { GrUserAdmin } from 'react-icons/gr';
import { IoIosMore } from 'react-icons/io';
import { IoArrowBack } from 'react-icons/io5';
import { px } from 'theme/margins';
import { ServerErrorResponse } from 'types/axios';
import { MUTATION_KEYS } from 'types/react-query';
import { UserRecord } from 'types/user';

import { InviteTeamMember } from './InviteTeamMember';

enum ROLES {
  ORG_ADMIN = 'ORG_ADMIN',
}

interface TeamSettingsMutationArgs {
  email: string;
}

export function TeamSettings() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showInviteScreen, setShowInviteScreen] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number | null>(null);
  const { user: currentAuthUser } = useAuthContext();
  const { addAlert } = useAlerts();
  const theme = useTheme();

  const handleClickMenuItem = useCallback((event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedMenuIndex(index);
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClosePopover = useCallback(() => setAnchorEl(null), []);

  const {
    data: users,
    isLoading: getTeamMembersPending,
    refetch: getTeamMembers,
  } = useQuery({
    queryKey: ['ORG_USERS', currentAuthUser?.org_name],
    queryFn: async () => {
      const res = await axiosAuth.get<{ users: UserRecord[] }>(
        `/org/${currentAuthUser?.org_id}/users`,
      );

      return res?.data;
    },
    select: data => data?.users,
    enabled: Boolean(currentAuthUser?.org_name && currentAuthUser?.org_id),
  });

  const { isPending: promoteTeamMemberPending, mutateAsync: promoteTeamMember } = useMutation({
    mutationKey: [MUTATION_KEYS.PROMOTE_TEAM_MEMBER],
    mutationFn: async ({ email }: TeamSettingsMutationArgs): Promise<UserRecord> => {
      if (email === currentAuthUser?.email) throw new Error('Cannot promote yourself');
      const res = await axiosAuth.patch<UserRecord>(
        `/org/${currentAuthUser?.org_id}/users/${email}/promote`,
      );

      return res.data;
    },
    onMutate: () => handleClosePopover(),
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'User Promoted',
        desc: 'User has been promoted to organization admin',
      });
      getTeamMembers();
    },
    onError: (err: ServerErrorResponse) => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: err.response?.data?.message || 'Unexpected Error',
        desc: 'An error occurred when attempting to promote the user',
      });
    },
    onSettled: () => setSelectedMenuIndex(null),
  });

  const { isPending: kickTeamMemberPending, mutateAsync: kickTeamMember } = useMutation({
    mutationKey: [MUTATION_KEYS.KICK_TEAM_MEMBER],
    mutationFn: async ({ email }: TeamSettingsMutationArgs): Promise<UserRecord> => {
      if (email === currentAuthUser?.email) throw new Error('Cannot kick yourself');
      const res = await axiosAuth.delete<UserRecord>(
        `/org/${currentAuthUser?.org_id}/users/${email}/kick`,
      );

      return res.data;
    },
    onMutate: () => handleClosePopover(),
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'User Kicked',
        desc: 'User has been kicked from the organization',
      });
      getTeamMembers();
    },
    onError: (err: ServerErrorResponse) => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: err.response?.data?.message || 'Unexpected Error',
        desc: 'An error occurred when attempting to kick the user',
      });
    },
    onSettled: () => setSelectedMenuIndex(null),
  });

  const teamMemberActionPending = useMemo(
    () => promoteTeamMemberPending || kickTeamMemberPending,
    [kickTeamMemberPending, promoteTeamMemberPending],
  );

  const actions: {
    label: string;
    actionFn: (cur: UserRecord) => Promise<UserRecord>;
  }[] = [
    {
      label: 'Promote',
      actionFn: (cur: UserRecord) =>
        promoteTeamMember({
          email: cur.email,
        }),
    },
    {
      label: 'Kick',
      actionFn: (cur: UserRecord) =>
        kickTeamMember({
          email: cur.email,
        }),
    },
  ];

  return (
    <>
      {/* <PageBanner background={theme.colors.gradients.sheet} title="Team Settings" /> */}
      <Stack sx={{ background: theme.colors.gradients.sheet, p: px }}>
        <Typography variant="h3">Team Settings</Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{ borderBottom: '1px solid rgba(32, 39, 35, 0.08)', minHeight: '40vh' }}
      >
        <Stack
          px={5}
          sx={{ flex: 1, background: 'linear-gradient(119deg, #F6F6F6 0%, #E7E7E7 100%)' }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography mt={5} variant="h3">
              Manage team
            </Typography>
            <Typography mt={3} variant="bodySmall">
              Invite a team member to your organization
            </Typography>
          </Box>
          {showInviteScreen ? (
            <Box sx={{ flex: 1 }}>
              <Typography
                mt={4}
                onClick={() => {
                  setShowInviteScreen(false);
                }}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}
                variant="bodySmallC"
              >
                <IoArrowBack size={20} />
                BACK
              </Typography>
            </Box>
          ) : (
            <Box sx={{ flex: 1 }}>
              <Typography
                mt={4}
                onClick={() => {
                  setShowInviteScreen(true);
                }}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}
                variant="bodySmallC"
              >
                <FaCirclePlus size={20} />
                INVITE TO TEAM
              </Typography>
            </Box>
          )}
        </Stack>
        {showInviteScreen ? (
          <InviteTeamMember />
        ) : (
          <Stack
            sx={{
              ...(getTeamMembersPending && { justifyContent: 'center', alignItems: 'center' }),
              flex: 2,
              background: '#fff',
            }}
          >
            {getTeamMembersPending ? (
              <CircularProgress />
            ) : (
              users?.map((cur, index) => (
                <Stack
                  alignItems="center"
                  direction="row"
                  key={cur.email}
                  px={5}
                  py={3}
                  sx={{ borderTop: index === 0 ? 'none' : '1px solid rgba(32, 39, 35, 0.08)' }}
                >
                  <Box sx={{ display: 'flex', flex: 1, gap: 1.5 }}>
                    <Typography variant="bodySmall">{cur.email}</Typography>
                    {cur.role === ROLES.ORG_ADMIN && <GrUserAdmin size={20} />}
                  </Box>

                  {cur.role !== ROLES.ORG_ADMIN && currentAuthUser?.email !== cur.email && (
                    <>
                      <IconButton
                        disabled={teamMemberActionPending}
                        onClick={e => handleClickMenuItem(e, index)}
                      >
                        {selectedMenuIndex === index && teamMemberActionPending ? (
                          <CircularProgress size={16} />
                        ) : (
                          <IoIosMore size={20} />
                        )}
                      </IconButton>
                      <Popover
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        key={cur.email}
                        onClose={handleClosePopover}
                        open={Boolean(anchorEl)}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        {actions.map(action => {
                          return (
                            <MenuItem
                              key={action.label}
                              onClick={() => action.actionFn(cur)}
                              sx={{
                                width: '164px',
                                height: '52px',
                                borderTop:
                                  index === 0 ? 'none' : '1px solid rgba(32, 39, 35, 0.08)',
                              }}
                            >
                              <Typography variant="body1">{action.label}</Typography>
                            </MenuItem>
                          );
                        })}
                      </Popover>
                    </>
                  )}
                </Stack>
              ))
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
}
