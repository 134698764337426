import { Section } from 'pages/DeploymentDetails/Section';
import { SectionContentKeyValue } from 'pages/DeploymentDetails/SectionContentKeyValue';
import { enforceLowercasedHyphenCasing } from 'utils/strings';

import { IRollupInfoSection } from './types';

export const VersionSection = ({ loading, rollup }: IRollupInfoSection) => {
  return (
    <Section
      renderGridContent={
        <>
          {Object.entries(rollup?.version ?? {}).map(([key, value]) => (
            <SectionContentKeyValue
              clipboard
              key={key}
              label={enforceLowercasedHyphenCasing(key)}
              loading={loading}
              value={value ?? '-'}
              xs={4}
            />
          ))}
        </>
      }
      title="Version"
    />
  );
};
