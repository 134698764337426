export const useScrollToElement = () => {
  const chatHistoryElement = document.getElementById('chat-history');

  return {
    scrollToChatInput: () => {
      window.scrollTo({ top: window.screen.height, behavior: 'smooth' });
    },
    scrollChatHistory: () => {
      chatHistoryElement?.scrollIntoView({ behavior: 'smooth' });
      chatHistoryElement?.scrollTo({
        top: chatHistoryElement?.scrollHeight + 100,
        behavior: 'smooth',
      });
    },
  };
};
