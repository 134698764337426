import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { verifyPromoCode } from 'api/auth';
import { AxiosError } from 'axios';
import { Banner } from 'components/Banner';
import FormTextField from 'components/form-fields/FormTextField';
import { PageBanner, PageBannerVariant } from 'components/Layout/PageBanner';
import { SectionTitle } from 'components/SectionTitle';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATHS } from 'routes';
import { SETTLEMENT_CHAINS } from 'types/deployments';
import { DAType, RollupType } from 'types/protoc-gen/rollup';

import SelectDA from './form-steps/SelectDA';
import SelectIntegrations from './form-steps/SelectIntegrations';
import SelectSettlementLayer from './form-steps/SelectSettlementLayer';
import SelectStackType from './form-steps/SelectStackType';
import { FundOrbitAccountsDialog } from './FundOrbitAccountsDialog';
import SummaryPanel from './SummaryPanel';
import { getValidationSchema } from './validationSchema';
import { VerifyPromoCode } from './VerifyPromoCode';

export interface FormValues {
  rollupType?: RollupType;
  isTestnet?: boolean;
  network?: SETTLEMENT_CHAINS;
  babylonEnabled?: boolean | null;
  // babylonFpDetails?: string | null; // TODO: revert when feature is ready
  daType?: DAType;
  challengePeriod?: number;
  chainId?: string;
  networkName?: string;
}

export function NewDeployment() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const [searchParams, setSearchParams] = useSearchParams();

  const promoCode = useMemo(() => searchParams?.get('code'), [searchParams]);

  const setPromoCodeParam = useCallback(
    (code: string) => {
      const params = Array.from(searchParams.entries()).reduce(
        (acc, cur) => ({ ...acc, [cur[0]]: cur[1] }),
        {},
      );

      setSearchParams({
        ...params,
        code,
      });
    },
    [searchParams, setSearchParams],
  );

  const {
    data: isValid,
    isPending,
    mutate: verifyCode,
  } = useMutation({
    mutationFn: verifyPromoCode,
    onError: err => {
      console.error('invalid promo code', err);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title:
          (err as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Invalid promo code.',
      });
      setPromoCodeParam('');
    },
  });

  useEffect(() => {
    if (promoCode) {
      verifyCode(promoCode || '');
    }
  }, [promoCode, verifyCode]);

  const form = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      rollupType: RollupType.TYPE_ORBIT,
      isTestnet: true,
      network: SETTLEMENT_CHAINS.SEPOLIA,
      daType: DAType.TYPE_ANYTRUST,
      challengePeriod: 12,
      babylonEnabled: false,
    },
    resolver: yupResolver<FormValues>(getValidationSchema(queryClient)),
  });

  const [dialogRollupType, setDialogRollupType] = useState(RollupType.UNRECOGNIZED);

  const onSubmit: SubmitHandler<FormValues> = data => {
    setDialogRollupType(data?.rollupType || RollupType.UNRECOGNIZED);
  };

  const onError: SubmitErrorHandler<FormValues> = formErrors => {
    console.error(formErrors);
  };

  const [showAlphaWarning, setShowAlphaWarning] = useState(true);

  return (
    <>
      <PageBanner
        onClickBack={() => navigate(PATHS.DEPLOYMENTS, { replace: true })}
        title="Deploy a Rollup"
        variant={PageBannerVariant.RAAS}
      />
      {isPending ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            flex: 1,
            position: 'relative',
          }}
        >
          <CircularProgress size={50} />
        </Stack>
      ) : !isValid ? (
        <VerifyPromoCode />
      ) : (
        <Box bgcolor="#fafafa" pb={4} sx={{ position: 'relative' }}>
          <Banner
            in={showAlphaWarning}
            onClose={() => setShowAlphaWarning(false)}
            text={`This feature is currently in the alpha stage, and rollups will be regularly
                terminated.`}
          />
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit, onError)}>
              <FundOrbitAccountsDialog
                onClose={() => {
                  setDialogRollupType(RollupType.UNRECOGNIZED);
                }}
                open={dialogRollupType === RollupType.TYPE_ORBIT}
              />
              <Box margin="0 auto">
                <Stack flexDirection="row">
                  <Box flex={1}>
                    <SectionTitle>Build your stack</SectionTitle>
                    <SelectStackType id="step_rollupType" />
                    <SelectIntegrations />
                    <SelectSettlementLayer />
                    <SelectDA id="step_daType" />
                    <SectionTitle my={4}>Rollup Settings</SectionTitle>
                    <FormTextField
                      fieldConfig={{
                        name: 'networkName',
                        label: 'Network name',
                        placeholder: 'abc-net',
                      }}
                      id="step_networkName"
                      tooltipContent="Example: abc-net. We will use your network name to label internal resources for your chain as well as to generate deliverables such as your network URL links (e.g., https://abc-net.alt.technology)."
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'challengePeriod',
                        label: 'Choose the challenge period (seconds)',
                        placeholder: '12',
                      }}
                      id="step_challengePeriod"
                      numericStepperProps={{ step: 1 }}
                      tooltipContent="The challenge period is the number of seconds that a proposal must be available to challenge before it is considered finalized. This will determine the duration your users must wait for withdrawals. By default, this is 12 seconds for testnets."
                      type="number"
                    />
                    <FormTextField
                      fieldConfig={{
                        name: 'chainId',
                        label: 'Chain ID',
                        placeholder: '110001',
                      }}
                      id="step_chainId"
                      tooltipContent="The chain ID is immutable and can't be modified after. Check on Chainlist to avoid duplication: https://chainlist.org"
                      type="number"
                    />
                  </Box>
                  <Box width="30%">
                    <SummaryPanel />
                  </Box>
                </Stack>
              </Box>
            </form>
            <DevTool control={form.control} />
          </FormProvider>
        </Box>
      )}
    </>
  );
}
