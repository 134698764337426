import { Button, Dialog, DialogProps, Radio, Stack, Typography } from '@mui/material';
import { DialogContainer, DialogHeader } from 'components/Dialog';
import { deploymentTypeHelperText } from 'pages/AaasDeployments/constants';
import { DeploymentType } from 'pages/AaasDeployments/types';
import { useState } from 'react';

export const SelectDeploymentDialog: React.FC<
  DialogProps & { onConfirmed?: (deploymentType: DeploymentType | undefined) => void }
> = ({ onClose, onConfirmed, open, ...props }) => {
  const [deploymentType, setDeploymentType] = useState<DeploymentType>();

  return (
    <Dialog onClose={onClose} open={open} {...props}>
      <DialogContainer>
        <DialogHeader onClose={() => onClose?.({}, 'backdropClick')} title="Select Deployment" />

        <Stack
          sx={{
            border: theme => `1px solid ${theme.colors.functional.subject.border}`,
            ...(!deploymentType && {
              '& .MuiButton-root:first-of-type': {
                borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
              },
            }),
          }}
        >
          {[DeploymentType.TEE, DeploymentType.NON_TEE].map(deployment => (
            <Button
              disableElevation
              key={deployment}
              onClick={() => {
                if (deployment === deploymentType) {
                  return setDeploymentType(undefined);
                }

                setDeploymentType(deployment);
              }}
              sx={{
                p: 0,
                justifyContent: 'start',
                '&, &:hover': {
                  background: 'none',
                  color: theme => theme.colors.functional.text.primary,
                },
                minHeight: '150px',
                ...(deployment === deploymentType && {
                  border: theme => `1px solid ${theme.colors.functional.text.link}`,
                }),
              }}
            >
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="start"
                spacing="16px"
                sx={{
                  p: '24px',
                }}
              >
                <Radio
                  checked={deployment === deploymentType}
                  sx={theme => ({
                    color: theme.palette.text.primary,
                    '&.Mui-checked': {
                      color: theme.colors.functional.text.link,
                    },
                  })}
                />
                <Stack alignItems="start" justifyContent="start" spacing="8px">
                  <Typography variant="bodySmallM">{deployment}</Typography>
                  <Typography textAlign="justify" variant="caption">
                    {deploymentTypeHelperText[deployment]}
                  </Typography>
                </Stack>
              </Stack>
            </Button>
          ))}
        </Stack>

        <Button
          disableElevation
          disabled={!deploymentType}
          fullWidth
          onClick={() => {
            onConfirmed?.(deploymentType);
            onClose?.({}, 'backdropClick');
          }}
        >
          Confirm
        </Button>
      </DialogContainer>
    </Dialog>
  );
};
