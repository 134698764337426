import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useRollupPublicClient } from 'hooks/viem/useRollupPublicClient';
import { Rollup } from 'types/protoc-gen/raas-rollup';
import { QUERY_KEYS } from 'types/react-query';
import { Address } from 'viem';

export interface IUseBatchBalances
  extends Omit<UseQueryOptions<BatchBalancesResult>, 'queryKey' | 'queryFn'> {
  rollup: Rollup | undefined;
  accounts: Address[] | { label: string; account: Address }[];
}

export const useBatchBalances = ({ accounts, rollup, ...queryOptions }: IUseBatchBalances) => {
  const client = useRollupPublicClient(rollup);

  return useQuery({
    queryKey: [QUERY_KEYS.VIEM_BATCH_BALANCES, { accounts }],
    queryFn: async (): Promise<BatchBalancesResult> => {
      if (!rollup) throw new Error('Provided rollup must not be undefined');

      const balances = await Promise.all(
        accounts.map(address =>
          client?.getBalance({ address: typeof address === 'string' ? address : address.account }),
        ),
      );

      return accounts.map<BatchBalanceMap>((account, index) => ({
        label: typeof account === 'string' ? undefined : account.label,
        account: typeof account === 'string' ? account : account.account,
        balance: balances?.[index],
      }));
    },
    enabled: !!rollup,
    ...queryOptions,
  });
};

export interface BatchBalanceMap {
  label?: string;
  account: Address;
  balance: bigint | undefined;
}

export type BatchBalancesResult = BatchBalanceMap[];
