import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import IconAvail from 'components/icons/IconAvail';
import IconCelestia from 'components/icons/IconCelestia';
import IconCheckedCircle from 'components/icons/IconCheckedCircle';
import IconEigenDa from 'components/icons/IconEigenDa';
import IconEth from 'components/icons/IconEth';
import IconNay from 'components/icons/IconNay';
import IconOP from 'components/icons/IconOP';

const details: {
  [key: string]: Partial<{
    eigen: string | boolean;
    op: string | boolean;
    eth: string | boolean;
    celestia: string | boolean;
    avail: string | boolean;
  }>;
} = {
  'Consensus mechanism': {
    eigen: 'Committee based',
    op: undefined,
    eth: 'GHOST + Casper3',
    celestia: 'Tendermint',
    avail: 'GRANDPA + BASE',
  },
  Decentralization: {
    eigen: 'Good',
    op: 'Good',
    eth: 'Excellent',
    celestia: 'Good',
    avail: 'Great',
  },
  'Execution Environment Overhead': {
    eigen: 'None',
    op: 'None',
    eth: 'Yes',
    celestia: 'None',
    avail: 'None',
  },
  'Validity Proofs': {
    eigen: true,
    op: true,
    eth: false,
    celestia: false,
    avail: true,
  },
  'Ability to Scale': {
    eigen: true,
    op: true,
    eth: false,
    celestia: true,
    avail: true,
  },
  'DA Sampling': {
    eigen: false,
    op: false,
    eth: false,
    celestia: true,
    avail: true,
  },
  Cost: {
    eigen: 'Low',
    op: 'Low',
    eth: 'Very high',
    celestia: 'Low',
    avail: 'Low',
  },
};

export const DADetailsTable = () => (
  <TableContainer
    sx={{
      '& .MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root': {
        background: theme => theme.colors.gradients.metal,
        p: 0,
        pt: '12px',
        pb: '5px',
        minWidth: '166px',
      },
      '& .MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root:first-of-type': {
        background: theme => theme.colors.gradients.sheet,
      },
      '& .MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root > *': {
        height: '24px',
        width: '100%',
      },

      '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root': {
        color: theme => theme.colors.functional.text.primary,
        background: theme => theme.colors.gradients.sheet,
        pl: 0,
        py: '16px',
        px: '24px',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '18px',
        textAlign: 'center',
      },
      '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root:first-of-type': {
        color: theme => theme.colors.functional.text.primary,
        background: theme => theme.colors.gradients.metal,
        textAlign: 'start',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '18px',
      },
    }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>
            <IconEigenDa />
          </TableCell>
          <TableCell>
            <IconOP />
          </TableCell>
          <TableCell>
            <IconEth />
          </TableCell>
          <TableCell>
            <IconCelestia />
          </TableCell>
          <TableCell>
            <IconAvail />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(details).map(([key, detailsMap]) => (
          <TableRow key={key}>
            <TableCell colSpan={1}>{key}</TableCell>
            {Object.entries(detailsMap).map(([chain, value]) => (
              <TableCell key={chain}>
                {typeof value === 'boolean' ? (
                  !value ? (
                    <IconNay sx={{ height: '18px', width: '18px' }} />
                  ) : (
                    <IconCheckedCircle sx={{ height: '18px', width: '18px' }} />
                  )
                ) : (
                  value ?? '-'
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
