import { Box, Skeleton, Typography } from '@mui/material';
import { hideFeature } from 'helpers/visibility';
import { useBlockscoutStats, useRaasDeployment } from 'hooks';
import { MetricGridItem } from 'pages/DeploymentDetails/MetricGridItem';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionButtonTimeFilter } from 'pages/DeploymentDetails/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/DeploymentDetails/useTimeFilterStore';
import { AverageFeeTipsTrendMetric } from 'pages/DeploymentDetails/views/FeeTrackerView/AverageFeeTipsTrendMetric';

import { AverageGasPriceMetric } from './AverageGasPriceMetric';
import { BaseFeeBalanceMetric } from './BaseFeeBalanceMetric';
import { GasUsedGrowthMetric } from './GasUsedGrowthMetric';
import { L1FeeBalanceMetric } from './L1FeeBalanceMetric';
import { SequencerFeeBalanceMetric } from './SequencerFeeBalanceMetric';

export const FeeTrackerView = () => {
  const { isPending: getRollupLoading } = useRaasDeployment();
  const { data: stats, isPending } = useBlockscoutStats();

  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <AverageGasPriceMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <GasUsedGrowthMetric />
          </MetricGridItem>
          {!hideFeature && (
            <MetricGridItem item>
              <AverageFeeTipsTrendMetric />
            </MetricGridItem>
          )}
          <MetricGridItem item>
            <L1FeeBalanceMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <BaseFeeBalanceMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <SequencerFeeBalanceMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter
          loading={getRollupLoading}
          variant={TimeFilterVariant.FEE_TRACKER_GAS_SECTION}
        />
      }
      renderTitleSuffix={
        isPending ? (
          <Skeleton
            height="30px"
            sx={{ ml: '16px', px: '6px', py: '1px' }}
            variant="text"
            width="50px"
          />
        ) : (
          stats?.gas_prices && (
            <Box sx={{ ml: '16px', background: '#fff', px: '6px', py: '1px' }}>
              <Typography
                sx={{ color: theme => theme.colors.functional.text.primary }}
                variant="caption"
              >
                {`${stats.gas_prices.average} Gwei`}
              </Typography>
            </Box>
          )
        )
      }
      sectionContentSx={{ p: '0px' }}
      title="Gas"
    />
  );
};
