import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { verifyPromoCode } from 'api/auth';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { MUTATION_KEYS } from 'types/react-query';

export interface IUseVerifyPromoCode
  extends Omit<
    UseMutationOptions<boolean, unknown, string | undefined>,
    'mutationFn' | 'mutationKey'
  > {}

export const useVerifyPromoCode = (options?: IUseVerifyPromoCode) => {
  const { addAlert } = useAlerts();

  return useMutation({
    mutationKey: [MUTATION_KEYS.AAAS_VERIFY_PROMO_CODE],
    mutationFn: async (code?: string) => {
      const result = await verifyPromoCode(code);

      return result;
    },
    onSuccess: (data, variables, context) => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Promo code verified',
      });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to verify promo code',
      });
      options?.onError?.(error, variables, context);
    },
    ...options,
  });
};
