import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconCopy = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M4 3.5H3.5V4V11H4.5V4.5H11V3.5H4ZM8 8H15V15H8V8ZM7 7H8H15H16V8V15V16H15H8H7V15V8V7Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
