import { useQuery } from '@tanstack/react-query';
import { useBlockscoutAxios } from 'hooks/raas/useBlockscoutAxios';
import { useParams } from 'react-router-dom';
import { QUERY_KEYS } from 'types/react-query';

export const metrics = [
  'totalAddresses',
  'totalContracts',
  'totalAccounts',
  'totalTxns',
  'totalTokens',
];

export const useBlockscoutCounters = () => {
  const { rollupId } = useParams();
  const blockscout = useBlockscoutAxios();

  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_BLOCKSCOUT_STATS,
      {
        variant: 'counters',
        rollupId,
      },
    ],
    queryFn: async (): Promise<Counter[]> => {
      const res = await blockscout.l2.get<BlockscoutCountersResponse>('/counters');

      return res.data.counters.filter(counter => metrics.includes(counter.id));
    },
    enabled: !!blockscout?.rollup,
  });
};

interface Counter {
  description: string;
  id: string;
  title: string;
  units: string;
  value: string;
}

interface BlockscoutCountersResponse {
  counters: Counter[];
}
