// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.28.3
// source: raas-metrics.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Duration } from "./google/protobuf/duration";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "rollupplugin.v1";

export enum Panel {
  PANEL_UNSPECIFIED = 0,
  PANEL_OPSTACK_L2_UNSAFE = 1,
  PANEL_OPSTACK_L2_SAFE = 2,
  PANEL_OPSTACK_L2_FINALIZED = 3,
  PANEL_OPSTACK_BATCHER_TX_COUNT = 4,
  PANEL_OPSTACK_PROPOSER_TX_COUNT = 5,
  PANEL_OPSTACK_BATCHER_PENDING_BLOCK = 6,
  PANEL_OPSTACK_CPU = 7,
  PANEL_OPSTACK_MEMORY = 8,
  PANEL_OPSTACK_TX_POOL = 9,
  PANEL_OPSTACK_RPM = 10,
  PANEL_OPSTACK_RPM_BY_METHOD = 11,
  UNRECOGNIZED = -1,
}

export function panelFromJSON(object: any): Panel {
  switch (object) {
    case 0:
    case "PANEL_UNSPECIFIED":
      return Panel.PANEL_UNSPECIFIED;
    case 1:
    case "PANEL_OPSTACK_L2_UNSAFE":
      return Panel.PANEL_OPSTACK_L2_UNSAFE;
    case 2:
    case "PANEL_OPSTACK_L2_SAFE":
      return Panel.PANEL_OPSTACK_L2_SAFE;
    case 3:
    case "PANEL_OPSTACK_L2_FINALIZED":
      return Panel.PANEL_OPSTACK_L2_FINALIZED;
    case 4:
    case "PANEL_OPSTACK_BATCHER_TX_COUNT":
      return Panel.PANEL_OPSTACK_BATCHER_TX_COUNT;
    case 5:
    case "PANEL_OPSTACK_PROPOSER_TX_COUNT":
      return Panel.PANEL_OPSTACK_PROPOSER_TX_COUNT;
    case 6:
    case "PANEL_OPSTACK_BATCHER_PENDING_BLOCK":
      return Panel.PANEL_OPSTACK_BATCHER_PENDING_BLOCK;
    case 7:
    case "PANEL_OPSTACK_CPU":
      return Panel.PANEL_OPSTACK_CPU;
    case 8:
    case "PANEL_OPSTACK_MEMORY":
      return Panel.PANEL_OPSTACK_MEMORY;
    case 9:
    case "PANEL_OPSTACK_TX_POOL":
      return Panel.PANEL_OPSTACK_TX_POOL;
    case 10:
    case "PANEL_OPSTACK_RPM":
      return Panel.PANEL_OPSTACK_RPM;
    case 11:
    case "PANEL_OPSTACK_RPM_BY_METHOD":
      return Panel.PANEL_OPSTACK_RPM_BY_METHOD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Panel.UNRECOGNIZED;
  }
}

export function panelToJSON(object: Panel): string {
  switch (object) {
    case Panel.PANEL_UNSPECIFIED:
      return "PANEL_UNSPECIFIED";
    case Panel.PANEL_OPSTACK_L2_UNSAFE:
      return "PANEL_OPSTACK_L2_UNSAFE";
    case Panel.PANEL_OPSTACK_L2_SAFE:
      return "PANEL_OPSTACK_L2_SAFE";
    case Panel.PANEL_OPSTACK_L2_FINALIZED:
      return "PANEL_OPSTACK_L2_FINALIZED";
    case Panel.PANEL_OPSTACK_BATCHER_TX_COUNT:
      return "PANEL_OPSTACK_BATCHER_TX_COUNT";
    case Panel.PANEL_OPSTACK_PROPOSER_TX_COUNT:
      return "PANEL_OPSTACK_PROPOSER_TX_COUNT";
    case Panel.PANEL_OPSTACK_BATCHER_PENDING_BLOCK:
      return "PANEL_OPSTACK_BATCHER_PENDING_BLOCK";
    case Panel.PANEL_OPSTACK_CPU:
      return "PANEL_OPSTACK_CPU";
    case Panel.PANEL_OPSTACK_MEMORY:
      return "PANEL_OPSTACK_MEMORY";
    case Panel.PANEL_OPSTACK_TX_POOL:
      return "PANEL_OPSTACK_TX_POOL";
    case Panel.PANEL_OPSTACK_RPM:
      return "PANEL_OPSTACK_RPM";
    case Panel.PANEL_OPSTACK_RPM_BY_METHOD:
      return "PANEL_OPSTACK_RPM_BY_METHOD";
    case Panel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GetSeriesRequest {
  panel: Panel;
  start: Date | undefined;
  end: Date | undefined;
  step:
    | Duration
    | undefined;
  /** for rollup it's rollup id */
  id: string;
}

export interface GetSeriesReply {
  series: Series[];
}

export interface Point {
  timestamp: Date | undefined;
  value: number;
}

export interface Series {
  label: string;
  points: Point[];
}

function createBaseGetSeriesRequest(): GetSeriesRequest {
  return { panel: 0, start: undefined, end: undefined, step: undefined, id: "" };
}

export const GetSeriesRequest = {
  encode(message: GetSeriesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.panel !== 0) {
      writer.uint32(8).int32(message.panel);
    }
    if (message.start !== undefined) {
      Timestamp.encode(toTimestamp(message.start), writer.uint32(18).fork()).ldelim();
    }
    if (message.end !== undefined) {
      Timestamp.encode(toTimestamp(message.end), writer.uint32(26).fork()).ldelim();
    }
    if (message.step !== undefined) {
      Duration.encode(message.step, writer.uint32(34).fork()).ldelim();
    }
    if (message.id !== "") {
      writer.uint32(42).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSeriesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSeriesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.panel = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.start = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.end = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.step = Duration.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSeriesRequest {
    return {
      panel: isSet(object.panel) ? panelFromJSON(object.panel) : 0,
      start: isSet(object.start) ? fromJsonTimestamp(object.start) : undefined,
      end: isSet(object.end) ? fromJsonTimestamp(object.end) : undefined,
      step: isSet(object.step) ? Duration.fromJSON(object.step) : undefined,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: GetSeriesRequest): unknown {
    const obj: any = {};
    if (message.panel !== 0) {
      obj.panel = panelToJSON(message.panel);
    }
    if (message.start !== undefined) {
      obj.start = message.start.toISOString();
    }
    if (message.end !== undefined) {
      obj.end = message.end.toISOString();
    }
    if (message.step !== undefined) {
      obj.step = Duration.toJSON(message.step);
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSeriesRequest>, I>>(base?: I): GetSeriesRequest {
    return GetSeriesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSeriesRequest>, I>>(object: I): GetSeriesRequest {
    const message = createBaseGetSeriesRequest();
    message.panel = object.panel ?? 0;
    message.start = object.start ?? undefined;
    message.end = object.end ?? undefined;
    message.step = (object.step !== undefined && object.step !== null) ? Duration.fromPartial(object.step) : undefined;
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseGetSeriesReply(): GetSeriesReply {
  return { series: [] };
}

export const GetSeriesReply = {
  encode(message: GetSeriesReply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.series) {
      Series.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSeriesReply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSeriesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.series.push(Series.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSeriesReply {
    return {
      series: globalThis.Array.isArray(object?.series) ? object.series.map((e: any) => Series.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetSeriesReply): unknown {
    const obj: any = {};
    if (message.series?.length) {
      obj.series = message.series.map((e) => Series.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSeriesReply>, I>>(base?: I): GetSeriesReply {
    return GetSeriesReply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSeriesReply>, I>>(object: I): GetSeriesReply {
    const message = createBaseGetSeriesReply();
    message.series = object.series?.map((e) => Series.fromPartial(e)) || [];
    return message;
  },
};

function createBasePoint(): Point {
  return { timestamp: undefined, value: 0 };
}

export const Point = {
  encode(message: Point, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(10).fork()).ldelim();
    }
    if (message.value !== 0) {
      writer.uint32(17).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Point {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Point {
    return {
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: Point): unknown {
    const obj: any = {};
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Point>, I>>(base?: I): Point {
    return Point.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Point>, I>>(object: I): Point {
    const message = createBasePoint();
    message.timestamp = object.timestamp ?? undefined;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseSeries(): Series {
  return { label: "", points: [] };
}

export const Series = {
  encode(message: Series, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    for (const v of message.points) {
      Point.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Series {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSeries();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.label = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.points.push(Point.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Series {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : "",
      points: globalThis.Array.isArray(object?.points) ? object.points.map((e: any) => Point.fromJSON(e)) : [],
    };
  },

  toJSON(message: Series): unknown {
    const obj: any = {};
    if (message.label !== "") {
      obj.label = message.label;
    }
    if (message.points?.length) {
      obj.points = message.points.map((e) => Point.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Series>, I>>(base?: I): Series {
    return Series.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Series>, I>>(object: I): Series {
    const message = createBaseSeries();
    message.label = object.label ?? "";
    message.points = object.points?.map((e) => Point.fromPartial(e)) || [];
    return message;
  },
};

export type MetricsServiceDefinition = typeof MetricsServiceDefinition;
export const MetricsServiceDefinition = {
  name: "MetricsService",
  fullName: "rollupplugin.v1.MetricsService",
  methods: {
    getSeries: {
      name: "GetSeries",
      requestType: GetSeriesRequest,
      requestStream: false,
      responseType: GetSeriesReply,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              24,
              18,
              22,
              47,
              118,
              49,
              47,
              98,
              102,
              102,
              47,
              109,
              101,
              116,
              114,
              105,
              99,
              115,
              47,
              115,
              101,
              114,
              105,
              101,
              115,
            ]),
          ],
        },
      },
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
