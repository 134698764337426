import { useBlockscoutMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';

export const NewAccountMetric = () => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.ROLLUP_STATS_EOA_ACCOUNTS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'newAccounts',
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'New accounts number per day'}
      id={'new-accounts'}
      loading={isPending}
      title={'New account'}
    />
  );
};
