import { AreaChart } from 'components/charts';
import { MetricContainer } from 'pages/DeploymentDetails/MetricContainer';

export const NewDepositedAccountsMetric = () => {
  return (
    <MetricContainer title={'New deposited accounts'}>
      <AreaChart />
    </MetricContainer>
  );
};
