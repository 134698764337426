import { useRaasMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { useParams } from 'react-router-dom';
import { Panel } from 'types/protoc-gen/raas-metrics';
import { formatAbbreviatedNumber } from 'utils/strings';

export interface ITxPoolMetric {
  timeFilterVariant?: TimeFilterVariant;
}

export const TxPoolMetric = ({ timeFilterVariant }: ITxPoolMetric) => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step } = useTimeFilterOptions(
    selectedTimeFilter[timeFilterVariant ?? TimeFilterVariant.OVERVIEW_METRICS_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    rollupId,
    panel: Panel.PANEL_OPSTACK_TX_POOL,
    start,
    end,
    step,
  });

  return (
    <Metric
      chartType="line"
      data={result}
      hideTooltipKeys
      id={'tx-pool'}
      legendSpacing={'8px'}
      loading={isPending}
      title={'Tx pool'}
      yAxisProps={{
        padding: { bottom: 35 },
        tickCount: 3,
        tickFormatter: value => formatAbbreviatedNumber(value),
        tickMargin: -40,
      }}
    />
  );
};
