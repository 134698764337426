import { IconButton, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { IconCopy } from 'components/icons/IconCopy';
import { useCopyToClipboard } from 'hooks/ui';
import ReactMarkdown from 'react-markdown';

import { MessageHistoryItem } from './types';

export interface IChatHistory {
  messageHistory: MessageHistoryItem[];
  loading?: boolean;
}

export const ChatHistory: React.FC<IChatHistory> = ({ loading, messageHistory }) => {
  const [, copy] = useCopyToClipboard();

  return (
    <Stack
      id="chat-history"
      spacing="24px"
      sx={{
        background: '#fff',
        minHeight: '35vh',
        maxHeight: '80vh',
        overflow: 'auto',
      }}
    >
      {messageHistory.map(({ author, message }, index) => {
        return (
          <Stack key={[message, author, index].join('-')} spacing="12px">
            <MessageContainer justifyContent={author === 'user' ? 'end' : 'start'}>
              <Stack
                alignItems="center"
                direction="row"
                spacing="24px"
                sx={{
                  background: theme =>
                    author === 'agent'
                      ? theme.colors.schema.metalPrimary
                      : theme.colors.schema.metalSecondary,
                  px: '16px',
                  minHeight: '60px',
                  maxWidth: '75%',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  overflow: 'auto',
                }}
              >
                <Typography
                  sx={{
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    '& > code': {
                      whiteSpace: 'pre-wrap !important',
                    },
                  }}
                >
                  <ReactMarkdown>{message}</ReactMarkdown>
                </Typography>
              </Stack>
              {author === 'agent' && (
                <Stack alignItems="start" justifyContent="center">
                  <IconButton onClick={() => copy(message)} size="small">
                    <IconCopy />
                  </IconButton>
                </Stack>
              )}
            </MessageContainer>
          </Stack>
        );
      })}

      {loading && (
        <MessageContainer>
          <Skeleton
            sx={{
              minHeight: '60px',
              minWidth: '220px',
            }}
            variant="rectangular"
          />
        </MessageContainer>
      )}

      <Stack id="chat-history-bottom" />
    </Stack>
  );
};

const MessageContainer: React.FC<StackProps> = ({ children, ...props }) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    spacing="8px"
    {...props}
  >
    {children}
  </Stack>
);
