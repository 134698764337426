import { useRaasMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { useParams } from 'react-router-dom';
import { Panel } from 'types/protoc-gen/raas-metrics';

export const ProposerTxCountMetric = () => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.PROPOSER_BALANCE_BALANCE_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    rollupId,
    panel: Panel.PANEL_OPSTACK_PROPOSER_TX_COUNT,
    start,
    end,
    step,
  });

  return (
    <Metric
      curveType="step"
      data={result}
      id={'proposer-tx-count'}
      loading={isPending}
      title={'Proposer tx count'}
    />
  );
};
