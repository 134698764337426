import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useCallback, useState } from 'react';

type CopiedValue = string | null;

type CopyFn = (text: string) => Promise<boolean>;

export interface IUseCopyToClipboard {
  renderDescription?: boolean;
}

export function useCopyToClipboard(options?: IUseCopyToClipboard): [CopiedValue, CopyFn] {
  const { renderDescription } = options ?? {};

  const { addAlert } = useAlerts();
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = useCallback(
    async text => {
      if (!navigator?.clipboard) {
        console.warn('Clipboard not supported');

        return false;
      }

      // Try to save to clipboard then save it in the state if worked
      try {
        await navigator.clipboard.writeText(text);
        setCopiedText(text);

        addAlert({
          title: 'Copied to clipboard',
          severity: ALERT_SEVERITY.INFO,
          ...(renderDescription ? { desc: text } : {}),
        });

        return true;
      } catch (error) {
        console.warn('Copy failed', error);
        setCopiedText(null);

        return false;
      }
    },
    [addAlert, renderDescription],
  );

  return [copiedText, copy];
}
