import { useAuthContext } from 'contexts/AuthContext';
import { Fragment, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { PATHS } from 'routes';

export const RequireLoginGuard = ({ children }: PropsWithChildren) => {
  const { isLoggedIn, user } = useAuthContext();

  if (!isLoggedIn) {
    return <Navigate replace to={PATHS.LOGIN} />;
  }

  if (!user?.org_id) {
    return <Navigate replace to={PATHS.CREATE_ORG} />;
  }

  return <Fragment>{children}</Fragment>;
};
