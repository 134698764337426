import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QUERY_KEYS } from 'types/react-query';
import { Address } from 'viem';

import { useBlockscoutAxios } from './useBlockscoutAxios';

export interface BlockscoutTokenMetricsResponse {
  items: BlockscoutTokenMetricsItem[];
  next_page_params: BlockscoutTokenMetricsNextPageParams;
}

export interface IUseBlockscoutTokens
  extends Omit<UseQueryOptions<BlockscoutTokenMetricsResponse>, 'queryFn' | 'queryKey'> {
  l2?: boolean;
  from: string;
  to: string;
}

export const useBlockscoutTokens = ({ from, l2, to, ...queryOptions }: IUseBlockscoutTokens) => {
  const { rollupId } = useParams();
  const blockscout = useBlockscoutAxios({ v2: true });

  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_BLOCKSCOUT_STATS,
      {
        variant: 'tokens',
        rollupId,
        from,
        to,
      },
    ],
    queryFn: async (): Promise<BlockscoutTokenMetricsResponse> => {
      const res = await blockscout[l2 ? 'l2' : 'l1'].get<BlockscoutTokenMetricsResponse>(
        `/tokens`,
        {
          params: {
            from,
            to,
          },
        },
      );

      return res.data;
    },
    enabled: !!blockscout?.rollup,
    ...queryOptions,
  });
};

export interface BlockscoutTokenMetricsItem {
  address: Address;
  name: string;
  symbol: string;
  type: string;
  decimals: string;
  holders: string;
  total_supply: string;
  icon_url: string | null;
  volume_24h: string | null;
  circulating_market_cap: string | null;
  exchange_rate: string | null;
}

export interface BlockscoutTokenMetricsNextPageParams {
  contract_address_hash: Address;
  fiat_value: string | null;
  holder_count: number;
  is_name_null: boolean;
  items_count: number;
  market_cap: string | null;
  name: string;
}
