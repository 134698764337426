import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconRaaS = ({ ...props }: SvgIconProps) => (
  <SvgIcon height="81" viewBox="0 0 80 81" width="80" {...props}>
    <path
      clipRule="evenodd"
      d="M40.4477 1.16081L40.0977 0.95874L39.7477 1.16081L34.2716 4.32244L33.9216 4.52451V4.92866V11.2519V11.6561L34.2716 11.8581L39.7477 15.0198L40.0977 15.2218L40.4477 15.0198L45.6711 12.004L69.2964 25.2066V45.8977H70.6964V24.7959V24.3852L70.3379 24.1848L46.2738 10.737V4.92866V4.52451L45.9238 4.32244L40.4477 1.16081ZM35.3216 10.8478V5.3328L40.0977 2.57532L44.8738 5.3328V10.8478L40.0977 13.6052L35.3216 10.8478ZM40.4477 21.3834L40.0977 21.1813L39.7477 21.3834L22.0884 31.5789L21.7384 31.781V32.1852V52.5763V52.9804L22.0884 53.1825L39.7477 63.378L40.0977 63.5801L40.4477 63.378L58.1069 53.1825L58.4569 52.9804V52.5763V32.1852V31.781L58.1069 31.5789L40.4477 21.3834ZM23.1384 52.1721V32.5893L40.0977 22.7979L57.0569 32.5893V52.1721L40.0977 61.9635L23.1384 52.1721ZM9.50604 52.3574V24.7958V24.3875L9.86149 24.1865L27.2388 14.3601L27.9279 15.5788L10.906 25.2042V52.3604L16.0295 55.3185L16.3795 55.5205V55.9247V62.2479V62.6521L16.0295 62.8541L10.5534 66.0158L10.2034 66.2178L9.85344 66.0158L4.37734 62.8541L4.02734 62.6521V62.2479V55.9247V55.5205L4.37734 55.3185L9.50604 52.3574ZM70.3419 52.1568L69.9919 51.9548L69.6419 52.1568L64.1658 55.3185L63.8158 55.5205V55.9247V61.732L40.1023 75.4231L22.6952 65.3732L21.9952 66.5857L39.7523 76.8376L40.1023 77.0397L40.4523 76.8376L64.419 63.0003L69.6419 66.0158L69.9919 66.2178L70.3419 66.0158L75.818 62.8541L76.168 62.6521V62.2479V55.9247V55.5205L75.818 55.3185L70.3419 52.1568ZM5.42734 61.8438V56.3288L10.2034 53.5713L14.9795 56.3288V61.8438L10.2034 64.6013L5.42734 61.8438ZM65.2158 61.8438V56.3288L69.9919 53.5713L74.768 56.3288V61.8438L69.9919 64.6013L65.2158 61.8438Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
