import { Stack } from '@mui/material';
import { usePrompt } from 'hooks/aaas';
import { aaaType } from 'labels/aaas';
import { useApp } from 'pages/AaasDeployments/useApp';
import { useChatStore } from 'pages/AaasDetails/useChatStore';
import { Section } from 'pages/DeploymentDetails/Section';
import { useEffect, useRef } from 'react';
import { aAATypeFromJSON } from 'types/protoc-gen/aaas-bffavs';

import { ChatHistory, InputSection } from './components';
import { useScrollToElement } from './useScrollToElement';

export const ChatView = () => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const { data: app } = useApp();
  const { scrollChatHistory, scrollToChatInput } = useScrollToElement();
  const { addMessage, messages, registerConversation } = useChatStore();
  const { isPending: promptPending, mutateAsync: prompt } = usePrompt({
    onSuccess: ({ response }) => {
      addMessage(app?.id, {
        message: response.join('\n'),
        author: 'agent',
      });

      scrollChatHistory();
      setTimeout(() => scrollToChatInput(), 1_000);
      textFieldRef.current?.focus();
    },
  });

  useEffect(() => {
    registerConversation(app?.id);
  }, [app, registerConversation]);

  useEffect(() => {
    textFieldRef.current?.focus();
    scrollToChatInput();
    scrollChatHistory();

    if (app?.id && messages[app.id]?.length === 0) {
      addMessage(app?.id, {
        message: 'GM Alter! How can I help you? 🤗',
        author: 'agent',
      });
    }
  }, [addMessage, app, scrollToChatInput, messages, scrollChatHistory]);

  const _messages = app?.id ? messages[app?.id] : [];

  return (
    <Section
      title={['Chat with AI Agent', app && `(${aaaType[aAATypeFromJSON(app.app)]})`].join(' ')}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing="24px"
        sx={{
          '&, & > *': {
            width: '100%',
          },
          py: '24px',
          px: {
            xs: '16px',
            sm: '24px',
            md: '100px',
            lg: '100px',
            xl: '100px',
          },
        }}
      >
        {_messages?.length > 0 && (
          <ChatHistory loading={promptPending} messageHistory={_messages} />
        )}

        <InputSection
          disabled={!app?.endpoints?.host}
          loading={promptPending}
          onPrompt={({ question }) => {
            if (!question) return;

            prompt({ message: question });
            addMessage(app?.id, {
              message: question,
              author: 'user',
            });
          }}
          ref={textFieldRef}
        />
      </Stack>
    </Section>
  );
};
