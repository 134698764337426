import { Box, Grid, GridProps, Stack, Typography } from '@mui/material';
import { IconAaa } from 'components/icons/IconAaa';
import { IconAvs } from 'components/icons/IconAvs';
import { IconRaaS } from 'components/icons/IconRaaS';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

interface LayerCardProps extends Omit<GridProps, 'title'> {
  path: string;
  icon: ReactNode;
  title: ReactNode;
  description: string;
}

function LayerCard({ description, icon, path, title, ...props }: LayerCardProps) {
  const navigate = useNavigate();

  return (
    <Grid
      alignItems="center"
      container
      item
      justifyContent="center"
      lg={4}
      onClick={() => navigate(path)}
      xs={12}
      {...props}
    >
      <Stack alignItems="center" spacing={9}>
        {icon}
        <Box>
          <Typography textAlign="center" variant="h3">
            {title}
          </Typography>
          <Typography mt={2} textAlign="center" variant="body2">
            {description}
          </Typography>
        </Box>
        <Box component="img" src="/imgs/iconArrowCircleFilled.svg" sx={{ width: 56, height: 56 }} />
      </Stack>
    </Grid>
  );
}

export function SelectLayerType() {
  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      sx={{ bgcolor: '#fafafa', height: '80vh', '&>*': { height: '100%', cursor: 'pointer' } }}
    >
      <LayerCard
        description="Autonomous AI Agent"
        icon={<IconAaa sx={{ width: '80px', height: '80px' }} />}
        path={PATHS.AAAS_DEPLOYMENTS}
        sx={{ background: theme => theme.colors.gradients.cream }}
        title="AAA"
      />
      <LayerCard
        description="Deploy and manage your AVS"
        icon={<IconAvs sx={{ width: '80px', height: '80px' }} />}
        path={PATHS.AVS_DEPLOYMENTS}
        sx={{ background: theme => theme.colors.gradients.water }}
        title="AVS"
      />
      <LayerCard
        description="Deploy and manage your Rollup"
        icon={<IconRaaS sx={{ mx: 'auto', width: '80px', height: '81px' }} />}
        path={PATHS.DEPLOYMENTS}
        sx={{ background: theme => theme.colors.gradients.leaf }}
        title="RaaS"
      />
    </Grid>
  );
}
