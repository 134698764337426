import { TextField } from '@mui/material';
import { Controller, ControllerProps } from 'react-hook-form';

export interface IInlineFormTextField extends Omit<ControllerProps, 'render'> {}

export const InlineFormTextField = ({ ...props }: IInlineFormTextField) => (
  <Controller
    {...props}
    render={({ field, fieldState }) => (
      <TextField
        {...field}
        error={Boolean(fieldState.error)}
        fullWidth={false}
        helperText={fieldState.error?.message || ' '} // Empty space so that layout does not shift when error message appears
        inputRef={field.ref}
        margin="none"
        placeholder="Domain for RPC Endpoint"
        size="small"
        sx={{
          p: 0,
          m: 0,
          mb: 0,
          pr: '8px',
          width: '100%',
          '.MuiInputBase-input': {
            p: 0,
            m: 0,
            mb: 0,
          },
        }}
        value={field.value || ''}
        variant="standard"
      />
    )}
  />
);
