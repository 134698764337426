import { Grid, GridProps, Stack, useTheme } from '@mui/material';

export interface IMetricGridItem extends Omit<GridProps, 'children'> {
  children: React.ReactNode;
  stackSx?: GridProps['sx'];
  item?: boolean;
}

export const MetricGridItem = ({ children, stackSx, sx, ...props }: IMetricGridItem) => {
  const theme = useTheme();

  return (
    <Grid
      container
      flexDirection="column"
      xs={6}
      {...props}
      sx={{
        width: '100%',
        '&&:nth-of-type(odd)': {
          borderRight: `1px solid ${theme.colors.functional.subject.border}`,
          borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
        },
        '&&:nth-of-type(even)': {
          borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
        },
        ...sx,
      }}
    >
      <Stack
        spacing="8px"
        sx={{
          background: '#fff',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          width: '100%',
          ...stackSx,
        }}
      >
        {children}
      </Stack>
    </Grid>
  );
};
