import { useMemo } from 'react';
import { Rollup } from 'types/protoc-gen/raas-rollup';
import { createPublicClient, http } from 'viem';

export const useRollupPublicClient = (rollup: Rollup | undefined) => {
  return useMemo(
    () =>
      rollup &&
      createPublicClient({
        batch: {
          multicall: true,
        },
        chain: {
          id: rollup?.chainId,
          name: rollup?.name,
          nativeCurrency: {
            name: rollup?.tokenMetadata?.name,
            decimals: rollup?.tokenMetadata?.decimals,
            symbol: rollup?.tokenMetadata?.symbol,
          },
          rpcUrls: {
            default: {
              http: [rollup?.rpcEndpoint],
              webSocket: [rollup?.rpcWsEndpoint],
            },
          },
          blockExplorers: {
            default: {
              name: rollup?.name,
              url: rollup?.explorer,
            },
          },
        },
        transport: http(rollup?.rpcEndpoint, {
          batch: true,
        }),
      }),
    [rollup],
  );
};
