import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useRoutes } from 'react-router-dom';
import { PATHS, routes } from 'routes';

import Layout from './components/Layout';
import { AuthContextProvider } from './contexts/AuthContext';
import { inputGlobalStyles } from './theme/globalStyle';

export { PATHS }; // TODO: migrate all that imports PATHS from App

const App: React.FC = () => {
  const content = useRoutes(routes);

  return (
    <>
      <CssBaseline />
      {inputGlobalStyles}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}>
        <AuthContextProvider>
          <Layout>{content}</Layout>
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
