import { Editor } from '@monaco-editor/react';
import {
  Button,
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { IconClose } from 'components/icons/IconClose';
import { IconDocument } from 'components/icons/IconDocument';
import { Rollup } from 'types/protoc-gen/raas-rollup';

export interface IEditDialog extends DialogProps {
  data?: Rollup;
  onClose?: () => void;
}

export const EditDialog = ({ data, onClose, ...props }: IEditDialog) => {
  const theme = useTheme();

  return (
    <Dialog onClose={onClose} {...props}>
      <Stack
        spacing="24px"
        sx={{ minHeight: '500px', minWidth: '546px', background: '#fff', p: '40px' }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h5">Edit {data?.name}</Typography>
          <IconButton onClick={onClose} size="small">
            <IconClose />
          </IconButton>
        </Stack>
        <Stack spacing="8px">
          <Stack alignItems="center" direction="row" justifyContent="start" spacing="8px">
            <IconDocument
              fill={theme.colors.functional.text.link}
              sx={{
                height: '18px',
                width: '18px',
              }}
            />
            <Typography
              sx={{
                color: theme => theme.colors.functional.text.link,
              }}
              variant="captionC"
            >
              SAMPLE
            </Typography>
          </Stack>
          <Editor
            defaultLanguage="json"
            defaultValue={JSON.stringify(data, null, 2)}
            height="426px"
          />
        </Stack>
        <Button onClick={onClose} sx={{ minHeight: '68px' }} variant="raas">
          Save
        </Button>
      </Stack>
    </Dialog>
  );
};
