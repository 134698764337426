import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconDownload = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M10.6562 13.9998L10.7 13.9998V13.9995V13.9983V13.9964V13.9951V13.9937V13.986V13.9837V13.9811V13.9754V13.956L15.161 9.49497L14.171 8.50503L10.7 11.9761V9.24994V1.5H9.3V9.24994V11.9739L5.83111 8.50502L4.84116 9.49497L9.3 13.9538V13.9617V13.9754V13.9811V13.9837V13.986V13.9937V13.9951V13.9964V13.9983V13.9995V13.9998L9.34597 13.9998L9.5061 14.1599L10.0011 14.6549L10.496 14.1599L10.6562 13.9998ZM0.964844 18V7.00003H2.36484V17.3H17.6315V7.00003H19.0315V18V18.7H18.3315H1.66484H0.964844V18Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
