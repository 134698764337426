import { useBlockscoutMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';

export const TransactionsSuccessRateMetric = () => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.ROLLUP_STATS_TRANSACTIONS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'txnsSuccessRate',
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Successful transactions rate per day'}
      id={'txns-success-rate'}
      loading={isPending}
      title={'Transactions success rate'}
    />
  );
};
