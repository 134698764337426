import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconBinance = ({ ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_3011_3926)">
      <path
        d="M4.8366 3.06342L10.1439 0L15.4512 3.06342L13.5 4.19513L10.1439 2.26342L6.78782 4.19513L4.8366 3.06342ZM15.4512 6.92683L13.5 5.79513L10.1439 7.72683L6.78782 5.79513L4.8366 6.92683V9.19025L8.19269 11.1219V14.9853L10.1439 16.1171L12.0952 14.9853V11.1219L15.4512 9.19025V6.92683ZM15.4512 13.0537V10.7902L13.5 11.9219V14.1853L15.4512 13.0537ZM16.8366 13.8537L13.4805 15.7853V18.0487L18.7878 14.9853V8.8585L16.8366 9.99025V13.8537ZM14.8854 4.99512L16.8366 6.12682V8.39025L18.7878 7.25853V4.99512L16.8366 3.86342L14.8854 4.99512ZM8.19269 16.6049V18.8683L10.1439 20L12.0952 18.8683V16.6049L10.1439 17.7366L8.19269 16.6049ZM4.8366 13.0537L6.78782 14.1853V11.9219L4.8366 10.7902V13.0537ZM8.19269 4.99512L10.1439 6.12682L12.0952 4.99512L10.1439 3.86342L8.19269 4.99512ZM3.45122 6.12682L5.40244 4.99512L3.45122 3.86342L1.5 4.99512V7.25853L3.45122 8.39025V6.12682ZM3.45122 9.99025L1.5 8.8585V14.9853L6.80731 18.0487V15.7853L3.45122 13.8537V9.99025Z"
        fill="#F0B90B"
      />
    </g>
    <defs>
      <clipPath id="clip0_3011_3926">
        <rect fill="white" height="20" transform="translate(1.5)" width="17.2875" />
      </clipPath>
    </defs>
  </SvgIcon>
);
