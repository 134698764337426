import { useIsProduction } from 'hooks/ui';
import { PropsWithChildren } from 'react';
import { Navigate, To } from 'react-router-dom';
import { PATHS } from 'routes';

export const ProductionEnvGuard: React.FC<PropsWithChildren & { to?: To }> = ({
  children,
}: PropsWithChildren) => {
  const isProd = useIsProduction();

  return !isProd ? <>{children}</> : <Navigate replace to={PATHS.AVS_DEPLOYMENTS} />;
};
