import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface ILabelChip {
  children: ReactNode;
}

export const LabelChip = ({ children }: ILabelChip) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20px',
      background: '#E7E7E7',
      px: '6px',
      py: '1px',
    }}
  >
    <Typography variant="caption">{children}</Typography>
  </Box>
);
