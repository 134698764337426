import { Button, Grid, Stack, Typography } from '@mui/material';
import { IconChevronDown } from 'components/icons/IconChevronDown';
import { useRaasDeployment } from 'hooks';
import { Section } from 'pages/DeploymentDetails/Section';
import { StackRowFieldValue } from 'pages/DeploymentDetails/StackRowFieldValue';
import { isAddress, isHash, zeroHash } from 'viem';

export const VaultBalanceSection = () => {
  const { data: rollup } = useRaasDeployment();

  const fieldValueMap = [
    {
      field: 'Receipt',
      href: `${rollup?.explorer}/tx/${zeroHash}`,
      value: zeroHash,
    },
    {
      field: 'Vault balance',
      value: '1.46478930742 ETH',
    },
    {
      field: 'Missing receipt in L1',
      href: `${rollup?.explorer}/tx/${zeroHash}`,
      value: zeroHash,
    },
    {
      field: 'Missing receipt balance',
      value: '1.46478930742 ETH',
    },
    {
      field: 'Minimum withdrawal balance',
      value: '10 ETH',
    },
    {
      field: 'Target network',
      value: 'Sepolia',
    },
  ];

  return (
    <Section
      renderContent={
        <Stack spacing="8px">
          <Grid
            container
            sx={theme => ({
              background: '#fff',
              px: '40px',
              py: '24px',
              borderBottom: `1px solid ${theme.colors.functional.subject.border}`,
            })}
          >
            <Stack spacing="16px" width="100%">
              {fieldValueMap.map(({ field, href, value }) => (
                <StackRowFieldValue
                  copyable={!!href}
                  field={field}
                  href={href}
                  key={field}
                  showTooltip={isAddress(value) || isHash(value)}
                  textTruncateBackChars={isHash(value) ? 16 : isAddress(value) ? 6 : undefined}
                  textTruncateFrontChars={isHash(value) ? 16 : isAddress(value) ? 8 : undefined}
                  truncate={isAddress(value) || isHash(value)}
                  value={value}
                />
              ))}
            </Stack>
          </Grid>
        </Stack>
      }
      renderSubHeaderEndContent={
        <Button
          disableElevation
          sx={theme => ({
            '&, &:hover': {
              background: theme.colors.gradients.leaf,
              color: theme.colors.functional.text.primary,
            },
          })}
        >
          Withdraw Now
        </Button>
      }
      renderSubHeaderStartContent={
        <Button
          disableElevation
          sx={theme => ({
            '&, &:hover': {
              background: '#fff',
              color: theme.colors.functional.text.primary,
            },
          })}
        >
          <Stack direction="row" spacing="32px">
            <Typography variant="bodySmallM">L1 Fee Vault</Typography>
            <IconChevronDown />
          </Stack>
        </Button>
      }
      subHeaderSx={{ height: '100px' }}
    />
  );
};
