import { PageBanner } from 'components/Layout/PageBanner';
import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

export const RaasAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onBack = useCallback(() => navigate(PATHS.ADMIN_RAAS), [navigate]);

  return (
    <>
      <PageBanner
        onClickBack={location.pathname.includes('/raas/') ? onBack : undefined}
        title="Rollup Dashboard"
      />
      <Outlet />
    </>
  );
};
