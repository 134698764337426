import { Grid } from '@mui/material';
import { BASE_FEE_ADDR, L1_FEE_ADDR, SEQUENCER_FEE_ADDR } from 'constants/raas';
import { metrics, useBlockscoutCounters, useRaasDeployment } from 'hooks';
import { IUseBatchBalances, useBatchBalances } from 'hooks/viem/useBatchBalances';
import { Section } from 'pages/DeploymentDetails/Section';
import { WidgetMetric } from 'pages/DeploymentDetails/WidgetMetric';
import { formatUnits } from 'viem';

const accounts: IUseBatchBalances['accounts'] = [
  { label: 'Base Fee Balance', account: BASE_FEE_ADDR },
  { label: 'L1 Fee Balance', account: L1_FEE_ADDR },
  { label: 'Sequencer Fee Balance', account: SEQUENCER_FEE_ADDR },
];

export const OverviewSection = () => {
  const { data: rollup } = useRaasDeployment();
  const { data: result, isPending: getRollupStatisticsPending } = useBlockscoutCounters();
  const { data: balances, isPending: getBalancesPending } = useBatchBalances({
    accounts,
    rollup,
  });

  return (
    <Section
      renderContent={
        <Grid container>
          {!result || getRollupStatisticsPending
            ? metrics.map(metric => <WidgetMetric key={metric} loading tooltip="..." />)
            : result?.map(({ description, id, title, value }) => {
                return (
                  <WidgetMetric
                    key={id}
                    label={title}
                    tooltip={description}
                    value={Number(value ?? 0)}
                  />
                );
              })}
          {!balances || getBalancesPending
            ? accounts.map(v => (
                <WidgetMetric key={typeof v === 'string' ? v : v.account} loading tooltip="..." />
              ))
            : balances?.map(({ account, balance, label }) => {
                return (
                  <>
                    {balance && (
                      <WidgetMetric
                        key={account}
                        label={label}
                        tooltip={`Balance in ETH for account ${account}`}
                        value={+formatUnits(balance, rollup?.tokenMetadata?.decimals ?? 18)}
                        valueSuffix={rollup?.tokenMetadata?.symbol ?? 'ETH'}
                      />
                    )}
                  </>
                );
              })}
        </Grid>
      }
      title="Overview"
    />
  );
};
