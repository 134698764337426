import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableRowSkeleton } from 'components/Skeleton/TableRowSkeleton';
import { Pagination } from 'components/Table/Pagination';
import { useBlockscoutTokens, useRaasDeployment } from 'hooks';
import { useTablePagination } from 'hooks/ui';
import { Section } from 'pages/DeploymentDetails/Section';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { truncateEthAddress } from 'utils/address';
import { getAddress } from 'viem';

export const TokensSection = () => {
  const { data: rollup } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.ROLLUP_STATS_TOKENS_SECTION] || 'month',
  );
  const { data: blockscoutTokensResult, isPending } = useBlockscoutTokens({ l2: true, from, to });
  const { filteredData, paginationCount, setCurrentPage } = useTablePagination({
    data: blockscoutTokensResult?.items ?? [],
  });

  return (
    <Section
      renderContent={
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="captionC">Token Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="captionC">L2 Address</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="captionC">Type</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="captionC">Holders</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isPending
                ? Array.from({ length: 10 }).map((_, index) => (
                    <TableRowSkeleton cellCount={6} key={index.toString()} />
                  ))
                : filteredData?.map(({ address, holders, name, type }) => (
                    <TableRow key={[name, address, type].join('-')} sx={{ background: '#fff' }}>
                      <TableCell>
                        <Typography variant="bodySmall">{name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          component={'a'}
                          href={[rollup?.l2Blockscout, 'tokens', getAddress(address)].join('/')}
                          rel="noopener noreferrer"
                          sx={{ textDecoration: 'underline' }}
                          target="_blank"
                          variant="bodySmall"
                        >
                          {truncateEthAddress(getAddress(address))}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall">{type}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall">
                          {Number(holders).toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
            {filteredData && (
              <Pagination
                paginationOptions={{
                  count: paginationCount,
                  onChange: (_, page) => setCurrentPage(page),
                }}
                sx={{ pl: '40px', py: '24px' }}
              />
            )}
          </Table>
        </TableContainer>
      }
      title="Tokens"
    />
  );
};
