import { LoadingButton } from '@mui/lab';
import {
  Box,
  Drawer,
  DrawerProps,
  Grid,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useCreateApp, useListApps, useListAppTypes } from 'hooks/aaas';
import { useDisclosure } from 'hooks/ui';
import { useCallback, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { aAATypeFromJSON, APP, CreateAAAReply } from 'types/protoc-gen/aaas-bffavs';

import { TemplateCard, VerifyPromoCodeDialog } from './components';
import { SelectDeploymentDialog } from './SelectDeploymentDialog';

export interface IAgentStoreDrawer extends Omit<DrawerProps, 'children' | 'onClose'> {
  onClose: () => void;
  onAppDeployed?: (reply: CreateAAAReply) => void;
}

export const AgentStoreDrawer: React.FC<IAgentStoreDrawer> = ({
  onAppDeployed,
  onClose,
  open,
  ...drawerProps
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<APP>();

  const {
    onClose: onCloseVerifyPromoCodeDialog,
    onOpen: onOpenVerifyPromoCodeDialog,
    open: isOpenVerifyPromoCodeDialog,
  } = useDisclosure();
  const {
    onClose: onCloseSelectDeploymentDialog,
    onOpen: onOpenSelectDeploymentDialog,
    open: isOpenSelectDeploymentDialog,
  } = useDisclosure();

  const { refetch: refetchDeployedApps } = useListApps();
  const { data, isPending: getAppsPending } = useListAppTypes();
  const { isPending: deployAppsPending, mutateAsync: deployAppFromTemplate } = useCreateApp({
    onSuccess: async data => {
      await refetchDeployedApps();
      onAppDeployed?.(data);
    },
  });

  const handlePromoCodeVerified = useCallback(
    async (promoCode: string) => {
      await deployAppFromTemplate({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // TODO: remove ts-ignore when the weird typescript error is fixed
        app: aAATypeFromJSON(selectedTemplate?.typeId),
        name: `app`,
        promoCode,
      });
      onClose();
    },
    [deployAppFromTemplate, onClose, selectedTemplate?.typeId],
  );

  return (
    <>
      <Drawer
        anchor="bottom"
        onClose={onClose}
        open={open}
        {...drawerProps}
        sx={{
          height: '95vh',
          pb: '88px',
        }}
      >
        <Toolbar
          sx={{
            mb: '40px',
            pt: '40px',
            mx: '20px',
            alignItems: 'center',
            direction: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5">Agent Store</Typography>
          <IconButton aria-label="close-app-store-bottom-sheet" onClick={onClose}>
            <RxCross1 size="24px" />
          </IconButton>
        </Toolbar>

        <Grid
          container
          spacing="20px"
          sx={{
            mb: '64px',
            height: '80%',
            overflowY: 'auto',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'start',
            px: '40px',
          }}
        >
          {getAppsPending
            ? Array.from({ length: 12 }).map((_, index) => (
                <Grid item key={index} xs={4}>
                  <Skeleton
                    height="180px"
                    sx={{ borderRadius: 0, m: 0, p: 0 }}
                    variant="rectangular"
                  />
                </Grid>
              ))
            : data?.templates?.map(template => (
                <Grid item key={template.type} xs={4}>
                  <TemplateCard
                    onClick={() => {
                      if (selectedTemplate?.typeId === template.typeId) {
                        return setSelectedTemplate(undefined);
                      }

                      return setSelectedTemplate(template);
                    }}
                    selected={selectedTemplate?.typeId === template.typeId}
                    template={template}
                  />
                </Grid>
              ))}
        </Grid>

        <Box alignItems="center" display="flex" justifyContent="center">
          <LoadingButton
            disableElevation
            disabled={!selectedTemplate}
            loading={deployAppsPending}
            onClick={onOpenSelectDeploymentDialog}
            sx={{
              height: '68px',
              width: '378px',
              mb: '40px',
            }}
            variant="contained"
          >
            <Typography variant="bodySmall">Deploy</Typography>
          </LoadingButton>
        </Box>
      </Drawer>

      <VerifyPromoCodeDialog
        onClose={onCloseVerifyPromoCodeDialog}
        onPromoCodeVerified={handlePromoCodeVerified}
        open={isOpenVerifyPromoCodeDialog}
      />
      <SelectDeploymentDialog
        onClose={onCloseSelectDeploymentDialog}
        onConfirmed={deploymentType => {
          // TODO: handle deployment type selection
          console.log('deploymentType:', deploymentType);

          setTimeout(() => {
            onOpenVerifyPromoCodeDialog();
          }, 500);
        }}
        open={isOpenSelectDeploymentDialog}
      />
    </>
  );
};
