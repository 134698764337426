import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { truncateEthAddress } from 'utils/address';
import { zeroAddress } from 'viem';

export interface ITokenPairInfoMetric {}

const tokenPair = [
  {
    symbol: 'USDT',
    l1Address: zeroAddress,
    l2Address: zeroAddress,
  },
  {
    symbol: 'SIPHER',
    l1Address: zeroAddress,
    l2Address: zeroAddress,
  },
];

export const TokenPairInfoMetric: React.FC<ITokenPairInfoMetric> = () => {
  return (
    <>
      <Box sx={{ py: '24px', px: '40px' }}>
        <Typography variant="bodySmallM">Token Pair Info</Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="captionC">Symbol</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="captionC">L1 Address</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="captionC">L2 Address</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokenPair.map(pair => (
              <TableRow key={pair.symbol}>
                <TableCell>
                  <Typography variant="bodySmall">{pair.symbol}</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    variant="bodySmall"
                  >
                    {truncateEthAddress(pair.l1Address)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    variant="bodySmall"
                  >
                    {truncateEthAddress(pair.l2Address)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
