import { Editor, useMonaco } from '@monaco-editor/react';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, debounce, FormHelperText, Link, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { AutocompleteOption, CustomAutocomplete } from 'components/CustomAutocomplete';
import { Option } from 'components/Option';
import { WithInfo } from 'components/WithInfo';
import { ERR_MSG_COMPILE_REQUIRED } from 'constants/customAvs';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { AVS_TYPES } from 'types/avs';
import { AbiFunction } from 'viem';

import CustomArgs from './form-steps/CustomArgs';
import { AVSFormValues } from '.';

const pragmaRegex = /pragma\s+solidity\s*<?[>=^]{0,2}(\d+\.\d+\.\d+)/;

/**
 * @description necessary to use web worker because using a complicated regex to parse 8000+ lines of code takes up to 5 seconds
 */
const asyncParseCode = async (code: string): Promise<string> => {
  const worker = new Worker(new URL('./parse-worker.js', import.meta.url));

  if (!code) {
    console.error('No code to parse');
  }

  return new Promise((resolve, reject) => {
    worker.onmessage = e => {
      try {
        const data = JSON.parse(e?.data);

        console.debug('raw parse output: ', data);

        const contractName = data?.contractName;

        resolve(contractName);
      } catch (err: any) {
        reject(Error(err?.message || 'Error parsing code.'));
      } finally {
        worker.terminate();
      }
    };

    worker.onerror = err => {
      console.error('error in webworker: ', err);
      reject(Error(`Parse Error: ${err?.message}`));
      worker.terminate();
    };

    worker.postMessage({ code });
  });
};

const compile = async ({
  code,
  contractName,
  version,
}: {
  code: string;
  version: string;
  contractName?: string;
}): Promise<{
  bytecode: string;
  abi: any[];
  contractName?: string;
  methodIdentifiers?: string[];
  version: string;
}> => {
  const worker = new Worker(new URL('./compile-worker.js', import.meta.url));

  return new Promise((resolve, reject) => {
    worker.onmessage = e => {
      try {
        const data = JSON.parse(e?.data);

        console.debug('raw compile output: ', data);

        const errors = data?.errors?.filter((cur: any) => cur?.severity === 'error');

        if (errors?.length) {
          throw Error(errors?.[0]?.formattedMessage);
        }

        const contractName = data?.contractName;

        if (!contractName) {
          throw Error(
            'Unable to identify service manager contract. Please ensure that your contract inherits ServiceManagerBase or ECDSAServiceManagerBase.',
          );
        }

        const output = data?.contracts?.Flattened?.[contractName];

        resolve({
          abi: output?.abi,
          bytecode: '0x' + output?.evm?.bytecode?.object,
          contractName,
          methodIdentifiers: Object.keys(output?.evm?.methodIdentifiers || {}),
          version,
        });
      } catch (err: any) {
        reject(
          Error(err?.message || 'Error compiling contract. Please check your contract syntax.'),
        );
      } finally {
        worker.terminate();
      }
    };

    worker.onerror = err => {
      console.error('error in webworker: ', err);
      reject(Error(`Compilation Error: ${err?.message}`));
      worker.terminate();
    };

    worker.postMessage({ source: code, contractName, version });
  });
};

export default function CustomContractFields() {
  const { addAlert } = useAlerts();
  const { formState, getFieldState, register, setError, setValue, unregister } =
    useFormContext<AVSFormValues>();
  const compiledOutput = useWatch<AVSFormValues>({ name: 'compiledOutput' });
  const avsType = useWatch<AVSFormValues>({ name: 'avsType' });
  const aggregatorHandlerName = useWatch<AVSFormValues>({ name: 'aggregatorHandlerName' });
  const monaco = useMonaco();
  const editorRef = useRef<any>(null);

  const [viewFullContract, setViewFullContract] = useState(false);

  const [code, setCode] = useState('');
  const [contractName, setContractName] = useState('');

  const { isPending: isParsing, mutate: parseContractName } = useMutation({
    mutationFn: asyncParseCode,
    onSuccess: res => {
      if (res !== contractName) {
        setContractName(res);

        if (!viewFullContract) {
          hideContractDependencies(res);
        }
      }
    },
  });

  const hideContractDependencies = useCallback(
    (contractName: string) => {
      if (monaco) {
        const editor = editorRef.current;

        const lines = code?.split('\n');

        const start =
          lines?.findIndex(cur => new RegExp(`contract \\b${contractName}\\b`).test(cur)) || 0;

        console.debug('start on line: ', start);

        // autohide on paste/input
        editor.setHiddenAreas([new monaco.Range(4, 1, start, 1)]);
      }
    },
    [monaco, code],
  );

  const debouncedParseContractName = useMemo(
    () => debounce(parseContractName, 500),
    [parseContractName],
  );

  useEffect(() => {
    debouncedParseContractName(code);
  }, [code, debouncedParseContractName]);

  const [isFetchingCode, setIsFetchingCode] = useState(false);

  useEffect(() => {
    const fetchCode = async () => {
      if (code?.startsWith('http')) {
        try {
          setIsFetchingCode(true);
          const res = await axios.get(code);

          setCode(res?.data);
          setViewFullContract(true);
        } catch (err) {
          console.error(err);
          setCode(String(err) + '\n\nPlease ensure that you have pasted a public url');
        } finally {
          setIsFetchingCode(false);
        }
      }
    };

    fetchCode();
  }, [code]);

  useEffect(() => {
    if (viewFullContract) {
      const editor = editorRef.current;

      editor?.setHiddenAreas([]);
    }
  }, [viewFullContract]);

  const { isPending: isCompiling, mutate: compileContract } = useMutation({
    mutationFn: compile,
    onSuccess: (res, vars) => {
      console.debug('compiled output: ', res);
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: `Contract successfully compiled`,
        desc: `Solc version: ${vars?.version}`,
      });
      setValue('compiledOutput', res, { shouldValidate: true, shouldTouch: true });
    },
    onError: (err: Error) => {
      console.error('error: ', err);
      console.log('errmsg:', err?.message);
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to compile contract',
        desc: err?.message || err?.stack,
      });
    },
  });

  const hasAppendedEcdsaConstructorArgs =
    avsType === AVS_TYPES.CUSTOM_ECDSA &&
    compiledOutput?.abi?.find((cur: any) => cur?.type === 'constructor')?.inputs?.length > 3;

  const aggregatorHandlerOptions: AutocompleteOption[] = useMemo(() => {
    const functionNames = compiledOutput?.abi
      ?.filter((cur: AbiFunction) => cur?.type === 'function' && cur?.inputs?.length > 0) // aggregator handler function always accepts at least 1 arg
      ?.map((cur: AbiFunction) => cur?.name);

    return (
      compiledOutput?.methodIdentifiers?.reduce((acc: AutocompleteOption[], identifier: string) => {
        const methodHasArgs = identifier?.match(/^.+\(.+\)$/);

        if (!methodHasArgs) {
          return acc; // aggregator handler function always accepts at least 1 arg
        }

        const functionNameOfIdentifier = functionNames?.find((name: string) =>
          identifier?.includes(name),
        );

        if (functionNameOfIdentifier) {
          return [...acc, { label: identifier, value: functionNameOfIdentifier }];
        }

        return acc;
      }, [] as AutocompleteOption[]) || []
    );
  }, [compiledOutput]);

  return (
    <>
      <Typography id="step_customTemplate" variant="caption">
        {!code ? (
          <>
            Paste flattened contract or url here.{' '}
            <Link
              href="https://book.getfoundry.sh/reference/forge/forge-flatten"
              rel="noopener noreferrer"
              sx={{
                color: theme => theme.colors.functional.text.link,
                '&:hover': { textDecoration: 'underline' },
              }}
              target="_blank"
            >
              Show me how
            </Link>
          </>
        ) : viewFullContract ? (
          <>
            Showing full contract.{' '}
            <Typography
              onClick={() => {
                if (viewFullContract) {
                  hideContractDependencies(contractName);
                }

                setViewFullContract(prev => !prev);
              }}
              sx={{
                color: theme => theme.colors.functional.text.link,
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline' },
              }}
              variant="caption"
            >
              View {contractName} only
            </Typography>
          </>
        ) : (
          <>
            Showing main contract only.{' '}
            <Typography
              onClick={() => {
                setViewFullContract(prev => !prev);
              }}
              sx={{
                cursor: 'pointer',
                color: theme => theme.colors.functional.text.link,
                '&:hover': { textDecoration: 'underline' },
              }}
              variant="caption"
            >
              View full flattened contract
            </Typography>
          </>
        )}
      </Typography>
      <Box
        {...register('compiledOutput')}
        sx={{
          position: 'relative',
          border: formState.errors?.compiledOutput ? '3px solid #F1605F' : 'none',
        }}
      >
        {isFetchingCode && (
          <Typography
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 10,
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              transform: 'translate(-50%,-50%)',
            }}
          >
            <CircularProgress size={30} />
            Fetching contract code from {code}
          </Typography>
        )}
        <Editor
          height="600px"
          language="sol"
          onChange={input => {
            setCode(String(input));
          }}
          onMount={editor => {
            editorRef.current = editor;
          }}
          options={{
            selectOnLineNumbers: true,
            scrollBeyondLastLine: false,
            folding: true,
            showFoldingControls: 'always',
          }}
          theme="vs-dark"
          value={code}
        />
      </Box>
      {formState.errors?.compiledOutput?.abi?.message && !isCompiling && (
        <FormHelperText error sx={{ whiteSpace: 'pre', mt: 2 }}>
          {formState.errors?.compiledOutput?.abi?.message}
        </FormHelperText>
      )}
      <LoadingButton
        disabled={isParsing}
        loading={isCompiling}
        onClick={async () => {
          const fieldState = getFieldState('compiledOutput');

          if (fieldState?.isTouched && !fieldState?.invalid) {
            // If valid && touched, it means previous compilation was successful. Summary panel shows checkmark. Should show X when compile is clicked again
            setValue('compiledOutput', undefined, { shouldValidate: true, shouldTouch: true });
          }

          unregister('initArgs');
          unregister('constructorArgs');

          // Extract the version from the Solidity file
          const match = code.match(pragmaRegex);

          if (!match) {
            addAlert({ severity: ALERT_SEVERITY.ERROR, title: 'Invalid pragma version' });
            setError('compiledOutput.abi', { message: ERR_MSG_COMPILE_REQUIRED });

            return;
          }

          const pragmaVer = match?.[1];

          const res = await axios.get<string>('https://binaries.soliditylang.org/bin/list.txt');
          const versions = res?.data?.split('\n')?.filter(cur => !cur?.includes('nightly'));

          const version = versions?.find(cur => cur?.includes(`${pragmaVer}+commit`)) || '';

          if (!version) {
            addAlert({
              severity: ALERT_SEVERITY.ERROR,
              title: 'Failed to compile contract.',
              desc: `Could not find compiler version ${pragmaVer}. Please verify that your pragma statement is valid, or use a more specific version if necessary.`,
            });

            setError('compiledOutput.abi', { message: ERR_MSG_COMPILE_REQUIRED });

            return;
          }

          console.debug('compiler version: ', version);

          compileContract({ code, version, contractName });
        }}
        sx={{ my: 2 }}
        variant="contained"
      >
        {isParsing ? 'Parsing...' : 'Compile'}
      </LoadingButton>
      {!isCompiling &&
        compiledOutput?.abi &&
        (avsType === AVS_TYPES.CUSTOM_BLS || hasAppendedEcdsaConstructorArgs) && (
          <CustomArgs
            fieldArrName="constructorArgs"
            label={
              <Typography>
                <WithInfo
                  info="Arguments required for service manager's constructor"
                  text={<Typography variant="bodySmallC">CUSTOM CONSTRUCTOR ARGS</Typography>}
                />
              </Typography>
            }
          />
        )}
      {!isCompiling && compiledOutput?.abi && (
        <CustomArgs
          fieldArrName="initArgs"
          label={
            <Typography>
              <WithInfo
                info="Arguments required for service manager's initialize function"
                text={<Typography variant="bodySmallC">CUSTOM INITIALIZE ARGS</Typography>}
              />
            </Typography>
          }
          mt={8}
        />
      )}
      {compiledOutput?.abi && avsType === AVS_TYPES.CUSTOM_BLS && (
        <Option optionTitle="Aggregator Handler Function" sx={{ '&': { pl: 0 } }}>
          <CustomAutocomplete
            afterChange={selectedOption => {
              setValue(
                `aggregatorHandlerName`,
                { label: selectedOption?.label, value: String(selectedOption?.value) },
                {
                  shouldTouch: true,
                  shouldValidate: true,
                },
              );
            }}
            getOptionKey={option => option?.label}
            getOptionLabel={option => option?.label || ''}
            id={`step_${register('aggregatorHandlerName').name}`}
            options={aggregatorHandlerOptions}
            placeholder="Select the aggregator handler function"
            sx={{ width: '100%' }}
            value={aggregatorHandlerName}
          />
        </Option>
      )}
    </>
  );
}
