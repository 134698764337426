import { Formatter } from 'components/charts';
import { truncateEthAddress } from 'utils/address';
import { monthDateFormat } from 'utils/dates';
import { formatAbbreviatedNumber } from 'utils/strings';
import { formatEther, formatGwei } from 'viem';

export const largeNumberYAxisTickFormatter: Formatter = (value: any) =>
  formatAbbreviatedNumber(value) ?? '';

export const monthDateTickFormatter: Formatter = (value: any) =>
  value ? monthDateFormat(value) : '';

export const blockTimestampToMonthDateTickFormatter: Formatter = (value: any) => {
  const date = new Date(value * 1_000);

  return `${date.getMonth() + 1}/${date.getDate()}`;
};

export const gweiFormatter: Formatter = value =>
  `${formatAbbreviatedNumber(+Number(formatGwei(value)).toFixed(5))} Gwei`;

export const ethFormatter: Formatter = value =>
  `${formatAbbreviatedNumber(+Number(formatEther(value)).toFixed(5))} ETH`;

export const addressFormatter: Formatter = value => truncateEthAddress(value);
