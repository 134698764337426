import { useRaasDeployment } from 'hooks';
import { MetricGridItem } from 'pages/DeploymentDetails/MetricGridItem';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionButtonTimeFilter } from 'pages/DeploymentDetails/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/DeploymentDetails/useTimeFilterStore';
import { TxPoolMetric } from 'pages/DeploymentDetails/views/OverviewView/TxPoolMetric';

import { CPUMetric } from './CPUMetric';
import { MemoryMetric } from './MemoryMetric';

export const BlockchainStatisticsView = () => {
  const { isPending } = useRaasDeployment();

  return (
    <>
      <Section
        renderGridContent={
          <>
            <MetricGridItem item>
              <TxPoolMetric
                timeFilterVariant={TimeFilterVariant.BLOCKCHAIN_STATS_GENERAL_SECTION}
              />
            </MetricGridItem>
            <MetricGridItem item>
              <CPUMetric />
            </MetricGridItem>
            <MetricGridItem item>
              <MemoryMetric />
            </MetricGridItem>
          </>
        }
        renderSubHeaderEndContent={
          <SectionButtonTimeFilter
            loading={isPending}
            variant={TimeFilterVariant.BLOCKCHAIN_STATS_GENERAL_SECTION}
          />
        }
        sectionContentSx={{ p: 0 }}
        title="Blockchain Statistics"
      />
    </>
  );
};
