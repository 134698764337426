import { Stack, Typography } from '@mui/material';
import { DeploymentCard } from 'components/Deployment';
import { avsStatusLabelMap, avsTypeLabels } from 'labels/deployments';
import { useNavigate } from 'react-router-dom';
import { AVS_TYPES } from 'types/avs';
import { AVSDeployment, GetAVSStatusByNameResponse } from 'types/protoc-gen/avs';

export const AvsDeploymentCard = ({
  aggregator,
  avsDeployment,
}: {
  avsDeployment: AVSDeployment;
  aggregator?: GetAVSStatusByNameResponse;
}) => {
  const navigate = useNavigate();

  return (
    <DeploymentCard
      onClickHeader={() => navigate(`/avs/${avsDeployment.deploymentId}`)}
      title={avsDeployment.avsName}
      titleStartAdornment={
        avsStatusLabelMap[
          String(
            [AVS_TYPES.ECDSA_HELLO_WORLD, AVS_TYPES.CUSTOM_ECDSA]?.includes(avsDeployment?.avsType)
              ? 'running'
              : aggregator?.status,
          )
        ]
      }
    >
      <Stack direction="row" py={3}>
        <Stack flex={1}>
          <Typography variant="caption">Type</Typography>
          <Typography variant="bodySmallM">
            {avsTypeLabels[avsDeployment.avsType as AVS_TYPES]}
          </Typography>
        </Stack>

        {/* 
          <Stack flex={1}>
            <Typography variant="caption">Framework</Typography>
            <Typography variant="bodySmallM">To Do</Typography>
          </Stack> 
        */}
      </Stack>
    </DeploymentCard>
  );
};
