import { useState } from 'react';

export const useMenuDisclosure = <T extends HTMLElement>() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const onOpen = (event: React.MouseEvent<T>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    setAnchorEl,
    open,
    onOpen,
    onClose,
  };
};
