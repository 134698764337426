import { useRaasMetrics } from 'hooks/raas';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { useParams } from 'react-router-dom';
import { Panel } from 'types/protoc-gen/raas-metrics';

export const L2UnsafeMetric = () => {
  const { rollupId } = useParams();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end, start, step } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.L2_NODES_SECTION] || 'month',
  );
  const { data: result, isPending } = useRaasMetrics({
    rollupId,
    panel: Panel.PANEL_OPSTACK_L2_UNSAFE,
    start,
    end,
    step,
  });

  return (
    <Metric
      chartType="line"
      curveType="step"
      data={result}
      id={'l2-unsafe'}
      legendDirection={'column'}
      legendSpacing={'4px'}
      loading={isPending}
      title={'L2 unsafe'}
    />
  );
};
