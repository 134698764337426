import { SvgIcon, SvgIconProps } from '@mui/material';

const IconAvail = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_2077_989)" id="avail_logo_icon3x-1 1">
      <path
        d="M13.7425 17.5453L20.6017 20.7968C20.9531 20.9633 21.3737 20.8144 21.528 20.4575C23.1312 16.7485 24.1259 11.5281 22.3467 7.51218C20.671 3.72977 16.5268 2.70222 15.0597 2.91718L12.3682 4.37509C13.3868 4.56199 15.4408 5.83299 15.5081 9.42166C15.5637 12.3913 14.3691 15.0026 13.4524 16.4294C13.2051 16.8143 13.3291 17.3493 13.7425 17.5453Z"
        fill="#2B80D7"
        id="Vector"
      />
      <g id="Mask group">
        <mask height="19" id="mask0_2077_989" maskUnits="userSpaceOnUse" width="12" x="12" y="2">
          <g id="Group">
            <path
              d="M13.7435 17.5456L20.6027 20.797C20.9541 20.9636 21.3747 20.8147 21.529 20.4577C23.1322 16.7488 24.1269 11.5283 22.3477 7.51241C20.672 3.73002 16.5278 2.70247 15.0607 2.91742L12.3691 4.37533C13.3877 4.56224 15.4418 5.83321 15.509 9.42189C15.5647 12.3916 14.3701 15.0029 13.4533 16.4297C13.206 16.8146 13.3301 17.3496 13.7435 17.5456Z"
              fill="#DEA00D"
              id="Vector_2"
            />
          </g>
        </mask>
        <g mask="url(#mask0_2077_989)">
          <g id="Group_2">
            <g filter="url(#filter0_f_2077_989)" id="Group_3">
              <path
                d="M15.841 18.6599C19.4548 16.0561 24.2974 5.44438 21.8681 3.34626C20.9749 2.57482 12.823 2.7573 12.0947 2.18103L12.6883 3.06465C12.6883 3.06465 15.0749 6.054 14.9187 10.7483C14.8228 13.6313 13.7869 16.6117 13.1433 18.0686C13.038 18.3068 8.25614 15.9473 8.16632 16.1303C7.91087 16.6507 12.6002 18.9736 12.6002 18.9736L15.6044 18.7437C15.6905 18.7371 15.7709 18.7104 15.841 18.6599Z"
                fill="url(#paint0_linear_2077_989)"
                id="Vector_3"
              />
            </g>
            <g filter="url(#filter1_f_2077_989)" id="Group_4">
              <path
                d="M21.6119 21.4949C25.1462 18.8359 28.4267 10.938 25.96 6.52497C25.3841 5.49475 21.0721 2.54672 20.3438 1.97046L20.1174 4.90694C20.1174 4.90694 22.7788 7.47104 22.6223 12.1743C22.4996 15.8626 21.4589 18.6744 21.0154 19.7162C20.9084 19.9678 20.6219 20.0781 20.3684 19.9756C19.1707 19.4912 15.9937 18.1807 16.1049 17.9542C15.8494 18.4746 20.4956 21.1981 20.4956 21.1981L21.1048 21.5264C21.2673 21.614 21.4645 21.6059 21.6119 21.4949Z"
                fill="url(#paint1_linear_2077_989)"
                id="Vector_4"
              />
            </g>
            <g filter="url(#filter2_f_2077_989)" id="Group_5" opacity="0.8">
              <path
                d="M19.9398 13.7611L14.1391 11.854C13.4005 11.6112 12.6049 12.0131 12.3621 12.7518L10.9094 17.1713C10.6666 17.91 11.0685 18.7056 11.8071 18.9484L17.6078 20.8554C18.3464 21.0983 19.142 20.6963 19.3848 19.9577L20.8375 15.5381C21.0803 14.7995 20.6784 14.0039 19.9398 13.7611Z"
                fill="#13497E"
                id="Vector_5"
              />
            </g>
            <g filter="url(#filter3_f_2077_989)" id="Group_6" opacity="0.7">
              <path
                d="M13.5021 15.8015C16.3683 13.8001 16.8063 7.41954 14.3256 4.95744C13.6862 4.32278 12.7318 3.64145 12.1164 3.21661L12.618 3.86804C12.618 3.86804 14.6349 6.07188 14.5029 9.53267C14.4218 11.6581 13.5464 13.8552 13.0025 14.9294C12.9136 15.1049 8.87252 13.3654 8.7966 13.5004C8.58073 13.884 12.5436 15.5965 12.5436 15.5965L13.0446 15.832C13.193 15.9018 13.3677 15.8954 13.5021 15.8015Z"
                fill="#2B80D7"
                id="Vector_6"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        d="M18.7941 14.7367L12.499 12.4584C12.3038 12.3877 12.0905 12.4999 12.0301 12.6986C11.5926 14.1365 10.4451 16.7326 8.21653 17.7053C5.28578 18.9846 4.32151 16.1226 5.61109 19.7393C6.90071 23.356 11.162 24.3934 15.0588 22.1785C17.8836 20.5729 18.9729 17.2961 19.236 15.4435C19.2801 15.133 19.0891 14.8435 18.7941 14.7367Z"
        fill="url(#paint2_linear_2077_989)"
        id="Vector_7"
      />
      <g id="Mask group_2">
        <mask height="12" id="mask1_2077_989" maskUnits="userSpaceOnUse" width="15" x="5" y="12">
          <g id="Group_7">
            <path
              d="M18.7951 14.7372L12.5 12.4589C12.3047 12.3882 12.0915 12.5004 12.031 12.699C11.5936 14.137 10.4461 16.733 8.21751 17.7058C5.28677 18.9851 4.32248 16.123 5.61208 19.7398C6.90165 23.3565 11.1629 24.3938 15.0597 22.1789C17.8845 20.5734 18.9739 17.2966 19.2369 15.444C19.2811 15.1335 19.09 14.844 18.7951 14.7372Z"
              fill="url(#paint3_radial_2077_989)"
              id="Vector_8"
            />
          </g>
        </mask>
        <g mask="url(#mask1_2077_989)">
          <g id="Group_8">
            <g filter="url(#filter4_f_2077_989)" id="Group_9">
              <path
                d="M8.97394 24.3094C15.084 24.3094 17.6398 13.283 16.9284 11.4107C16.8901 11.31 16.7972 11.2462 16.6943 11.2139L5.34263 7.65314L12.2501 10.5019C12.3264 10.5333 12.3705 10.6125 12.354 10.6933C12.221 11.3436 11.5691 14.1508 9.91829 15.9324C8.04159 17.9577 4.1654 18.4122 2.56645 18.0345C1.71501 18.0332 1.23438 17.7419 1.23438 17.7419C3.17062 19 5.95122 24.3094 8.97394 24.3094Z"
                fill="url(#paint4_linear_2077_989)"
                id="Vector_9"
              />
            </g>
            <g filter="url(#filter5_f_2077_989)" id="Group_10" opacity="0.4">
              <path
                d="M9.67777 17.3497C12.1605 15.6675 14.0122 9.29541 13.5172 7.52062C13.4725 7.36055 13.3177 7.27284 13.1517 7.26544L6.50075 6.96826L12.2897 9.8143C12.3583 9.84799 12.3974 9.92026 12.3846 9.99559C12.2781 10.6207 11.7333 13.454 10.3391 15.2475C8.76479 17.2729 5.5132 17.7273 4.17188 17.3497C3.45762 17.3483 3.08887 17.6376 3.08887 17.6376C4.71312 18.8957 7.47332 18.8434 9.67777 17.3497Z"
                fill="#3EBADD"
                id="Vector_10"
              />
            </g>
            <g filter="url(#filter6_f_2077_989)" id="Group_11">
              <path
                d="M15.2548 22.7224C18.2446 21.0231 20.8717 14.2015 20.2511 12.4767C20.2039 12.3457 20.0748 12.2748 19.9366 12.2581L12.0158 11.3022L11.836 12.4642L18.0913 14.9068C18.2958 14.9867 18.428 15.1843 18.4011 15.4022C18.302 16.2037 17.8782 18.2189 16.069 20.2777C13.7355 22.9332 10.3621 22.8074 8.76317 22.4298C7.91169 22.4284 7.70215 22.4298 7.70215 22.4298C9.52413 23.7245 12.6269 24.2161 15.2548 22.7224Z"
                fill="url(#paint5_linear_2077_989)"
                id="Vector_11"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        d="M9.2842 11.5796C7.37787 15.1665 5.53313 22.5116 11.3644 23.3247C9.08395 23.5001 3.90119 22.3289 1.54627 16.7664C-1.39736 9.81334 4.3781 1.4584 10.8261 0.701412C17.1297 -0.0386317 20.3789 3.40141 22.25 7.30177C22.2981 7.39452 22.3402 7.48447 22.3764 7.57038C22.335 7.48064 22.2928 7.39108 22.25 7.30177C21.53 5.91123 19.4817 3.8836 16.0125 5.18727C13.5031 6.13028 10.7508 8.82004 9.2842 11.5796Z"
        fill="#2CBBF9"
        id="Vector_12"
      />
      <g id="Mask group_3">
        <mask height="24" id="mask2_2077_989" maskUnits="userSpaceOnUse" width="23" x="0" y="0">
          <g id="Group_12">
            <path
              d="M9.28323 11.5792C7.37685 15.1661 5.53212 22.5112 11.3634 23.3243C9.08298 23.4997 3.90021 22.3285 1.5453 16.766C-1.39834 9.81289 4.37712 1.45797 10.8251 0.700985C17.1287 -0.0390611 20.3779 3.40098 22.249 7.30137C22.297 7.39412 22.3392 7.48403 22.3754 7.56994C22.334 7.4802 22.2919 7.39063 22.249 7.30137C21.529 5.91083 19.4806 3.88317 16.0116 5.18682C13.5021 6.12984 10.7499 8.81963 9.28323 11.5792Z"
              fill="#00CCC0"
              id="Vector_13"
            />
          </g>
        </mask>
        <g mask="url(#mask2_2077_989)">
          <g id="Group_13">
            <g filter="url(#filter7_f_2077_989)" id="Group_14">
              <path
                d="M-4.15032 16.6116C-1.79542 22.1741 8.91567 23.8398 11.1961 23.6644C11.3105 22.5853 8.03856 20.2425 8.15268 17.6356C8.15268 17.6356 3.98037 17.7692 3.34327 11.5789C2.70618 5.38852 6.25676 2.27313 6.25676 2.27313C2.02366 4.35997 -5.42428 13.6024 -4.15032 16.6116Z"
                fill="url(#paint6_linear_2077_989)"
                id="Vector_14"
              />
              <path
                d="M22.3763 7.57075C33.3682 -1.30166 14.773 -3.34981 8.32498 -2.59282C5.64073 -2.2777 -1.42015 4.81942 1.35928 7.87356C1.35928 7.87356 4.24161 4.68037 8.32498 4.09699C12.4083 3.51362 14.2992 6.45733 14.2992 6.45733C14.7967 6.15216 16.1049 6.0681 16.5952 5.88385C20.2958 4.49322 22.1955 7.18956 22.3763 7.57075Z"
                fill="url(#paint7_linear_2077_989)"
                id="Vector_15"
              />
            </g>
            <g filter="url(#filter8_f_2077_989)" id="Group_15">
              <path
                d="M1.8136 17.4826C-4.74902 15.41 -1.59045 1.33417 4.99376 -0.680386C10.9179 -2.49299 11.4061 0.355108 12.4482 0.52863C12.5835 0.52445 12.6568 0.52713 12.6568 0.52713C12.584 0.539886 12.515 0.539743 12.4482 0.52863C11.6654 0.552816 8.80816 0.806713 6.08339 3.13895C1.98535 6.64659 0.335368 12.2543 1.8136 17.4826Z"
                fill="#56E5FF"
                id="Vector_16"
              />
            </g>
            <g filter="url(#filter9_f_2077_989)" id="Group_16">
              <path
                d="M12.4252 23.3129C10.6015 25.3659 3.13674 22.8695 0.781833 17.307C0.357855 16.3056 0.818836 13.2332 0.788204 12.0391C0.782866 11.9495 0.781833 11.9016 0.781833 11.9016C0.784852 11.944 0.786944 11.99 0.788204 12.0391C0.827816 12.7039 1.10451 15.6626 2.95542 18.3363C5.05572 21.3702 9.36978 22.5585 9.36978 22.5585C9.93272 22.959 11.5229 23.1871 12.4252 23.3129Z"
                fill="#FDC7C7"
                id="Vector_17"
              />
            </g>
            <g id="Mask group_4">
              <mask
                height="15"
                id="mask3_2077_989"
                maskUnits="userSpaceOnUse"
                width="15"
                x="-3"
                y="11"
              >
                <g id="Group_17">
                  <path
                    d="M11.743 21.8851L1.32227 11.1427L-2.4494 14.802L7.97137 25.5444L11.743 21.8851Z"
                    fill="url(#paint8_linear_2077_989)"
                    id="Vector_18"
                  />
                </g>
              </mask>
              <g mask="url(#mask3_2077_989)">
                <g id="Group_18">
                  <path
                    d="M12.4271 23.3126C10.6034 25.3656 3.1387 22.8692 0.783786 17.3067C0.359808 16.3053 0.820789 13.2329 0.790157 12.0388C0.78482 11.9492 0.783786 11.9012 0.783786 11.9012C0.786805 11.9437 0.788897 11.9896 0.790157 12.0388C0.829769 12.7037 1.10646 15.6623 2.95737 18.336C5.05765 21.3699 9.37176 22.5583 9.37176 22.5583C9.93466 22.9587 11.5249 23.1868 12.4271 23.3126Z"
                    fill="#FDC7C7"
                    id="Vector_19"
                  />
                </g>
              </g>
            </g>
            <g filter="url(#filter10_f_2077_989)" id="Group_19">
              <path
                d="M22.8414 7.86275C23.3216 4.37285 17.0953 -1.03532 10.6473 -0.278333C8.31443 -0.00445321 6.24741 2.24337 4.47168 4.08014C4.47168 4.08014 8.5016 0.49877 13.4076 1.18775C18.3136 1.87673 20.5057 5.50953 20.5057 5.50953C21.5301 6.10861 21.9976 7.94969 22.8414 7.86275Z"
                fill="#EFC7FD"
                id="Vector_20"
              />
            </g>
            <g id="Mask group_5">
              <mask
                height="9"
                id="mask4_2077_989"
                maskUnits="userSpaceOnUse"
                width="20"
                x="3"
                y="-1"
              >
                <g id="Group_20">
                  <path
                    d="M22.1942 1.67442L4.59375 -0.942444L3.71505 4.96835L21.3155 7.58522L22.1942 1.67442Z"
                    fill="url(#paint9_linear_2077_989)"
                    id="Vector_21"
                  />
                </g>
              </mask>
              <g mask="url(#mask4_2077_989)">
                <g id="Group_21">
                  <path
                    d="M22.8414 7.86347C23.3216 4.37358 17.0953 -1.03459 10.6473 -0.2776C8.31443 -0.00372079 6.24741 2.24411 4.47168 4.08087C4.47168 4.08087 8.5016 0.499503 13.4076 1.18848C18.3136 1.87746 20.5057 5.51025 20.5057 5.51025C21.5301 6.10937 21.9976 7.95045 22.8414 7.86347Z"
                    fill="#EFC7FD"
                    id="Vector_22"
                  />
                </g>
              </g>
            </g>
            <g filter="url(#filter11_f_2077_989)" id="Group_22">
              <path
                d="M15.378 4.31023C8.77275 6.3933 2.92177 18.5517 8.60704 23.1124C14.2923 27.6731 20.9386 19.0097 24.2823 11.9928C22.628 7.27717 21.9832 2.22714 15.378 4.31023Z"
                fill="url(#paint10_linear_2077_989)"
                id="Vector_23"
              />
            </g>
            <g id="Mask group_6">
              <mask
                height="30"
                id="mask5_2077_989"
                maskUnits="userSpaceOnUse"
                width="30"
                x="-3"
                y="-3"
              >
                <g id="Group_23">
                  <path
                    d="M26.0727 7.23771L9.59863 -2.73254L-2.29438 16.9213L14.1796 26.8915L26.0727 7.23771Z"
                    fill="url(#paint11_linear_2077_989)"
                    id="Vector_24"
                  />
                </g>
              </mask>
              <g mask="url(#mask5_2077_989)">
                <g id="Group_24">
                  <path
                    d="M15.377 4.31005C8.7718 6.39314 2.9208 18.5515 8.60605 23.1122C14.2913 27.6729 20.9376 19.0095 24.2813 11.9926C22.627 7.27696 21.9822 2.22697 15.377 4.31005Z"
                    fill="url(#paint12_linear_2077_989)"
                    id="Vector_25"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="34.3901"
        id="filter0_f_2077_989"
        width="31.9647"
        x="-0.642514"
        y="-6.61773"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="4.39938" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="20.202"
        id="filter1_f_2077_989"
        width="11.3112"
        x="15.8014"
        y="1.67717"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="0.146646" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="25.5673"
        id="filter2_f_2077_989"
        width="26.4937"
        x="2.62668"
        y="3.57108"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="4.10609" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="15.0086"
        id="filter3_f_2077_989"
        width="9.50454"
        x="7.61492"
        y="2.04345"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="0.586584" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="28.388"
        id="filter4_f_2077_989"
        width="27.5413"
        x="-4.63148"
        y="1.78728"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="2.93293" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="13.9076"
        id="filter5_f_2077_989"
        width="12.8551"
        x="1.9157"
        y="5.79509"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="0.586584" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="12.9264"
        id="filter6_f_2077_989"
        width="13.2272"
        x="7.40886"
        y="11.009"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="0.146646" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="45.1847"
        id="filter7_f_2077_989"
        width="48.8449"
        x="-13.6803"
        y="-12.1227"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="4.69267" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="21.1104"
        id="filter8_f_2077_989"
        width="16.8063"
        x="-2.9759"
        y="-2.45466"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="0.586584" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="14.4706"
        id="filter9_f_2077_989"
        width="14.1696"
        x="-0.571605"
        y="10.7284"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="0.586584" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="10.5621"
        id="filter10_f_2077_989"
        width="20.7418"
        x="3.29851"
        y="-1.5232"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="0.586584" />
      </filter>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="21.1412"
        id="filter11_f_2077_989"
        width="18.5612"
        x="6.01433"
        y="3.53428"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_2077_989" stdDeviation="0.146646" />
      </filter>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2077_989"
        x1="17.1482"
        x2="17.0366"
        y1="5.01879"
        y2="19.0681"
      >
        <stop stopColor="#13497E" />
        <stop offset="0.640419" stopColor="#2B80D7" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_2077_989"
        x1="21.4568"
        x2="21.4622"
        y1="6.05189"
        y2="20.6486"
      >
        <stop stopColor="#E2C7FE" />
        <stop offset="0.450702" stopColor="#77B8FD" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_2077_989"
        x1="15.6176"
        x2="8.08588"
        y1="9.80231"
        y2="22.4757"
      >
        <stop stopColor="#269EBF" />
        <stop offset="1" stopColor="#3EBADD" />
      </linearGradient>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(16.6457 14.0382) rotate(139.707) scale(15.9841 11.7781)"
        gradientUnits="userSpaceOnUse"
        id="paint3_radial_2077_989"
        r="1"
      >
        <stop offset="0.411461" stopColor="#FF7360" />
        <stop offset="1" stopColor="#630513" />
      </radialGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_2077_989"
        x1="14.0923"
        x2="7.89795"
        y1="13.0936"
        y2="21.9337"
      >
        <stop stopColor="#33B6D2" />
        <stop offset="0.142991" stopColor="#5BD8FF" />
        <stop offset="1" stopColor="#228AA7" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint5_linear_2077_989"
        x1="16.1177"
        x2="10.8032"
        y1="14.0663"
        y2="22.9078"
      >
        <stop offset="0.635417" stopColor="#68DEFF" />
        <stop offset="1" stopColor="#F3C8CB" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_2077_989"
        x1="1.5968"
        x2="7.45367"
        y1="8.09295"
        y2="22.5866"
      >
        <stop offset="0.661495" stopColor="#88FFF8" />
        <stop offset="1" stopColor="#176781" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint7_linear_2077_989"
        x1="4.70929"
        x2="20.9819"
        y1="8.98688"
        y2="2.2405"
      >
        <stop offset="0.731633" stopColor="#4D89FD" />
        <stop offset="1" stopColor="#033883" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint8_linear_2077_989"
        x1="9.43833"
        x2="2.67596"
        y1="23.182"
        y2="18.2911"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="0.658857" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint9_linear_2077_989"
        x1="21.5209"
        x2="13.834"
        y1="6.12234"
        y2="1.05262"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint10_linear_2077_989"
        x1="19.5773"
        x2="8.62807"
        y1="4.26117"
        y2="22.8072"
      >
        <stop stopColor="#EFC7FE" />
        <stop offset="0.286458" stopColor="#2CBBF9" />
        <stop offset="0.65625" stopColor="#2CBBF9" />
        <stop offset="1" stopColor="#FDC8C8" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint11_linear_2077_989"
        x1="20.1455"
        x2="6.60567"
        y1="16.2259"
        y2="7.63676"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="0.659264" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint12_linear_2077_989"
        x1="19.5764"
        x2="8.62708"
        y1="4.261"
        y2="22.8071"
      >
        <stop stopColor="#EFC7FE" />
        <stop offset="0.317708" stopColor="#2CBBF9" />
        <stop offset="0.661458" stopColor="#2CBBF9" />
        <stop offset="1" stopColor="#FDC8C8" />
      </linearGradient>
      <clipPath id="clip0_2077_989">
        <rect fill="white" height="22.8" transform="translate(0.599609 0.599976)" width="22.8" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconAvail;
