import {
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IconCirclePlus } from 'components/icons/IconCirclePlus';
import dayjs from 'dayjs';
import { useRaasDeployments } from 'hooks';
import { useDisclosure } from 'hooks/ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Rollup } from 'types/protoc-gen/raas-rollup';

import { EditDialog } from './EditDialog';

export const RaasAdminDashboard = () => {
  const navigate = useNavigate();
  const {
    onClose: onCloseEditDialog,
    onOpen: onOpenEditDialog,
    open: openedEditDialog,
  } = useDisclosure();
  const { data: rollups } = useRaasDeployments();

  const [rollup, setRollup] = useState<Rollup | undefined>(undefined);

  return (
    <>
      <Grid container sx={{ background: '#fff', minHeight: '534px', mb: '164px' }}>
        <Grid
          item
          sx={{
            pb: '300px',
          }}
          xs={12}
        >
          <Stack
            direction="row"
            spacing={0}
            sx={{
              pt: '24px',
              '& > .MuiButton-root': {
                minHeight: '68px',
                borderWidth: '1px 1px 1px 0px',
                borderStyle: 'solid',
                borderColor: '#20272314',
              },
            }}
          >
            <Button variant="raas">
              <Typography variant="bodySmallM">OP Stack</Typography>
            </Button>
            <Button variant="metal">
              <Typography variant="bodySmallM">Arbitrum Orbit</Typography>
            </Button>
          </Stack>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    'Chain Name',
                    'Chain ID',
                    'Env',
                    'Start Date',
                    'End Date',
                    'L1',
                    'Symbol',
                    // 'DA',
                    // 'Assets',
                    // 'Metadata',
                    // 'Version',
                    '',
                  ].map(header => (
                    <TableCell key={header}>
                      <Typography variant="captionC">{header.toUpperCase()}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rollups?.map((rollup, idx) => (
                  <TableRow
                    key={idx.toString()}
                    sx={{
                      '& .MuiTableCell-root': { pl: '40px', py: '24px' },
                      '& .MuiTableCell-root:last-of-type': { pl: 0, px: 0, py: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography
                        onClick={() => navigate(rollup.id)}
                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                        variant="captionM"
                      >
                        {rollup.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="captionM">{rollup?.chainId}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="captionM">{rollup?.environment}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="captionM">
                        {dayjs(rollup?.startupAt).format('DD/MM/YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="captionM">{`-`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="captionM">{rollup?.settlementLayer}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="captionM">{rollup?.tokenMetadata?.symbol}</Typography>
                    </TableCell>
                    {/* 
                    // TODO: remove or uncomment this block later when admin dashboard design is finalized
                    <TableCell>
                      <Stack spacing="8px">
                        {Object.entries(rollup?.dataAvailabilityMetadata ?? {}).map(
                          ([key, value]) => (
                            <TypographyKeyValue key={key} label={key} value={value} />
                          ),
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing="8px">
                        {Object.entries({
                          RPC: rollup?.rpcEndpoint,
                          WSS: rollup?.rpcWsEndpoint,
                          Explorer: rollup?.explorer,
                          Bridge: rollup?.bridge,
                        }).map(([key, value]) => (
                          <TypographyKeyValue
                            copyable={key === 'RPC' || key === 'WSS'}
                            key={key}
                            label={key}
                            value={value}
                          />
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing="8px">
                        {Object.entries(rollup?.metadata ?? {}).map(([key, value]) => (
                          <TypographyKeyValue copyable key={key} label={key} value={value} />
                        ))}
                        <TypographyKeyValue
                          label={'contract addrs'}
                          previewable
                          value={JSON.stringify(rollup?.contractAddresses, null, 2)}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing="8px">
                        {Object.entries(rollup?.version ?? {}).map(([key, value]) => (
                          <TypographyKeyValue copyable key={key} label={key} value={value} />
                        ))}
                      </Stack>
                    </TableCell> 
                    */}
                    <TableCell>
                      <Stack
                        spacing="0px"
                        sx={{
                          '& > .MuiButton-root': {
                            height: '100%',
                            minHeight: '64px',
                            borderWidth: '0px 1px 0px 1px',
                            borderStyle: 'solid',
                            borderColor: '#20272308',
                            '&:hover': {
                              background: '#fafafa',
                            },
                          },
                          '& > .MuiButton-root:first-of-type': {
                            borderWidth: '0px 1px 1px 1px',
                          },
                        }}
                      >
                        {[
                          { label: 'View', onClick: () => navigate(rollup.id) },
                          {
                            label: 'Edit',
                            onClick: () => {
                              onOpenEditDialog();
                              setRollup(rollup);
                            },
                          },
                        ].map(v => (
                          <Button key={v.label} onClick={v.onClick} variant="sheet">
                            <Typography
                              sx={{ color: theme => theme.colors.functional.text.link }}
                              variant="captionC"
                            >
                              {v.label}
                            </Typography>
                          </Button>
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow
                  sx={{
                    '& .MuiTableCell-root': { p: 0 },
                  }}
                >
                  <TableCell colSpan={12}>
                    <Button
                      sx={{
                        minHeight: '72px',
                        width: '100%',
                        background: '#fafafa',
                        color: theme => theme.colors.functional.text.primary,
                        border: 0,
                        '&:hover': {
                          background: '#f7f7f7',
                          color: theme => theme.colors.functional.text.primary,
                        },
                      }}
                      variant="text"
                    >
                      <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                        spacing="8px"
                      >
                        <IconCirclePlus />
                        <Typography variant="body1">Add New</Typography>
                      </Stack>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <EditDialog data={rollup} onClose={onCloseEditDialog} open={openedEditDialog} />
    </>
  );
};
