import { Button, ButtonProps, Stack } from '@mui/material';
import { IconUserGuide } from 'components/icons/IconUserGuide';

export const UserGuideButton = ({ ...props }: ButtonProps) => (
  <Button
    sx={theme => ({
      color: theme.colors.functional.text.primary,
      background: theme.colors.gradients.water,
      '&:hover': {
        color: theme.colors.functional.text.primary,
        background: theme.colors.gradients.water,
      },
    })}
    {...props}
  >
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      User Guide
      <IconUserGuide sx={{ ml: '8px' }} />
    </Stack>
  </Button>
);
