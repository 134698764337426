import { SvgIcon, SvgIconProps } from '@mui/material';

const IconEthBlobs = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={sx} viewBox="0 0 20 20" width="20" {...props}>
    <path d="M9.99117 19.9449V14.9548L3.81836 11.345L9.99117 19.9449Z" fill="#F0CDC2" />
    <path
      clipRule="evenodd"
      d="M3.77807 11.3076C3.79564 11.2883 3.82429 11.2839 3.84681 11.2971L10.0196 14.9069C10.0366 14.9168 10.047 14.935 10.047 14.9546V19.9447C10.047 19.9687 10.0315 19.9899 10.0087 19.9973C9.98581 20.0046 9.96084 19.9964 9.94685 19.9769L3.77404 11.377C3.75883 11.3558 3.76051 11.3269 3.77807 11.3076ZM4.0153 11.5236L9.93648 19.773V14.9863L4.0153 11.5236Z"
      fill="#1616B4"
      fillRule="evenodd"
    />
    <path d="M10.0117 19.9449V14.9548L16.1845 11.345L10.0117 19.9449Z" fill="#C9B3F5" />
    <path
      clipRule="evenodd"
      d="M16.224 11.3076C16.2415 11.3269 16.2432 11.3558 16.228 11.377L10.0552 19.9769C10.0412 19.9964 10.0162 20.0046 9.99341 19.9973C9.97056 19.9899 9.95508 19.9687 9.95508 19.9447V14.9546C9.95508 14.935 9.96549 14.9168 9.98244 14.9069L16.1552 11.2971C16.1778 11.2839 16.2064 11.2883 16.224 11.3076ZM10.0656 14.9863V19.773L15.9868 11.5236L10.0656 14.9863Z"
      fill="#1616B4"
      fillRule="evenodd"
    />
    <path d="M9.99169 13.7143V7.3457L3.74805 10.1415L9.99169 13.7143Z" fill="#88AAF1" />
    <path
      clipRule="evenodd"
      d="M10.0203 7.29941C10.0361 7.30959 10.0455 7.32705 10.0455 7.34578V13.7144C10.0455 13.7341 10.0351 13.7523 10.018 13.7622C10.001 13.7721 9.97996 13.7721 9.96286 13.7623L3.71922 10.1895C3.70117 10.1792 3.69045 10.1596 3.69147 10.1388C3.6925 10.1181 3.7051 10.0996 3.72408 10.0912L9.96772 7.29535C9.98481 7.2877 10.0046 7.28922 10.0203 7.29941ZM3.86841 10.1476L9.93505 13.6191V7.43105L3.86841 10.1476Z"
      fill="#1616B4"
      fillRule="evenodd"
    />
    <path d="M10.0117 13.7143V7.3457L16.2555 10.1415L10.0117 13.7143Z" fill="#C9B3F5" />
    <path
      clipRule="evenodd"
      d="M9.98029 7.29941C9.996 7.28922 10.0158 7.2877 10.0329 7.29535L16.2767 10.0912C16.2956 10.0996 16.3082 10.1181 16.3093 10.1388C16.3103 10.1596 16.2996 10.1792 16.2815 10.1895L10.0378 13.7623C10.0207 13.7721 9.99965 13.7721 9.98261 13.7622C9.96557 13.7523 9.95508 13.7341 9.95508 13.7144V7.34578C9.95508 7.32705 9.96457 7.30959 9.98029 7.29941ZM10.0656 7.43105V13.6191L16.1323 10.1476L10.0656 7.43105Z"
      fill="#1616B4"
      fillRule="evenodd"
    />
    <path d="M3.74805 10.1415L9.99169 0.0551758V7.34567L3.74805 10.1415Z" fill="#F0CDC2" />
    <path
      clipRule="evenodd"
      d="M10.0054 0.00211294C10.0292 0.00886867 10.0455 0.0305622 10.0455 0.0552533V7.34574C10.0455 7.36752 10.0328 7.38727 10.0129 7.39617L3.76923 10.192C3.74708 10.2019 3.72105 10.1962 3.70507 10.1779C3.68909 10.1597 3.6869 10.1331 3.69968 10.1125L9.94332 0.0261738C9.95632 0.00517959 9.98166 -0.00464279 10.0054 0.00211294ZM3.88839 10.0175L9.93505 7.30995V0.249472L3.88839 10.0175Z"
      fill="#1616B4"
      fillRule="evenodd"
    />
    <path d="M16.2554 10.1415L10.0117 0.0551758V7.34567L16.2554 10.1415Z" fill="#B8FAF6" />
    <path
      clipRule="evenodd"
      d="M9.99521 0.00211294C10.019 -0.00464279 10.0443 0.00517959 10.0573 0.0261738L16.3009 10.1125C16.3137 10.1331 16.3115 10.1597 16.2955 10.1779C16.2796 10.1962 16.2535 10.2019 16.2314 10.192L9.98775 7.39617C9.96787 7.38727 9.95508 7.36752 9.95508 7.34574V0.0552533C9.95508 0.0305622 9.97146 0.00886867 9.99521 0.00211294ZM10.0656 0.249472V7.30995L16.1122 10.0175L10.0656 0.249472Z"
      fill="#1616B4"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default IconEthBlobs;
