import { useBlockscoutBalanceMetrics, useRaasDeployment } from 'hooks';
import { RollupType } from 'labels/raas';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';
import { addressFormatter, ethFormatter } from 'utils/recharts';

export const BatcherBalanceHistoryMetric = () => {
  const { data: rollup } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.BATCHER_BALANCE_BALANCE_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutBalanceMetrics({
    account: rollup?.accounts?.[rollup?.type === RollupType.OPSTACK ? 'batcher' : 'batchPoster'],
    from,
    to,
  });

  return (
    <Metric
      data={result}
      id={'batcher-balance-history'}
      loading={isPending}
      title={'Balance history'}
      tooltipKeyFormatter={addressFormatter}
      tooltipValueFormatter={ethFormatter}
      yAxisProps={{
        tickFormatter: ethFormatter,
      }}
    />
  );
};
