import { useListApps } from 'hooks/aaas';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export interface IUseApp {
  appId?: string;
}

export const useApp = (opts?: IUseApp) => {
  const { appId } = opts ?? {};
  const params = useParams();

  const { data, ...queryResult } = useListApps();

  return useMemo(
    () => ({ data: data?.aaas.find(v => v.id === (appId ?? params?.appId)), ...queryResult }),
    [appId, data?.aaas, params?.appId, queryResult],
  );
};
