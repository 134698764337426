import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconCirclePlus = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" fill="#202723" r="12" />
      <path
        clipRule="evenodd"
        d="M12.4998 12.2474L12.4998 16.2947H11.4998L11.4998 12.2474H7.95765V11.2474H11.4998L11.4998 7.45262H12.4998L12.4998 11.2474H16.0419V12.2474H12.4998Z"
        fill="white"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};
