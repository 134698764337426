import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogProps,
  FormHelperText,
  Grid,
  Link,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  activateOperator,
  fetchAllAggregatorsStatus,
  fetchOperatorStatus,
  launchOperator,
} from 'api/avs';
import CheckboxCard from 'components/Card/CheckboxCard';
import CustomStepIcon from 'components/CustomStepIcon';
import IconLink from 'components/icons/IconLink';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import { useAvsRoles } from 'hooks/useAvsRoles';
import { useOperators } from 'hooks/useOperators';
import { _strategyOptions } from 'pages/NewAVS/form-steps/SelectStrategies';
import { ChangeEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { AVS_QUORUMS } from 'types/avs';
import { AVSDeployment, OperatorStatus } from 'types/protoc-gen/avs';
import { QUERY_KEYS } from 'types/react-query';
import { getOnChainQuorums } from 'utils/avs';
import { truncate } from 'utils/strings';
import { Client, Hash } from 'viem';
import { waitForTransactionReceipt } from 'viem/actions';
import { useAccount, useClient } from 'wagmi';

import { AVSDetailsFormValues } from '.';

export default function ActivateHostedOperatorWizard({
  avs,
  onClose,
  ...props
}: DialogProps & { avs: AVSDeployment }) {
  const { control } = useFormContext<AVSDetailsFormValues>();
  const enabledOperators = useWatch<AVSDetailsFormValues, 'operators'>({
    name: 'operators',
    control,
  });
  const [activeStep, setActiveStep] = useState(0);
  const { chain } = useAccount();
  const { offChainQuorums, refetchOperatorStates } = useOperators({ avs });
  const { allowlistManager } = useAvsRoles(avs);

  const { isWhitelistingHostedOperator, operatorList, whitelistHostedOperator } = useOperators({
    selectedOperators: enabledOperators,
    avs,
    onWhitelistOperatorsConfirmed: () => {
      setActiveStep(1);
    },
  });

  const { data: operatorStatus } = useQuery({
    queryKey: [QUERY_KEYS.GET_OPERATOR_STATUS, avs?.deploymentId],
    queryFn: fetchOperatorStatus,
    enabled: Boolean(avs?.deploymentId),
  });
  const { data: aggregator } = useQuery({
    queryKey: [QUERY_KEYS.GET_AGGREGATORS_STATUS],
    queryFn: fetchAllAggregatorsStatus,
    select: res => {
      return res?.find(agg => agg.avsName === avs?.avsName);
    },
    enabled: Boolean(avs?.avsName),
  });

  const queryClient = useQueryClient();

  const { isPending: isLaunching, mutate: launch } = useMutation({
    mutationFn: launchOperator,
    onSuccess: () => {
      setActiveStep(3);
      queryClient?.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_OPERATOR_STATUS, avs?.deploymentId],
      });
    },
  });

  const viemClient = useClient();

  const [registerOperatorTxHash, setRegisterOperatorTxHash] = useState('');
  const [isRegisterTxPending, setIsRegisterTxPending] = useState(false);

  const {
    error,
    isPending: isActivating,
    mutate: activate,
    reset,
  } = useMutation({
    mutationFn: activateOperator,
    onSuccess: async res => {
      setRegisterOperatorTxHash(res?.txnHash);
      setIsRegisterTxPending(true);

      try {
        await waitForTransactionReceipt(viemClient as Client, { hash: res?.txnHash as Hash });
      } catch (err) {
        console.error('Register tx failed: ', err);
      } finally {
        setIsRegisterTxPending(false);
      }

      refetchOperatorStates();
      setActiveStep(2);
    },
  });

  const hostedOperator = operatorList?.find(cur => cur.address === avs?.operators?.[0]?.operator);

  const selectableStrategies = useMemo(
    () =>
      _strategyOptions?.filter(
        cur =>
          offChainQuorums?.includes(cur?.value) &&
          !hostedOperator?.registeredQuorums?.includes(cur?.value),
      ),
    [hostedOperator?.registeredQuorums, offChainQuorums],
  );

  useEffect(() => {
    if (activeStep === 0 && hostedOperator?.whitelisted) {
      setActiveStep(1);
    }

    if (activeStep === 1 && !selectableStrategies?.length) {
      setActiveStep(2);
    }

    if (
      activeStep === 2 &&
      [OperatorStatus.launching, OperatorStatus.running]?.includes(
        operatorStatus?.status as OperatorStatus,
      )
    ) {
      setActiveStep(3);
    }
  }, [activeStep, hostedOperator, operatorStatus, selectableStrategies]);

  const [promoCodeInput, setPromoCodeInput] = useState('');

  const handleChangePromoCodeInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const input = e?.target?.value;

      setPromoCodeInput(input);

      if (error) {
        reset();
      }
    },
    [error, reset],
  );

  const [selectedQuorums, setSelectedQuorums] = useState(
    selectableStrategies?.map(opt => opt.value),
  );

  const handleSelectOption = useCallback(
    (value: AVS_QUORUMS) => () => {
      if (selectedQuorums.includes(value)) {
        setSelectedQuorums(selectedQuorums.filter(cur => cur !== value));
      } else {
        setSelectedQuorums([...selectedQuorums, value]?.sort());
      }
    },
    [selectedQuorums],
  );

  const steps: {
    title: ReactNode;
    description?: ReactNode;
    content?: ReactNode;
    actionBar: ReactNode;
  }[] = useMemo(
    () => [
      {
        title: <Typography>Whitelist hosted operator</Typography>,
        actionBar: (
          <Web3LoadingButton
            fullWidth
            loading={isWhitelistingHostedOperator}
            onClick={whitelistHostedOperator}
            requiredRole="allowlistManager"
            requiredSender={allowlistManager}
            variant="contained"
          >
            Whitelist
          </Web3LoadingButton>
        ),
      },
      {
        title: <Typography>Register for selected strategies</Typography>,
        description: registerOperatorTxHash ? (
          <Link
            href={`${chain?.blockExplorers?.default?.url}/tx/${registerOperatorTxHash}`}
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'underline',
              display: 'inline-flex',
              alignItems: 'center',
              width: 'max-content',
            }}
            target="_blank"
          >
            <Typography fontSize={12} variant="caption">
              Txn hash: {truncate(registerOperatorTxHash, undefined, 5, 3)}
            </Typography>
            {isRegisterTxPending && <CircularProgress size={15} />}
            <IconLink sx={{ ml: 1, width: '14px', height: '14px' }} />
          </Link>
        ) : null,
        content: (
          <Stack>
            {_strategyOptions
              ?.filter(
                cur =>
                  offChainQuorums?.includes(cur?.value) &&
                  !hostedOperator?.registeredQuorums?.includes(cur?.value),
              )
              ?.map((option, index) => {
                const isNextOptionSelected = selectedQuorums?.includes(
                  _strategyOptions?.filter(
                    cur =>
                      offChainQuorums?.includes(cur?.value) &&
                      !hostedOperator?.registeredQuorums?.includes(cur?.value),
                  )?.[index + 1]?.value,
                );

                return (
                  <Grid item key={option?.value} xs={12}>
                    <CheckboxCard
                      content={<Typography px={1}>{option.label}</Typography>}
                      isSelected={selectedQuorums?.includes(option.value)}
                      leftButtonGroup={
                        <Checkbox
                          checked={selectedQuorums?.includes(option.value)}
                          onChange={handleSelectOption(option.value)}
                          size="small"
                          sx={{ ml: 1.5 }}
                        />
                      }
                      onClick={handleSelectOption(option.value)}
                      sx={{ py: 1, svg: {}, ...(isNextOptionSelected && { borderBottom: 'none' }) }}
                    />
                  </Grid>
                );
              })}
          </Stack>
        ),
        actionBar: (
          <>
            <LoadingButton
              fullWidth
              loading={isActivating}
              onClick={() =>
                activate({
                  deploymentId: avs?.deploymentId || '',
                  quorums: getOnChainQuorums(selectedQuorums, avs),
                })
              }
              variant="contained"
            >
              Register
            </LoadingButton>
            {hostedOperator?.registeredQuorums?.length ? (
              <Button
                fullWidth
                onClick={() => {
                  setActiveStep(2);
                }}
                variant="outlined"
              >
                Skip
              </Button>
            ) : null}
          </>
        ),
      },
      {
        title: <Typography>Launch hosted operator</Typography>,
        content: (
          <Stack>
            <Typography variant="bodySmallC">Enter a promo code</Typography>
            <TextField
              error={Boolean(error)}
              helperText={error ? 'Invalid promo code.' : ''}
              onChange={handleChangePromoCodeInput}
              placeholder="Promo Code"
              value={promoCodeInput}
            />
          </Stack>
        ),
        actionBar: (
          <>
            <LoadingButton
              disabled={aggregator?.status !== 'running'}
              fullWidth
              loading={isLaunching}
              onClick={() => {
                launch({ deploymentId: avs?.deploymentId, promoCode: promoCodeInput });
              }}
              variant="contained"
            >
              Launch
            </LoadingButton>
            {aggregator?.status !== 'running' && (
              <FormHelperText error>Please wait until AVS is initialized</FormHelperText>
            )}
            {selectableStrategies?.length ? (
              <Button
                fullWidth
                onClick={() => {
                  setActiveStep(1);
                }}
                variant="outlined"
              >
                Back
              </Button>
            ) : null}
          </>
        ),
      },
    ],
    [
      activate,
      aggregator?.status,
      allowlistManager,
      avs,
      chain?.blockExplorers?.default?.url,
      error,
      handleChangePromoCodeInput,
      handleSelectOption,
      hostedOperator?.registeredQuorums,
      isActivating,
      isLaunching,
      isRegisterTxPending,
      isWhitelistingHostedOperator,
      launch,
      offChainQuorums,
      promoCodeInput,
      registerOperatorTxHash,
      selectableStrategies?.length,
      selectedQuorums,
      whitelistHostedOperator,
    ],
  );

  return (
    <Dialog onClose={onClose} sx={{ '& > div > .MuiPaper-root': { p: 5 } }} {...props}>
      <Typography variant="h5">Activate Hosted Operator</Typography>
      <Stepper activeStep={activeStep} connector={null} orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={index}
            sx={
              index !== steps?.length - 1
                ? {
                    position: 'relative',
                    '& .MuiStepLabel-iconContainer:after': {
                      content: "' '",
                      height: 'calc(100% - 24px)',
                      borderRight: '1px solid black',
                      position: 'absolute',
                      left: '11px',
                      top: '32px',
                      zIndex: 0,
                    },
                  }
                : {}
            }
          >
            <StepLabel StepIconComponent={CustomStepIcon} sx={{ alignItems: 'flex-start' }}>
              <Typography mt={0.25}>{step.title}</Typography> {step.description}
            </StepLabel>
            <StepContent sx={{ borderLeft: 'none' }}>
              <Typography>{step.content}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Stack alignItems="center" justifyContent="center" mt={2} width="100%">
        {activeStep >= steps?.length ? (
          <Stack gap={2}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#66B489',
                fontWeight: 500,
              }}
            >
              🎉&nbsp;Successfully activated hosted operator
            </Typography>
            {operatorStatus?.status &&
            [OperatorStatus.running, OperatorStatus.launching]?.includes(operatorStatus?.status) ? (
              <Typography>
                Your hosted operator is now {OperatorStatus?.[operatorStatus?.status]}
              </Typography>
            ) : null}
            <Button
              fullWidth
              onClick={() => {
                onClose?.({}, 'escapeKeyDown');
              }}
              variant="contained"
            >
              Close
            </Button>
          </Stack>
        ) : (
          steps?.[activeStep]?.actionBar
        )}
      </Stack>
    </Dialog>
  );
}
