import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';
import { useApp } from 'pages/AaasDeployments/useApp';
import { MUTATION_KEYS } from 'types/react-query';

interface PromptError extends Error {
  code: string;
}

export interface IUsePrompt
  extends Omit<
    UseMutationOptions<PromptReply, PromptError, PromptRequest, unknown>,
    'mutationFn' | 'mutationKey'
  > {
  appId?: string;
}

export const usePrompt = (
  opts?: IUsePrompt,
): UseMutationResult<PromptReply, PromptError, PromptRequest, unknown> => {
  const { data: app } = useApp({ appId: opts?.appId });

  return useMutation({
    mutationKey: [MUTATION_KEYS.AAAS_AI_AGENT_PROMPT, { appId: opts?.appId }],
    mutationFn: async (args: { message: string }) => {
      if (!app?.endpoints?.host) throw new Error('The app has no host endpoint');

      const res = await axios.post<PromptReply>(`https://${app.endpoints.host}/chat`, args);

      return res.data;
    },
    retry: 5, // The default retryDelay is set to double (starting at 1000ms) with each attempt, but not exceed 30 seconds: https://tanstack.com/query/latest/docs/framework/react/guides/query-retries
    ...opts,
  });
};

export interface PromptRequest {
  message: string;
}

export interface PromptReply {
  response: string[];
  status: 'success';
}
