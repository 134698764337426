import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { SendTransactionErrorType, WaitForTransactionReceiptErrorType } from '@wagmi/core';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { useTxStatus } from 'contexts/TxStatusContext';
import { Address, Client, SendTransactionReturnType } from 'viem';
import { waitForTransactionReceipt } from 'viem/actions';
import { useAccount, useClient, useSendTransaction } from 'wagmi';

const waitWithRetryForTxReceipt = async (
  client: Client,
  txHash: `0x${string}`,
  retries = 3,
  timeBetweenRetries = 1000,
) => {
  try {
    await waitForTransactionReceipt(client, { hash: txHash });
  } catch (err) {
    if (retries > 0) {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          waitWithRetryForTxReceipt(client, txHash, retries - 1, timeBetweenRetries)
            ?.then(resolve)
            ?.catch(err => {
              reject(err);
            });
        }, timeBetweenRetries);
      });
    }

    if (retries <= 0) {
      return Promise.reject(err);
    }
  }
};

interface UseSendTxArgs {
  txKey: string;
  onError?: (error: SendTransactionErrorType | WaitForTransactionReceiptErrorType) => void;
  onTxConfirmed?: (txReceipt: SendTransactionReturnType) => void;
}

const getTxLink = (txHash: string, url: string, name: string) => ({
  url: `${url}/tx/${txHash}`,
  label: `View tx on ${name}`,
});

export const useSendTx = (props: UseSendTxArgs) => {
  const { onError, onTxConfirmed, txKey } = props;

  const { addAlert, removeAlert } = useAlerts();
  const addRecentTransaction = useAddRecentTransaction();

  const { addPendingTx, pendingTxs, removePendingTx } = useTxStatus();
  const isPending = pendingTxs?.has(txKey);

  const { chain } = useAccount();
  const client = useClient();
  const functionName = 'Send ETH';
  const { data: txHash, sendTransaction } = useSendTransaction({
    mutation: {
      onSuccess: async txHash => {
        addRecentTransaction({ hash: txHash, description: functionName });
        const pendingTxKey = addAlert({
          title: `${functionName} transaction pending`,
          desc: `Tx hash: ${txHash}`,
          severity: ALERT_SEVERITY.PENDING,
          link: getTxLink(
            txHash,
            chain?.blockExplorers?.default.url || '',
            chain?.blockExplorers?.default?.name || '',
          ),
          timeout: Infinity,
        });

        if (client) {
          await waitWithRetryForTxReceipt(client, txHash);
          removePendingTx(txKey);
          removeAlert(pendingTxKey);
          addAlert({
            title: `${functionName} transaction confirmed`,
            desc: `Tx hash: ${txHash}`,
            link: getTxLink(
              txHash,
              chain?.blockExplorers?.default.url || '',
              chain?.blockExplorers?.default?.name || '',
            ),
            severity: ALERT_SEVERITY.SUCCESS,
          });
          onTxConfirmed?.(txHash);
        } else {
          console.error('no client configured. Client: ', client);
        }
      },
      onError: err => {
        console.error(`${functionName} transaction failed`, err);
        console.error(
          'Error Message: ',
          err?.message || (err?.cause as string) || 'An unexpected error occured',
        );
        removePendingTx(txKey);
        addAlert({
          title: `${functionName} transaction failed`,
          severity: ALERT_SEVERITY.ERROR,
          desc: err?.message || (err?.cause as string) || 'An unexpected error occured',
          link: txHash
            ? getTxLink(
                txHash,
                chain?.blockExplorers?.default.url || '',
                chain?.blockExplorers?.default?.name || '',
              )
            : undefined,
        });
        onError?.(err);
      },
    },
  });

  const send = ({ to, value }: { to: Address; value: bigint }) => {
    console.debug(`[${functionName}] props: `, props);
    addPendingTx(txKey);
    sendTransaction({
      to,
      value,
    });
  };

  return { send, txHash, isPending };
};
