import {
  Box,
  Grid,
  GridProps,
  Skeleton,
  SkeletonProps,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import IconInfo from 'components/icons/IconInfo';

export interface IWidgetMetric extends Omit<GridProps, 'children'> {
  label?: string;
  caption?: string;
  value?: number;
  valueSuffix?: string;
  loading?: boolean;
  tooltip?: string;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  skeletonProps?: SkeletonProps;
}

export const WidgetMetric = ({
  caption,
  label,
  loading,
  skeletonProps,
  sx,
  tooltip,
  tooltipProps,
  value,
  valueSuffix,
  ...props
}: IWidgetMetric) => {
  return (
    <Grid
      item
      xs={4}
      {...props}
      sx={{
        borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
        borderRight: theme => `1px solid ${theme.colors.functional.subject.border}`,
        px: '40px',
        py: '24px',
        minHeight: '134px',
        background: '#fff',
        '& .MuiSkeleton-root': {
          borderRadius: 0,
        },
        ...sx,
      }}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        spacing="20px"
        sx={{ height: '100%' }}
      >
        <Stack direction="row" justifyContent="space-between">
          {loading ? (
            <>
              <Skeleton height="18px" variant="rectangular" width="40%" />
              {tooltip && (
                <Skeleton
                  height="18px"
                  sx={{
                    '&.MuiSkeleton-root': {
                      borderRadius: '50%',
                    },
                  }}
                  variant="rounded"
                  width="18px"
                />
              )}
            </>
          ) : (
            <>
              <Typography variant="captionC">{label ?? '-'}</Typography>
              {tooltip && (
                <Tooltip placement="top" {...tooltipProps} title={tooltip}>
                  <Box alignItems="center" display="flex" justifyContent="center">
                    <IconInfo sx={{ height: '18px', width: '18px' }} />
                  </Box>
                </Tooltip>
              )}
            </>
          )}
        </Stack>

        {loading ? (
          <Skeleton height="30px" variant="text" width="80%" {...skeletonProps} />
        ) : (
          <Typography variant="h4">
            {[value?.toLocaleString() ?? '-', valueSuffix].join(' ')}
          </Typography>
        )}

        {caption &&
          (loading ? (
            <Skeleton height="30px" variant="text" width="15%" {...skeletonProps} />
          ) : (
            <Typography variant="caption">{caption}</Typography>
          ))}
      </Stack>
    </Grid>
  );
};
