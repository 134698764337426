import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconMore = ({ ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M5.00065 9.79134C5.00065 10.5968 4.34773 11.2497 3.54232 11.2497C2.7369 11.2497 2.08398 10.5968 2.08398 9.79134C2.08398 8.98593 2.7369 8.33301 3.54232 8.33301C4.34773 8.33301 5.00065 8.98593 5.00065 9.79134ZM11.2507 9.79134C11.2507 10.5968 10.5977 11.2497 9.79232 11.2497C8.9869 11.2497 8.33398 10.5968 8.33398 9.79134C8.33398 8.98593 8.9869 8.33301 9.79232 8.33301C10.5977 8.33301 11.2507 8.98593 11.2507 9.79134ZM16.0423 11.2497C16.8477 11.2497 17.5007 10.5968 17.5007 9.79134C17.5007 8.98593 16.8477 8.33301 16.0423 8.33301C15.2369 8.33301 14.584 8.98593 14.584 9.79134C14.584 10.5968 15.2369 11.2497 16.0423 11.2497Z"
      fill="#202723"
      fillRule="evenodd"
    />
  </SvgIcon>
);
