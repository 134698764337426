import { hideFeature } from 'helpers/visibility';
import { useRaasDeployment } from 'hooks/raas/useRaasDeployment';

import { CurrentPlanSection } from './CurrentPlanSection';
import { MetricsSection } from './MetricsSection';
import { RollupInfoSection } from './RollupInfoSection';

export const OverviewView = () => {
  const { data: rollup, isPending } = useRaasDeployment();

  return (
    <>
      <RollupInfoSection loading={isPending} rollup={rollup} />
      {!hideFeature && <CurrentPlanSection />}
      <MetricsSection />
    </>
  );
};
