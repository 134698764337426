import { Section } from 'pages/DeploymentDetails/Section';
import { SectionContentKeyValue } from 'pages/DeploymentDetails/SectionContentKeyValue';

import { IRollupInfoSection } from './types';

export const AssetsSection = ({ loading, rollup }: IRollupInfoSection) => {
  return (
    <Section
      renderGridContent={
        <>
          <SectionContentKeyValue
            clipboard
            label="WebSocket"
            loading={loading}
            truncate
            truncateMaxLength={40}
            value={rollup?.rpcWsEndpoint}
            xs={6}
          />
          <SectionContentKeyValue
            clipboard
            hyperlink
            label="HTTP RPC"
            loading={loading}
            truncate
            truncateMaxLength={30}
            value={rollup?.rpcEndpoint}
            xs={6}
          />
          <SectionContentKeyValue
            clipboard
            hyperlink
            label="Bridge"
            loading={loading}
            truncate
            truncateMaxLength={40}
            value={rollup?.bridge}
            xs={6}
          />
          <SectionContentKeyValue
            clipboard
            hyperlink
            label="Explorer"
            loading={loading}
            truncate
            value={rollup?.explorer}
            xs={6}
          />
        </>
      }
      title="Assets"
    />
  );
};
