import { useRaasDeployment } from 'hooks';
import { MetricGridItem } from 'pages/DeploymentDetails/MetricGridItem';
import { Section } from 'pages/DeploymentDetails/Section';
import { SectionButtonTimeFilter } from 'pages/DeploymentDetails/SectionButtonTimeFilter';
import { TimeFilterVariant } from 'pages/DeploymentDetails/useTimeFilterStore';

import { AverageBlockSizeMetric } from './AverageBlockSizeMetric';

export const BlocksSection = () => {
  const { isPending } = useRaasDeployment();

  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item xs={12}>
            <AverageBlockSizeMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <SectionButtonTimeFilter
          loading={isPending}
          variant={TimeFilterVariant.ROLLUP_STATS_BLOCK_SECTION}
        />
      }
      sectionContentSx={{ p: '0px' }}
      title="Blocks"
    />
  );
};
