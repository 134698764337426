import { useBlockscoutMetrics } from 'hooks';
import { Metric } from 'pages/DeploymentDetails/Metric';
import { useTimeFilterOptions } from 'pages/DeploymentDetails/useTimeFilterOptions';
import { TimeFilterVariant, useTimeFilterStore } from 'pages/DeploymentDetails/useTimeFilterStore';

export const NewTransactionsMetric = () => {
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[TimeFilterVariant.ROLLUP_STATS_TRANSACTIONS_SECTION] || 'month',
  );
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'newTxns',
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Number of new transactions'}
      id={'new-transactions'}
      loading={isPending}
      title={'New transactions'}
    />
  );
};
