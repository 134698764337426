import { Box, BoxProps, CircularProgress, Stack, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface IOption extends BoxProps {
  optionTitle: ReactNode;
  children?: ReactNode;
  endAdornment?: ReactNode;
  isLoading?: boolean;
}

export const Option = styled(
  ({ children, endAdornment, isLoading, optionTitle, sx, ...props }: IOption) => {
    return (
      <Box sx={{ pl: { md: 5, xs: 2.5 }, ...sx }} {...props}>
        <Typography
          alignItems="center"
          component={Stack}
          flexDirection="row"
          fontWeight={500}
          justifyContent={endAdornment ? 'space-between' : 'flex-start'}
          mb={{ xs: 1.5, md: 3 }}
          mt={{ xs: 2.5, md: 5 }}
          variant="bodySmallC"
        >
          {optionTitle} {endAdornment}
        </Typography>
        {isLoading ? (
          <Stack
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '10rem',
            }}
          >
            <CircularProgress size={40} />
          </Stack>
        ) : (
          children
        )}
      </Box>
    );
  },
)(() => ``);
