import { Box, BoxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { holesky } from 'viem/chains';
import { useAccount } from 'wagmi';

import EnsureNetworkButton from './EnsureNetworkButton';

export const ConditionalConnectButton: FC<
  {
    bypass?: boolean;
    chainId?: number;
    chainName?: string;
    hideHelperText?: boolean;
    helperText?: string;
  } & BoxProps
> = ({
  bypass = false,
  chainId = holesky.id,
  chainName,
  children,
  helperText = '',
  hideHelperText = false,
  sx,
  ...props
}) => {
  const { chainId: currentChainId, isConnected } = useAccount();

  const isRouteAccessible =
    bypass || (chainId ? currentChainId === chainId && isConnected : isConnected);

  return isRouteAccessible ? (
    children
  ) : (
    <>
      {isConnected && !hideHelperText && (
        <Typography textAlign="center">
          {helperText || `Please switch to chain ID: ${chainId}`}
        </Typography>
      )}
      <Box
        sx={{
          width: '100%',
          '& > div': { display: 'flex', flex: 1 },
          '&& > div > button': {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '56px',
            border: '1px solid red',
          },
          ...sx,
        }}
        {...props}
      >
        <EnsureNetworkButton fullWidth targetId={chainId} targetName={chainName} />
      </Box>
    </>
  );
};
