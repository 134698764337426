import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { PromptReply } from 'hooks/aaas/usePrompt';
import { useApp } from 'pages/AaasDeployments/useApp';
import { useState } from 'react';
import { AAAHostType, aAAHostTypeFromJSON } from 'types/protoc-gen/aaas-bffavs';
import { QUERY_KEYS } from 'types/react-query';

const endpointTemplate = {
  chat: 'https://{BASE_URL}/chat',
  ui: 'https://{BASE_URL}',
};

interface IUseHealthz extends Omit<UseQueryOptions, 'queryFn' | 'queryKey'> {
  appId?: string;
}

export const useHealthz = (opts?: IUseHealthz) => {
  const [throwCount, setThrowCount] = useState<number>(0);
  const { data: app } = useApp({ appId: opts?.appId });
  const { addAlert } = useAlerts();

  return useQuery({
    queryKey: [QUERY_KEYS.AAAS_HEALTHZ, { appId: opts?.appId }],
    queryFn: async (): Promise<PromptReply | null> => {
      if (!app?.endpoints?.host) throw new Error('The app has no host endpoint');

      try {
        const res = await axios[
          aAAHostTypeFromJSON(app?.hostType) === AAAHostType.AAAHOSTTYPE_CHATBOX ? 'post' : 'get'
        ]<PromptReply>(
          endpointTemplate[
            aAAHostTypeFromJSON(app?.hostType) === AAAHostType.AAAHOSTTYPE_CHATBOX ? 'chat' : 'ui'
          ].replace('{BASE_URL}', app.endpoints.host),
          {
            message: 'healthz',
          },
        );

        return res.data;
      } catch (err: any) {
        setThrowCount(throwCount + 1);

        if (
          throwCount === 3 &&
          aAAHostTypeFromJSON(app?.hostType) === AAAHostType.AAAHOSTTYPE_CHATBOX &&
          err.name === 'AxiosError' &&
          err?.code === 'ERR_NETWORK' &&
          err?.config?.method === 'post'
        )
          addAlert({
            severity: ALERT_SEVERITY.INFO,
            title: 'AI Agent Not Available',
            desc: 'It seems like the AI agent is taking awhile to deploy. Please refresh the page and try again in a few minutes.',
          });

        throw err;
      }
    },
    retry: 8,
    retryDelay: 60_000,
    throwOnError: true,
    ...opts,
  });
};
