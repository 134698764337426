import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchAllAggregatorsStatus, fetchAllAvs } from 'api/avs';
import { AccordionSection } from 'components/AccordionSection';
import { DeploymentGridActionButton, DeploymentGridContainer } from 'components/Deployment';
import { PageBanner, PageBannerVariant } from 'components/Layout/PageBanner';
import { SubHeader } from 'components/Layout/SubHeader';
import { useGlobalPublicAvs } from 'hooks/api/useGlobalPublicAvs';
import { useDisclosure } from 'hooks/ui/useDisclosure';
import { useState } from 'react';
import { AVSDeployment, GetAVSStatusByNameResponse } from 'types/protoc-gen/avs';
import { QUERY_KEYS } from 'types/react-query';

import { AvsDeploymentCard } from './components/AvsDeploymentCard';
import { AvsCreationModeDialog } from './AvsCreationModeDialog';
import { GlobalAvsDeployments } from './GlobalAvsDeployments';

export function AVSDeployments() {
  const [showGlobal, setShowGlobal] = useState(false);

  const {
    onClose: closeAvsModeSelection,
    onOpen: openAvsModeSelection,
    open: isAvsModeSelectionOpen,
  } = useDisclosure();

  const {
    data: avsDeployments,
    isFetching: isFetchingAvsDeployments,
    isLoading: isLoadingAvsDeployments,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_AVS_DEPLOYMENTS],
    queryFn: fetchAllAvs,
  });
  const {
    data: aggregators,
    isFetching: isFetchingAggregators,
    isLoading: isLoadingAggregators,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_AGGREGATORS_STATUS],
    queryFn: fetchAllAggregatorsStatus,
  });
  const {
    data: globalAvsDeployments,
    isFetching: isFetchingGlobalAvs,
    isLoading: isLoadingGlobalAvs,
  } = useGlobalPublicAvs({
    enabled: Boolean(showGlobal),
  });

  return (
    <>
      <AvsCreationModeDialog onClose={closeAvsModeSelection} open={isAvsModeSelectionOpen} />
      <PageBanner variant={PageBannerVariant.AVS} />
      <SubHeader
        isLoading={
          (isLoadingAvsDeployments && isFetchingAvsDeployments) ||
          (isLoadingAggregators && isFetchingAggregators)
        }
      >
        Your AVS Deployments
      </SubHeader>
      <DeploymentGridContainer>
        {avsDeployments?.map(avs => (
          <AvsDeploymentGridItem
            aggregator={aggregators?.find(agg => agg.avsName === avs?.avsName)}
            avsDeployment={avs}
            key={avs.deploymentId}
          />
        ))}

        <DeploymentGridActionButton label="New AVS" onClick={openAvsModeSelection} />
      </DeploymentGridContainer>

      <AccordionSection
        onClickHeader={() => setShowGlobal(prev => !prev)}
        open={showGlobal}
        renderTitle={<Typography variant="h5">{showGlobal ? '' : 'Browse '} Global AVS</Typography>}
        sx={{ mb: '164px' }}
      >
        <GlobalAvsDeployments
          data={globalAvsDeployments?.avsDeployment || ([] as AVSDeployment[])}
          isLoading={isLoadingGlobalAvs && isFetchingGlobalAvs}
        />
      </AccordionSection>
    </>
  );
}

const AvsDeploymentGridItem: React.FC<{
  avsDeployment: AVSDeployment;
  aggregator: GetAVSStatusByNameResponse | undefined;
}> = ({ aggregator, avsDeployment, ...props }) => (
  <Grid item xs={4} {...props}>
    <AvsDeploymentCard aggregator={aggregator} avsDeployment={avsDeployment} />
  </Grid>
);
