import { CircularProgress, Grid, Typography } from '@mui/material';

export interface ISubHeader {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const SubHeader = ({ children, isLoading }: ISubHeader) => {
  return (
    <Grid
      alignItems="center"
      container
      gap={2}
      sx={{ background: '#fafafa', px: 5, py: 4, border: '1px solid rgba(32, 39, 35, 0.08)' }}
    >
      <Typography variant="h5">{children}</Typography>
      {isLoading && <CircularProgress size={20} />}
    </Grid>
  );
};
