import { Skeleton, Stack, SvgIcon, Typography } from '@mui/material';
import { useRaasDeployment } from 'hooks';
import { useBatchBalances } from 'hooks/viem/useBatchBalances';
import { colors } from 'theme/colors';
import { Address, formatEther } from 'viem';

import { SubHeaderChip } from './SubHeaderChip';

export interface IAccountBalanceSubHeaderChip {
  account: Address;
}

export const AccountBalanceSubHeaderChip = ({ account }: IAccountBalanceSubHeaderChip) => {
  const { data: rollup } = useRaasDeployment();
  const { data: result, isPending } = useBatchBalances({
    accounts: [account],
    rollup,
  });
  const formattedBalance = +formatEther(result?.[0].balance ?? 0n);

  return (
    <SubHeaderChip>
      <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
        {isPending ? (
          <Skeleton sx={{ height: '8px', width: '8px' }} variant="circular" />
        ) : (
          <SvgIcon sx={{ height: '8px', width: '8px' }} viewBox="0 0 8 8">
            <circle
              cx="4"
              cy="4"
              fill={+formattedBalance > 0.05 ? colors.schema.waterPrimary : colors.schema.failure}
              r="4"
            />
          </SvgIcon>
        )}
        {isPending ? (
          <Skeleton variant="text" width="35px" />
        ) : (
          <Typography variant="caption">
            {`${formattedBalance} ${rollup?.tokenMetadata?.symbol ?? 'ETH'}`}
          </Typography>
        )}
      </Stack>
    </SubHeaderChip>
  );
};
