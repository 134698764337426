import { create } from 'zustand';

interface State {
  dialogs: {
    edit: {
      threshold: string;
      notifications: string;
    };
  };
}

interface Actions {
  setThreshold: (threshold: string) => void;
  setNotifications: (notifications: string) => void;
  reset: () => void;
}

const initialState: State = {
  dialogs: {
    edit: {
      threshold: '',
      notifications: '',
    },
  },
};

export const useBalanceAlertStore = create<State & Actions>(set => ({
  ...initialState,
  setThreshold: threshold =>
    set(state => ({
      ...state,
      dialogs: { ...state.dialogs, edit: { ...state.dialogs.edit, threshold } },
    })),
  setNotifications: notifications =>
    set(state => ({
      ...state,
      dialogs: { ...state.dialogs, edit: { ...state.dialogs.edit, notifications } },
    })),
  reset: () => set(initialState),
}));
