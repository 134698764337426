import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { bff } from 'api';
import { CreateAAAReply, CreateAAARequest } from 'types/protoc-gen/aaas-bffavs';
import { MUTATION_KEYS } from 'types/react-query';

export interface IUseCreateApp
  extends Omit<
    UseMutationOptions<CreateAAAReply, Error, CreateAAARequest, unknown>,
    'mutationFn' | 'mutationKey'
  > {}

export const useCreateApp = (
  opts?: IUseCreateApp,
): UseMutationResult<CreateAAAReply, Error, CreateAAARequest, unknown> => {
  return useMutation<CreateAAAReply, Error, CreateAAARequest, unknown>({
    mutationKey: [MUTATION_KEYS.CREATE_AAA],
    mutationFn: async (args: CreateAAARequest) => {
      const res = await bff.post<CreateAAAReply>('/bff/aaas', args);

      return res.data;
    },
    ...opts,
  });
};
