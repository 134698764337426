import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IconEdit } from 'components/icons/IconEdit';
import { useDisclosure } from 'hooks/ui';
import { Section } from 'pages/DeploymentDetails/Section';
import { useCallback, useEffect } from 'react';
import { truncateEthAddress } from 'utils/address';
import { truncate } from 'utils/strings';
import { zeroAddress } from 'viem';

import { EditDialog } from './EditDialog';
import { useBalanceAlertStore } from './store';

export const BalanceAlertView = () => {
  const { reset, setNotifications, setThreshold } = useBalanceAlertStore();
  const {
    onClose: onCloseEditDialog,
    onOpen: onOpenEditDialog,
    open: openedEditDialog,
  } = useDisclosure();
  const handleClickEditDialog = useCallback(
    (data: MockData) => {
      setThreshold(data.threshold);
      setNotifications(data.notifications.join(','));
      onOpenEditDialog();
    },
    [onOpenEditDialog, setNotifications, setThreshold],
  );

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <>
      <Section title="Balance Alert">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['Name', 'Address', 'Balance (ETH)', 'Threshold (ETH)', 'Notifications', ' '].map(
                  header => (
                    <TableCell key={header}>
                      <Typography variant="captionC">{header}</Typography>
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {_getMockData().map((v, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <Typography variant="bodySmall">{v.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{ cursor: 'pointer', color: '#5C8CE1', textDecoration: 'underline' }}
                      variant="bodySmall"
                    >
                      {truncateEthAddress(v.address)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={theme => ({
                        color:
                          v.threshold > v.balance
                            ? '#F1605F'
                            : theme.colors.functional.text.primary,
                      })}
                      variant="bodySmall"
                    >
                      {v.balance}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="bodySmall">{v.threshold}</Typography>
                  </TableCell>
                  <TableCell>
                    <Stack spacing="8px">
                      {v.notifications.length === 0
                        ? '-'
                        : v.notifications.map(text => (
                            <Typography key={text} variant="bodySmall">
                              {truncate(text, '...', 15, 0)}
                            </Typography>
                          ))}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Button
                      disableElevation
                      onClick={() => handleClickEditDialog(v)}
                      size="small"
                      sx={theme => ({
                        color: theme.colors.functional.text.primary,
                        background: 'transparent',
                        '&:hover': {
                          background: 'transparent',
                          color: theme.colors.functional.text.primary,
                        },
                      })}
                    >
                      <Stack direction="row" spacing="8px">
                        <IconEdit sx={{ height: '20px', width: '20px' }} />
                        <Typography variant="bodySmallC"> EDIT</Typography>
                      </Stack>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Section>

      <EditDialog onClose={onCloseEditDialog} open={openedEditDialog} />
    </>
  );
};

interface MockData {
  name: string;
  address: '0x0000000000000000000000000000000000000000';
  balance: string;
  threshold: string;
  notifications: string[];
}

const _getMockData = (): MockData[] => [
  {
    name: 'Batcher',
    address: zeroAddress,
    balance: '0.4990118',
    threshold: '0.5445',
    notifications: ['abc@xterio.com', 'slack://something-long-string-zzz.xyz'],
  },
  {
    name: 'Proposer',
    address: zeroAddress,
    balance: '0.985587',
    threshold: '0.5',
    notifications: [],
  },
];
