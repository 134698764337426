import { darken, Dialog, DialogContent, DialogProps, Stack, Typography } from '@mui/material';
import IconImport from 'components/icons/IconImport';
import { useDisclosure } from 'hooks/ui';
import { IoAddSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

import { ImportAvsDialog } from './ImportAvsDialog';

export function AvsCreationModeDialog({ onClose, open, ...rest }: DialogProps) {
  const {
    onClose: closeImportAvsDialog,
    onOpen: openImportAvsDialog,
    open: isImportAvsDialogOpen,
  } = useDisclosure();

  const navigate = useNavigate();

  return (
    <>
      <ImportAvsDialog onClose={closeImportAvsDialog} open={isImportAvsDialogOpen} />
      <Dialog
        disablePortal
        onClose={onClose}
        open={open}
        sx={{
          '& .MuiPaper-root': { p: 3, background: '#FFFFFF', maxWidth: '90vw', minWidth: '30vw' },
        }}
        {...rest}
      >
        <DialogContent>
          <Stack
            spacing={3}
            sx={{
              '& > div': {
                bgcolor: '#F6F6F6',
                p: 5,
                cursor: 'pointer',
              },
              '& > div:hover': {
                bgcolor: darken('#F6F6F6', 0.05),
              },
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              onClick={() => navigate(PATHS.NEW_AVS)}
              spacing={2}
            >
              <IoAddSharp size={24} />
              <Typography variant="bodySmallC">Deploy New AVS</Typography>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              onClick={e => {
                onClose?.(e, 'backdropClick');
                openImportAvsDialog();
              }}
              spacing={2}
            >
              <IconImport />
              <Typography variant="bodySmallC">Import Existing AVS</Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
