import { Box, IconButton, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { IconAaa } from 'components/icons/IconAaa';
import IconDeploy from 'components/icons/IconDeploy';
import { ReactNode, useCallback } from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { px } from 'theme/margins';

export enum PageBannerVariant {
  AVS = 'AVS',
  RAAS = 'RAAS',
  AAA = 'AAA',
}

export interface IPageBanner {
  variant?: PageBannerVariant;
  title?: string;
  renderSubTitleContent?: ReactNode;
  loading?: boolean;
  onClickBack?: () => void;
}

export function PageBanner({
  loading,
  onClickBack,
  renderSubTitleContent: endAdornment,
  title: _title,
  variant,
}: IPageBanner) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const background = useBackground(variant);
  const handleBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <>
      <Box sx={{ background }}>
        <Box margin="0 auto" pb={{ md: '40px' }} pt={{ md: '38px' }} px={px}>
          <Stack alignItems="flex-end" flexDirection="row" justifyContent="space-between">
            <Stack alignItems="start">
              <Stack
                alignItems="center"
                direction="row"
                display="flex"
                justifyContent="space-between"
                spacing="40px"
              >
                {(location.pathname?.match(/\/(rollups|avs|aaa)\/[^/]+/) || onClickBack) && (
                  <IconButton
                    onClick={onClickBack ?? handleBack}
                    sx={{
                      height: '56px',
                      width: '56px',
                      background: theme.colors.functional.container.primary,
                      color: '#fff',
                      '&:hover': {
                        background: theme.colors.functional.container.primary,
                        color: '#fff',
                      },
                    }}
                  >
                    <IoArrowBackSharp size={24} />
                  </IconButton>
                )}

                {loading ? (
                  <Skeleton sx={{ minWidth: '400px', minHeight: '80px' }} variant="rectangular" />
                ) : (
                  <Typography
                    sx={{ color: theme => theme.colors.functional.text.primary }}
                    variant="h1"
                  >
                    {_title ?? (variant && title[variant])}
                  </Typography>
                )}
              </Stack>

              {endAdornment}
            </Stack>

            {(variant && icon[variant]) ?? (
              <IconDeploy sx={{ color: theme => theme.colors.functional.text.primary }} />
            )}
          </Stack>
        </Box>
      </Box>
    </>
  );
}

const useBackground = (variant?: PageBannerVariant) => {
  const theme = useTheme();

  switch (variant) {
    case PageBannerVariant.AVS:
      return theme.colors.gradients.water;

    case PageBannerVariant.RAAS:
      return theme.colors.gradients.leaf;

    case PageBannerVariant.AAA:
      return theme.colors.gradients.cream;

    default:
      return theme.colors.gradients.water;
  }
};

const title = {
  [PageBannerVariant.AVS]: 'AVS Dashboard',
  [PageBannerVariant.RAAS]: 'Rollup Dashboard',
  [PageBannerVariant.AAA]: 'AAA Dashboard',
};

const icon = {
  [PageBannerVariant.AVS]: (
    <IconDeploy sx={{ color: theme => theme.colors.functional.text.primary }} />
  ),
  [PageBannerVariant.RAAS]: (
    <IconDeploy sx={{ color: theme => theme.colors.functional.text.primary }} />
  ),
  [PageBannerVariant.AAA]: <IconAaa sx={{ height: '220px', width: '220px' }} />,
};
