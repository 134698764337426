import './maskedOverflow.css';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemButton,
  ListItemText,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchOperatorStatus } from 'api/avs';
import CheckboxCard from 'components/Card/CheckboxCard';
import CustomStepIcon from 'components/CustomStepIcon';
import IconInfo from 'components/icons/IconInfo';
import { Option } from 'components/Option';
import Web3LoadingButton from 'components/Web3/Web3LoadingButton';
import { useAvsRoles } from 'hooks/useAvsRoles';
import { useOperators } from 'hooks/useOperators';
import { avsStrategyLabels, getOperatorStatusColors } from 'labels/deployments';
import { ChangeEventHandler, Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MdCheck, MdClose, MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { AVS_QUORUMS, AVS_TYPES } from 'types/avs';
import { AVSDeployment, OperatorStatus, QueryOperatorResponse } from 'types/protoc-gen/avs';
import { QUERY_KEYS } from 'types/react-query';
import { Address, isAddress } from 'viem';

import ActivateHostedOperatorWizard from './ActivateHostedOperatorWizard';
import OperatorActionsMenu from './OperatorActionsMenu';
import { AVSDetailsFormValues } from '.';

export type OperatorList = { address: Address; name: string }[];

export default function SelectOperators({
  avs,
  isAvsOwner,
  sx,
  ...props
}: BoxProps & { avs: AVSDeployment; isAvsOwner: boolean }) {
  const { control, register, setValue } = useFormContext<AVSDetailsFormValues>();
  const selectedOperators = useWatch<AVSDetailsFormValues, 'operators'>({
    name: 'operators',
    control,
  });

  const isEcdsaAvs = [AVS_TYPES.ECDSA_HELLO_WORLD, AVS_TYPES.CUSTOM_ECDSA]?.includes(avs?.avsType);

  const {
    disableAllowlist,
    enableAllowlist,
    isAllowlistEnabled,
    isLoadingAllowlist,
    isTogglingAllowlist,
    isUpdatingOperators,
    offChainQuorums,
    operatorChangeset,
    operatorList,
    setCustomOperators,
    updateOperators,
  } = useOperators({
    selectedOperators,
    avs,
    onWhitelistOperatorsConfirmed: () => {
      setActiveStep(1);
    },
  });

  const isHostedOperatorWhitelisted = useMemo(
    () => operatorList?.find(cur => cur.address === avs?.operators?.[0]?.operator)?.whitelisted,
    [operatorList, avs?.operators],
  );

  const defaultSelectedOperators = useMemo(
    () =>
      isHostedOperatorWhitelisted
        ? operatorList?.filter(cur => Boolean(cur.whitelisted))?.map(cur => cur.address) || []
        : [
            ...(avs?.operators?.map(cur => cur.operator as Address) || []),
            ...(operatorList?.filter(cur => Boolean(cur.whitelisted))?.map(cur => cur.address) ||
              []),
          ],
    [avs?.operators, isHostedOperatorWhitelisted, operatorList],
  );

  const {
    data: operatorStatus,
    isFetching: isFetchingStatus,
    isLoading: isLoadingStatus,
    isRefetching: isRefetchingStatus,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_OPERATOR_STATUS, avs?.deploymentId],
    queryFn: fetchOperatorStatus,
    enabled: Boolean(avs?.deploymentId && !isEcdsaAvs),
  });

  useEffect(() => {
    if (defaultSelectedOperators?.length) {
      setValue('operators', defaultSelectedOperators);
    }
  }, [setValue, defaultSelectedOperators]);

  const handleSelectOperator = (value: Address) => () => {
    if (selectedOperators?.includes?.(value)) {
      setValue(
        'operators',
        selectedOperators?.filter?.(cur => cur !== value),
        { shouldDirty: true, shouldTouch: true },
      );
    } else {
      setValue('operators', [...(selectedOperators || []), [value]].flat(), {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const hasChanges = Boolean(
    operatorList?.length > 1 && // Hosted Operator Service is always in the list
      JSON.stringify(selectedOperators?.slice()?.sort()) !==
        JSON.stringify(
          operatorList
            ?.filter(cur => cur.whitelisted)
            ?.map(cur => cur.address)
            ?.sort(),
        ),
  );

  const [activeStep, setActiveStep] = useState(0);
  const [showWhitelistOperatorWizard, setShowWhitelistOperatorWizard] = useState(false);
  const [showHostedOperatorWizard, setShowHostedOperatorWizard] = useState(false);
  const [showCredentialsDialog, setShowCredentialsDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAddCustomOperatorDialog, setShowAddCustomOperatorDialog] = useState(false);
  const [customOperatorAddrInput, setCustomOperatorAddrInput] = useState('');
  const { allowlistManager } = useAvsRoles(avs);

  const handleOperatorAddrInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    const val = e?.target?.value;

    setCustomOperatorAddrInput(val);
  };

  useEffect(() => {
    if (hasChanges) {
      setActiveStep(0);
    }
  }, [hasChanges]);

  const steps: {
    title: ReactNode;
    description?: ReactNode;
    content?: ReactNode;
    actionBar: ReactNode;
  }[] = useMemo(
    () => [
      {
        title: <Typography>Update whitelist</Typography>,
        actionBar: (
          <Web3LoadingButton
            loading={isUpdatingOperators}
            onClick={updateOperators}
            requiredRole="allowlistManager"
            requiredSender={allowlistManager}
            variant="contained"
          >
            Update
          </Web3LoadingButton>
        ),
      },
    ],
    [allowlistManager, isUpdatingOperators, updateOperators],
  );

  return (
    <>
      <Dialog
        disablePortal
        onClose={() => {
          setShowWhitelistOperatorWizard(false);
        }}
        open={showWhitelistOperatorWizard}
        sx={{ '& .MuiPaper-root': { p: 5 } }}
      >
        <Typography variant="h5">Save operator changes</Typography>
        <Stepper activeStep={activeStep} connector={null} orientation="vertical">
          {steps.map((step, index) => (
            <Step
              key={index}
              sx={
                index !== steps?.length - 1
                  ? {
                      position: 'relative',
                      '& .MuiStepLabel-iconContainer:after': {
                        content: "' '",
                        height: 'calc(100% - 24px)',
                        borderRight: '1px solid black',
                        position: 'absolute',
                        left: '11px',
                        top: '32px',
                        zIndex: 0,
                      },
                    }
                  : {}
              }
            >
              <StepLabel StepIconComponent={CustomStepIcon} sx={{ alignItems: 'flex-start' }}>
                <Typography mt={0.25}>{step.title}</Typography> {step.description}
              </StepLabel>
              <StepContent>
                <Typography>{step.content}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Stack alignItems="center" justifyContent="center" mt={2} width="100%">
          {activeStep >= steps?.length ? (
            <Stack gap={2} width="100%">
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#66B489',
                  fontWeight: 500,
                }}
              >
                🎉&nbsp;Whitelist updated! <br />
                {!isEcdsaAvs &&
                  !operatorStatus?.status &&
                  'You may now activate your hosted operator.'}
              </Typography>

              {!isEcdsaAvs && !operatorStatus?.status ? (
                <>
                  <Button
                    fullWidth
                    onClick={() => {
                      setShowWhitelistOperatorWizard(false);
                      setShowHostedOperatorWizard(true);
                    }}
                    variant="contained"
                  >
                    Activate now
                  </Button>
                  <Button
                    fullWidth
                    onClick={() => {
                      setShowWhitelistOperatorWizard(false);
                    }}
                    variant="outlined"
                  >
                    I&apos;ll do it later
                  </Button>
                </>
              ) : (
                <Button
                  fullWidth
                  onClick={() => {
                    setShowWhitelistOperatorWizard(false);
                  }}
                  variant="contained"
                >
                  Close
                </Button>
              )}
            </Stack>
          ) : (
            steps?.[activeStep]?.actionBar
          )}
        </Stack>
      </Dialog>
      {showHostedOperatorWizard && (
        <ActivateHostedOperatorWizard
          avs={avs}
          onClose={() => setShowHostedOperatorWizard(false)}
          open={true}
        />
      )}
      {showCredentialsDialog && (
        <Dialog
          onClose={() => setShowCredentialsDialog(false)}
          open={true}
          sx={{ '&>div>.MuiPaper-root': { p: 3, minWidth: '40rem' } }}
        >
          <DialogTitle>Credentials</DialogTitle>
          <DialogContent>
            <Typography variant="bodySmallC">Endpoint</Typography>
            <TextField disabled fullWidth value={`https://${operatorStatus?.operatorIngress}`} />
            <Typography component="div" mt={1} variant="bodySmallC">
              Proxy Endpoint
            </Typography>
            <TextField disabled fullWidth value={`https://${operatorStatus?.proxyIngress}`} />
            <Typography component="div" mt={1} variant="bodySmallC">
              Username
            </Typography>
            <TextField disabled fullWidth value={operatorStatus?.userName} />
            <Typography component="div" mt={1} variant="bodySmallC">
              Password
            </Typography>
            <TextField
              InputProps={{
                endAdornment: showPassword ? (
                  <Box
                    component={MdOutlineVisibility}
                    onClick={() => setShowPassword(false)}
                    sx={{ cursor: 'pointer' }}
                  />
                ) : (
                  <Box
                    component={MdOutlineVisibilityOff}
                    onClick={() => setShowPassword(true)}
                    sx={{ cursor: 'pointer' }}
                  />
                ),
              }}
              disabled
              fullWidth
              type={showPassword ? 'text' : 'password'}
              value={operatorStatus?.password}
            />
          </DialogContent>
        </Dialog>
      )}
      {showAddCustomOperatorDialog && (
        <Dialog
          onClose={() => setShowAddCustomOperatorDialog(false)}
          open={true}
          sx={{ '&>div>.MuiPaper-root': { p: 3, minWidth: '30rem' } }}
        >
          <DialogTitle>Add Custom Operator</DialogTitle>
          <DialogContent>
            <Typography variant="bodySmallC">Operator Address</Typography>
            <TextField
              error={Boolean(customOperatorAddrInput && !isAddress(customOperatorAddrInput))}
              fullWidth
              helperText={
                customOperatorAddrInput && !isAddress(customOperatorAddrInput)
                  ? 'Please enter a valid address'
                  : ''
              }
              onChange={handleOperatorAddrInputChange}
              placeholder="0x"
              value={customOperatorAddrInput}
            />
            <Button
              disabled={!isAddress(customOperatorAddrInput)}
              fullWidth
              onClick={() => {
                setCustomOperators(prev =>
                  Array.from(new Set(prev)?.add(customOperatorAddrInput as Address)),
                );
                setShowAddCustomOperatorDialog(false);
                setCustomOperatorAddrInput('');
                setTimeout(() => {
                  const el = document.getElementById(customOperatorAddrInput);

                  el?.scrollIntoView({ behavior: 'smooth' });

                  handleSelectOperator(customOperatorAddrInput as Address)();
                }, 50);
              }}
              sx={{ mt: 2 }}
            >
              Add
            </Button>
          </DialogContent>
        </Dialog>
      )}
      <Option
        endAdornment={
          <Button
            disabled={isTogglingAllowlist || !isAllowlistEnabled || !isAvsOwner}
            onClick={() => setShowAddCustomOperatorDialog(true)}
            variant="outlined"
          >
            Add Custom Operator
          </Button>
        }
        isLoading={
          (!isEcdsaAvs && isLoadingStatus && isFetchingStatus && !isRefetchingStatus) ||
          isLoadingAllowlist
        }
        optionTitle={
          <Stack alignItems="center" direction="row" gap={1}>
            <Typography variant="bodySmallC">Whitelist Operators</Typography>
            <Switch
              checked={Boolean(isAllowlistEnabled)}
              disabled={!isAvsOwner}
              onChange={e => {
                e?.preventDefault();
                const val = e?.target?.checked;

                if (val) {
                  enableAllowlist();
                } else {
                  disableAllowlist();
                }
              }}
            />
            {isTogglingAllowlist && (
              <Typography
                sx={{ display: 'flex', alignItems: 'center', fontSize: 12, gap: 1 }}
                variant="bodySmall"
              >
                {isAllowlistEnabled ? 'Disabling' : 'Enabling'}
                <CircularProgress size={12} />
              </Typography>
            )}
          </Stack>
        }
        sx={{
          '&& > .MuiTypography-root.MuiStack-root': {
            mb: '10px',
          },
          ...sx,
        }}
        {...props}
      >
        <Grid
          container
          {...register('operators')}
          className="masked-overflow"
          ref={null}
          sx={{ maxHeight: '20rem' }}
        >
          {operatorList?.map((operator, index) => {
            const isNextOptionSelected = selectedOperators?.includes(
              operatorList?.[index + 1]?.address,
            );

            return operator.address === avs?.operators?.[0]?.operator && isEcdsaAvs ? null : (
              <Grid
                id={operator.address}
                item
                key={operator.address}
                sx={{ scrollMarginTop: 20 }}
                xs={12}
              >
                <CheckboxCard
                  content={
                    <Typography px={1}>
                      {operator.name}
                      {index === 0 && (
                        <>
                          <Typography component="span" sx={{ opacity: 0.4, ml: 1 }}>
                            (DEFAULT)
                          </Typography>
                          {operatorStatus && (
                            <Typography component="span" sx={{ opacity: 0.4, ml: 1 }}>
                              [
                              {OperatorStatus?.[operatorStatus?.status] === 'running'
                                ? 'Online'
                                : 'Offline'}
                              ]
                            </Typography>
                          )}
                        </>
                      )}
                    </Typography>
                  }
                  disabled={
                    operator.address === avs?.operators?.[0]?.operator ||
                    !isAllowlistEnabled ||
                    !isAvsOwner
                  }
                  isSelected={selectedOperators?.includes(operator.address)}
                  leftAdornment={
                    <Typography
                      fontSize={11}
                      onClick={e => e?.stopPropagation()}
                      sx={{
                        width: '0.5rem',
                        background: getOperatorStatusColors(operator, offChainQuorums)?.background,
                        color: 'transparent',
                        '&:hover': {
                          width: '12rem',
                          color: getOperatorStatusColors(operator, offChainQuorums)?.text,
                          cursor: 'default',
                        },
                        transition: 'all 0.5s ease',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        pl: 1,
                        justifyContent: 'center',
                        pointerEvents: 'all',
                        whiteSpace: 'nowrap',
                      }}
                      variant="bodySmall"
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {operator?.whitelisted ? <MdCheck size={15} /> : <MdClose size={15} />}
                        {!operator?.whitelisted && 'Not '}
                        Whitelisted
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {operator?.registered ? <MdCheck size={15} /> : <MdClose size={15} />}
                        {!operator?.registered
                          ? 'Not '
                          : operator.registeredQuorums &&
                            operator.registeredQuorums?.length < offChainQuorums?.length
                          ? 'Partially '
                          : ''}
                        Registered{' '}
                        {operator?.registered && (
                          <Tooltip
                            placement="right-end"
                            title={
                              <Stack>
                                <Typography variant="caption">
                                  Registered: <br />
                                  {operator?.registeredQuorums?.map(quorumNumber => (
                                    <>
                                      - {avsStrategyLabels?.[quorumNumber as AVS_QUORUMS]}
                                      <br />
                                    </>
                                  ))}
                                </Typography>
                                {(!operator?.registeredQuorums?.length ||
                                  operator?.registeredQuorums?.length <
                                    offChainQuorums?.length) && (
                                  <Typography variant="caption">
                                    Supported but not registered: <br />
                                    {offChainQuorums
                                      ?.filter(
                                        quorumNumber =>
                                          !operator?.registeredQuorums?.includes(quorumNumber),
                                      )
                                      ?.map(
                                        quorumNumber =>
                                          `- ${avsStrategyLabels?.[quorumNumber as AVS_QUORUMS]}`,
                                      )
                                      ?.join(', ')}
                                  </Typography>
                                )}
                              </Stack>
                            }
                          >
                            <Box sx={{ '&& path': { fill: 'currentcolor' } }}>
                              <IconInfo color="inherit" />
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                    </Typography>
                  }
                  leftButtonGroup={
                    <Checkbox
                      checked={selectedOperators?.includes(operator.address) || false}
                      onChange={handleSelectOperator(operator.address)}
                      size="small"
                      sx={{ ml: 1.5 }}
                    />
                  }
                  onClick={handleSelectOperator(operator.address)}
                  rightButtonGroup={
                    operator.address === avs?.operators?.[0]?.operator ? (
                      <OperatorActionsMenu
                        avs={avs}
                        isAvsOwner={isAvsOwner}
                        operator={operator}
                        operatorStatus={operatorStatus as QueryOperatorResponse}
                        renderMenuItems={defaultActions => (
                          <>
                            <ListItemButton onClick={() => setShowHostedOperatorWizard(true)}>
                              <ListItemText primary="Activate" />
                            </ListItemButton>
                            {operatorStatus?.status === OperatorStatus.running && (
                              <ListItemButton
                                onClick={() => {
                                  setShowCredentialsDialog(true);
                                }}
                              >
                                <ListItemText primary="Credentials" />
                              </ListItemButton>
                            )}
                            {defaultActions.map((cur, index) => (
                              <Fragment key={index}>{cur.component}</Fragment>
                            ))}
                          </>
                        )}
                      />
                    ) : (
                      <OperatorActionsMenu
                        avs={avs}
                        isAvsOwner={isAvsOwner}
                        operator={operator}
                        operatorStatus={operatorStatus as QueryOperatorResponse}
                      />
                    )
                  }
                  sx={{
                    svg: {},
                    display: 'flex',
                    ...(isNextOptionSelected && { borderBottom: 'none' }),
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Option>
      <Collapse
        in={
          hasChanges &&
          // Do not show save changes button if hosted operator is the only operator in the changeset
          !(
            operatorChangeset?.addedOperators?.includes(avs?.operators?.[0]?.operator as Address) &&
            selectedOperators?.length === 1
          ) &&
          !(!isEcdsaAvs && (isLoadingStatus || operatorStatus?.status === undefined)) &&
          Boolean(defaultSelectedOperators?.length)
        }
      >
        <Stack alignItems="center" direction="row" justifyContent="flex-end" width="100%">
          <Button
            onClick={() => {
              if (operatorList) {
                setValue('operators', defaultSelectedOperators);
              }
            }}
            sx={{ color: theme => theme.colors.functional.text.primary }}
            variant="text"
          >
            <Typography variant="bodySmallC">Cancel</Typography>
          </Button>
          <LoadingButton
            loading={isUpdatingOperators}
            onClick={() => setShowWhitelistOperatorWizard(true)}
            variant="contained"
          >
            <Typography variant="bodySmallC">Save Changes</Typography>
          </LoadingButton>
        </Stack>
      </Collapse>
    </>
  );
}
